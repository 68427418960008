import { get } from "lodash";

export const getName = (data) => {
  return get(
    data,
    "product.customData.name",
    get(data, "product.productData.name", "")
  );
};

export const getDescription = (data) => {
  return get(
    data,
    "product.customData.kortbeskrivelse",
    get(
      data,
      "product.customData.description",
      get(data, "product.productData.description", "")
    )
  );
};

export const getFirstImage = (data) => {
  return get(data, "product.customData.billeder[0].url", null);
};

export const getAmountPerUnit = (data) => {
  return get(data, "product.productData.amountPerUnit", 1);
};
