import React from "react";
import { createPortal } from "react-dom";

import "./WatchedActionModal.css";
import { ExitButton } from "../../../ExitButton/ExitButton";
import { Icon } from "../../../Icon/Icon";
import { getImageWithSize } from "../../../../Utils/getImageWithSize";
import { ModalButton } from "../../../ModalButton/ModalButton";
import { ListItem } from "../../../ListItem/ListItem";

export const WatchedActionModal = ({ open, respond, data }) => {
  const modalRoot = document.getElementById("modal-root");

  const confirmAction =
    data.actionType === "should-change"
      ? "confirm-change"
      : data.actionType === "should-activate"
      ? "confirm-automatic"
      : "";

  return open
    ? createPortal(
        <div className="WatchedActionModal__backdrop">
          <div
            className="WatchedActionModal"
            style={{
              height: data.actionType === "should-change" ? "605px" : "552px",
            }}
          >
            <div className="WatchedActionModal__close-button">
              <ExitButton click={() => respond("close")} text="Luk" />
            </div>

            <div className="WatchedActionModal__content">
              <div className="WatchedActionModal__product">
                <div className="WatchedActionModal__product-image">
                  {data.image ? (
                    <img
                      alt="Fejl"
                      src={getImageWithSize(data.image, "65x65")}
                    />
                  ) : (
                    <Icon name="noImageAvail" width={65} />
                  )}
                </div>
                <div className="WatchedActionModal__product-text">
                  <div className="WatchedActionModal__product-text-title">
                    {data.name}
                  </div>
                  <div className="WatchedActionModal__product-text-prodno">
                    {data.prodNo}
                  </div>
                </div>
              </div>
              <div className="WatchedActionModal__action">
                <WatchedActionBox
                  input={data.timesOrdered}
                  actionType={data.actionType}
                />
              </div>
              <div className="WatchedActionModal__recommendation">
                {data.actionType === "should-change" && (
                  <WatchedRecommendationBox
                    currentMin={data.current.currentMin}
                    currentMax={data.current.currentMax}
                    recommendedMin={data.recommended.min}
                    recommendedMax={data.recommended.max}
                    unit={data.unit}
                    respond={respond}
                  />
                )}
                {data.actionType === "should-activate" && (
                  <WatchedActivateBox />
                )}
              </div>
            </div>

            <div
              className="WatchedActionModal__button"
              onClick={() => respond(confirmAction)}
            >
              {data.isLoading ? (
                <div>
                  {" "}
                  <Icon name="ovalLoader" width={34} />{" "}
                </div>
              ) : (
                <div className="WatchedActionModal__button-text">
                  Godkend anbefaling
                </div>
              )}
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};

const WatchedActionBox = ({ input, actionType }) => (
  <div className="WatchedActionBox">
    <div className="WatchedActionBox__icon">
      <Icon name="productWatchIcon" width={32} />
    </div>
    {actionType === "should-change" && (
      <div className="WatchedActionBox__text">
        {" "}
        Du er løbet tør for denne vare og har manuelt bestilt den{" "}
        <b>{input}&nbsp;gange over de sidste 6&nbsp;måneder.</b> Vi har derfor
        beregnet et nyt forslag til dig.{" "}
      </div>
    )}
    {actionType === "should-activate" && (
      <div className="WatchedActionBox__text">
        Varen er bestilt manuelt <b>{input} gange på de sidste 6 måneder.</b>{" "}
        For at spare tid og undgå at mangle produktet, kan du aktivere
        automatisk genbestilling.
      </div>
    )}
  </div>
);

const WatchedRecommendationBox = ({
  currentMin,
  currentMax,
  recommendedMin,
  recommendedMax,
  unit,
  respond,
}) => (
  <div className="WatchedRecommendationBox">
    <div className="WatchedRecommendationBox__titles">
      <div className="WatchedRecommendationBox__titles-title WatchedRecommendationBox__titles-current">
        Nuværende
      </div>
      <div className="WatchedRecommendationBox__titles-title WatchedRecommendationBox__titles-recommended">
        Anbefaling
      </div>
    </div>
    <WatchedRecommendationBar
      title="Minimumsbeholdning"
      current={currentMin}
      recommended={recommendedMin}
      unit={unit}
    />
    <WatchedRecommendationBar
      title="Ønsket beholdning"
      current={currentMax}
      recommended={recommendedMax}
      unit={unit}
    />
    <div
      className="WatchedRecommendationBox__ignore"
      onClick={() => respond("click-ignore")}
    >
      <div className="WatchedRecommendationBox__ignore-text">
        Ignorér anbefaling
      </div>
    </div>
  </div>
);

const WatchedRecommendationBar = ({ title, current, recommended, unit }) => (
  <div className="WatchedRecommendationBar">
    <div className="WatchedRecommendationBar__title">{title}</div>
    <div className="WatchedRecommendationBar__right-side">
      <div className="WatchedRecommendationBar__input">
        <input
          className="WatchedRecommendationBar__input-input"
          value={current}
          readOnly
        />
      </div>
      <div className="WatchedRecommendationBar__diff">
        {formattedUnit(current, recommended, unit)}
      </div>
      <div className="WatchedRecommendationBar__input WatchedRecommendationBar__input-recommended">
        <input
          className="WatchedRecommendationBar__input-input"
          value={recommended}
          readOnly
        />
      </div>
    </div>
  </div>
);

const formattedUnit = (current, recommended, unit) => {
  const total = recommended - current;
  if (total > 0) {
    return `+${total} ${unit}`;
  } else {
    return `${total} ${unit}`;
  }
};

const WatchedActivateBox = ({ respond }) => {
  return (
    <>
      <div className="WatchedActivateBox">
        <div className="WatchedActivateBox__label">
          <div className="WatchedActivateBox__label-text">Anbefaling</div>
        </div>
        <ListItem title="Automatisk genbestilling">
          <div className="EditItem__auto-reorder">
            <ModalButton
              buttonStyle="toggleButton"
              iconName="autoOrderActive"
              labelStyle="toggleLabel"
              autoOrderState="active"
              width={16}
              click={() => {}}
            />
          </div>
        </ListItem>
      </div>
      <div
        className="WatchedRecommendationBox__ignore"
        onClick={() => respond("ignore")}
      >
        <div className="WatchedRecommendationBox__ignore-text">
          Ignorér anbefaling
        </div>
      </div>
    </>
  );
};
