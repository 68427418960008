import { ApolloConsumer } from "@apollo/client";
import { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../Icon/Icon";
import "./ProfileDropdown.css";

type Props = {
  role: string;
};

export const ProfileDropdown: FC<Props> = ({ role }) => {
  const history = useHistory();
  return (
    <ApolloConsumer>
      {(client) => (
        <Fragment>
          {/* <div className="ProfileDropdown__backdrop" /> */}
          <div className="ProfileDropdown">
            <div
              className="ProfileDropdown__item ProfileDropdown__help"
              onClick={() => {
                window.location.assign(
                  "https://manual.eflowsoftware.com/da/inventory"
                );
              }}
            >
              <Icon name="iconHelp" width={22} height={22} />
              <div className="ProfileDropdown__text ProfileDropdown__help-text">
                Hjælp
              </div>
            </div>
            {role === "ADMIN" && (
              <div
                className="ProfileDropdown__item ProfileDropdown__settings"
                onClick={() => {
                  history.push("/settings");
                }}
              >
                <Icon name="iconSettings" width={22} height={22} />
                <div className="ProfileDropdown__text ProfileDropdown__settings-text">
                  Indstillinger
                </div>
              </div>
            )}
            <div
              className="ProfileDropdown__item ProfileDropdown__switch-app"
              onClick={() => {
                client.resetStore();
                window.location.assign(
                  `${process.env.REACT_APP_SSO_BASE_URL}/login/apps`
                );
              }}
            >
              <Icon name="undoIcon" width={22} height={22} />
              <div className="ProfileDropdown__text ProfileDropdown__switch-app-text">
                Skift applikation
              </div>
            </div>
            <div
              className="ProfileDropdown__item ProfileDropdown__logout"
              onClick={() => {
                localStorage.removeItem("bdjToken");
                client.resetStore();
                window.location.assign(
                  `${process.env.REACT_APP_SSO_BASE_URL}/logout`
                );
              }}
            >
              <Icon name="iconSignout" width={22} height={22} />
              <div className="ProfileDropdown__text ProfileDropdown__logout-text">
                Log ud
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </ApolloConsumer>
  );
};
