import { AutomaticOrdering } from "../../Generated/graphql";
import { noop } from "../../Utils/noop";
import { Icon } from "../Icon/Icon";
import "./ModalButton.css";

export const ModalButton = ({
  iconName,
  width = 16,
  buttonStyle,
  labelStyle,
  autoOrderState,
  click,
  canEdit = true,
}) => {
  const button = ["ModalButton"];

  if (buttonStyle) {
    button.push(`ModalButton__${buttonStyle}`);
  }

  if (!canEdit) {
    button.push("ModalButton__cannot-edit");
  }

  const ModalButton = (
    <div
      onClick={canEdit ? click : noop}
      className={button.join(" ")}
      style={{
        backgroundColor:
          autoOrderState === AutomaticOrdering.Active ? "#68CEA2" : "#AA2525",
      }}
    >
      <div
        className="ModalButton__icon-container"
        style={
          autoOrderState === AutomaticOrdering.Active
            ? { right: "2px" }
            : { right: "101px" }
        }
      >
        <div className="ModalButton__icon">
          <Icon name={iconName} width={width} height={width} />
        </div>
      </div>
      <div
        className="ModalButton__text-container"
        style={
          autoOrderState === AutomaticOrdering.Active
            ? { right: "115px" }
            : { right: "9px" }
        }
      >
        <div className="ModalButton__text">
          {autoOrderState === AutomaticOrdering.Active
            ? "AKTIVERET"
            : "DEAKTIVERET"}
        </div>
      </div>
    </div>
  );

  const disabledModalButton = (
    <div className="disabledModalButton">
      <div className="disabledModalButton__icon-container">
        <div className="disabledModalButton__icon">
          <Icon name={iconName} width={width} height={width} />
        </div>
      </div>
      <div className="disabledModalButton__text-container">
        <div className="disabledModalButton__text u-no-text-select">
          DEAKTIVERET
        </div>
      </div>
    </div>
  );

  return autoOrderState === "disabled" ? disabledModalButton : ModalButton;
};
