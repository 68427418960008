import { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  useCartsQuery,
  useSetCartCommentMutation,
} from "../../Generated/graphql";
import { Button } from "../Button/Button";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import { TextArea } from "../TextArea/TextArea";
import "./AddCommentToActiveOrder.css";

const AddCommentToActiveOrder = ({ match, history }: any) => {
  const [updateCart] = useSetCartCommentMutation();
  const { data, error, loading } = useCartsQuery();

  const handleOrderComment = (comment: string) => {
    const currentCart = data?.carts[0];

    if (!currentCart) {
      return;
    }

    updateCart({
      variables: {
        cartId: currentCart.id,
        comment,
      },
    }).then(() => {
      history.goBack();
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Der skete en fejl, prøv igen</div>;

  return (
    <AddCommentToActiveOrderContent
      comment={data?.carts?.[0]?.comment || ""}
      setOrderComment={handleOrderComment}
      history={history}
    />
  );
};

const routed = withRouter(AddCommentToActiveOrder);

export { routed as AddCommentToActiveOrder };

const AddCommentToActiveOrderContent = ({
  comment,
  setOrderComment,
  history,
}: {
  comment: string;
  setOrderComment: (comment: string) => void;
  history: any;
}) => {
  const [myComment, setComment] = useState(comment);
  return (
    <div className="AddCommentToActiveOrder">
      <div className="NewItem__exit">
        <ExitButton click={() => history.goBack()} />
      </div>
      <div className="AddCommentToActiveOrder__header">
        <div className="AddCommentToActiveOrder__title">
          <div className="AddCommentToActiveOrder__title-text">
            {comment
              ? "Rediger kommentar til ordren"
              : "Tilføj kommentar til ordren"}
          </div>
        </div>
      </div>
      <TextArea
        value={myComment}
        setValue={(e: any) => setComment(e.target.value)}
        height="300px"
      />

      <AddCommentToActiveOrderFooter
        setCommentOnOrder={() => setOrderComment(myComment)}
      />
    </div>
  );
};

const AddCommentToActiveOrderFooter = ({
  setCommentOnOrder,
}: {
  setCommentOnOrder: () => void;
}) => {
  const [saving, setSaving] = useState(false);
  return (
    <div className="AddCommentToActiveOrderFooter">
      <div className="NewItem__footer">
        <div className="AddCommentToActiveOrderFooter__footer-changesSaved">
          <Button
            buttonStyle="newItem"
            width="160px"
            click={() => {
              setSaving(true);
              return setCommentOnOrder();
            }}
          >
            {saving ? (
              <div className="NewItem__creating-button-spin">
                <Icon name="buttonSpinner" width={12} />
              </div>
            ) : (
              <Icon name="add" width={12} />
            )}
            <div>{saving ? "Gemmer" : "Gem kommentar"}</div>
          </Button>
        </div>
      </div>
    </div>
  );
};
