import moment from "moment";
import { FC, useState } from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useValueChartDataQuery } from "../../../Generated/graphql";
import { formatLargeNumber } from "../../../Utils/formatLargeNumber";
import { BoxLoader } from "../../BoxLoader/BoxLoader";
import { DashboardNotEnoughDataToCalculate } from "../DashboardNotEnoughDataToCalculate/DashboardNotEnoughDataToCalculate";
import "./DashboardInventoryValueChart.css";

type Props = {
  selectedYear: { name: string };
  accountancyYear: number;
};

export const DashboardInventoryValueChart: FC<Props> = ({
  selectedYear = { name: moment().format("YYYY") },
  accountancyYear,
}) => {
  const [currentScreenSize, setCurrentScreenSize] = useState(0);
  const [requiredPadding, setRequiredPadding] = useState({
    left: -19,
    right: -20,
  });

  const formattedSelectedYear = selectedYear.name.includes("Regnskabsår")
    ? selectedYear.name.split(" ")[1]
    : selectedYear.name;

  const { data: queryData, loading } = useValueChartDataQuery({
    variables: {
      year: parseInt(formattedSelectedYear, 10),
      startMonth: accountancyYear,
    },
    fetchPolicy: "network-only",
  });

  const screenSize = window.innerWidth;

  if (screenSize !== currentScreenSize) {
    setCurrentScreenSize(screenSize);
    const calculatedPadding = calculateRequiredPadding(screenSize);

    if (calculatedPadding !== requiredPadding) {
      setRequiredPadding(calculatedPadding);
    }
  }

  const chartData =
    queryData && queryData.valueChartData ? queryData.valueChartData : [];
  const chartHasRealData = chartData.filter((x) => x.inventoryValue !== null);

  return (
    <div className="DashboardInventoryValueChart">
      {loading && (
        <div className="DashboardInventoryValueChart__loading">
          <BoxLoader />
        </div>
      )}
      {chartHasRealData.length === 0 && (
        <div
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <DashboardNotEnoughDataToCalculate />
        </div>
      )}
      {chartHasRealData.length > 0 && (
        <ResponsiveContainer debounce={1} height="100%" width="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 0,
            }}
            barGap={-28}
          >
            <CartesianGrid
              vertical={false}
              stroke="#A1B4C4"
              strokeWidth="1px"
              strokeOpacity="100%"
            />
            <XAxis
              dataKey="name"
              padding={requiredPadding}
              tick={<CustomXTick />}
              tickLine={false}
              axisLine={false}
            />
            <YAxis axisLine={false} tick={<CustomYTick />} tickLine={false} />
            <Tooltip content={<DashboardCustomizedTooltip />} />
            <Area
              type="monotone"
              dataKey="inventoryValue"
              fill="rgba(64, 146, 215, 0.4)"
              stroke="rgba(0,0,0,0)"
              activeDot={false}
            />
            <Bar
              dataKey="intakeValue"
              fill="#68CEA2"
              barSize={28}
              radius={[4, 4, 0, 0]}
              stroke="rgba(0,0,0,0)"
            />
            <Bar
              dataKey="outgoingValue"
              fill="#EAC75A"
              barSize={28}
              radius={[4, 4, 0, 0]}
              stroke="rgba(0,0,0,0)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

const CustomXTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={11}
        textAnchor="middle"
        fill="#6D7A85"
        className="DashboardInventoryValueChart__xaxis-label"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomYTick = ({ x, y, payload }: any) => {
  const stringedValue = payload.value.toString();
  const formattedValue = stringedValue.substring(0, stringedValue.length - 3);
  return (
    <g transform={`translate(${x},${y - 8})`}>
      <text
        x={0}
        y={0}
        dy={11}
        textAnchor="end"
        fill="#6D7A85"
        className="DashboardInventoryValueChart__xaxis-label"
      >
        {formattedValue !== "" ? formattedValue : payload.value}
      </text>
    </g>
  );
};

const DashboardCustomizedTooltip = (props: any) => {
  return (
    <div className="DashboardCustomizedTooltip">
      <div className="DashboardCustomizedTooltip__title">
        <div className="DashboardCustomizedTooltip__title-text">
          {`${getMonthName(props?.payload[0]?.payload.name || "JAN")} ${props
            ?.payload[0]?.payload.year || ""}`}
        </div>
      </div>

      <div className="DashboardCustomizedTooltip__content">
        {props.payload.map((info: any) => {
          return (
            <div
              className="DashboardCustomizedTooltip__content--inner"
              key={info.name}
            >
              <div
                className="DashboardCustomizedTooltip__color-box"
                style={{ backgroundColor: info.fill }}
              ></div>
              <div className="DashboardCustomizedTooltip__name">
                {getTooltipName(info.name)}
              </div>
              <div className="DashboardCustomizedTooltip__value">
                {formatLargeNumber(info.value.toFixed(2))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getTooltipName = (input: string) => {
  switch (input) {
    case "inventoryValue":
      return "Lagerværdi";

    case "intakeValue":
      return "Tilgang";

    case "outgoingValue":
      return "Afgang";

    default:
      return "";
  }
};

const getMonthName = (shortMonth: string) => {
  switch (shortMonth) {
    case "JAN":
      return "Januar";
    case "FEB":
      return "Februar";
    case "MAR":
      return "Marts";
    case "APR":
      return "April";
    case "MAJ":
      return "Maj";
    case "JUN":
      return "Juni";
    case "JUL":
      return "Juli";
    case "AUG":
      return "August";
    case "SEP":
      return "September";
    case "OKT":
      return "Oktober";
    case "NOV":
      return "November";
    case "DEC":
      return "December";

    default:
      return "";
  }
};

const calculateRequiredPadding = (
  screenSize: number
): { left: number; right: number } => {
  if (screenSize <= 1024) {
    return { left: -19, right: -20 };
  } else if (screenSize > 1024 && screenSize < 1280) {
    return { left: -26, right: -26 };
  } else if (screenSize > 1280 && screenSize <= 1440) {
    return { left: -30, right: -30 };
  } else if (screenSize > 1440) {
    return { left: -42, right: -42 };
  }

  return { left: 0, right: 0 };
};
