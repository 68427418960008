import { FC } from "react";
import "./ShimmerBar.css";

type Props = {
  theme: "light" | "dark";
};

export const ShimmerBar: FC<Props> = ({ theme = "light" }) => {
  return (
    <div className={`ShimmerBar ShimmerBar--${theme}`}>
      <div className={`ShimmerBar__bar ShimmerBar__bar--${theme}`} />
    </div>
  );
};
