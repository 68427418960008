export const getImageWithSize = (
  url,
  size,
  format = "jpeg",
  compression = 80
) => {
  const imagePathArray = url.split("/");
  const imageName = imagePathArray[imagePathArray.length - 1];

  return `${process.env.REACT_APP_IMAGE_API}${size}/${imageName}/${format}/${compression}`;
};
