import Lightbox from "lightbox-react";
import { get, isEqual } from "lodash";
import { FC, memo, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { useBoolean } from "usehooks-ts";
import { InventoryItem } from "../../Generated/graphql";
import { getImageWithSize } from "../../Utils/getImageWithSize";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import { ImageTooltip } from "../ImageTooltip/ImageTooltip";
import { ItemAddedToCart } from "../ItemAddedToCart/ItemAddedToCart";
import { ItemOrdered } from "../ItemOrdered/ItemOrdered";
import { ListItemActions } from "../ListItemActions/ListItemActions";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import "./InventoryListItem.css";

type Props = {
  windowWidth: number;
  item: InventoryItem;
  canEdit: boolean;
};

const InventoryListItem: FC<Props> = ({ canEdit, item, windowWidth }) => {
  const { value: showImage, setValue: setShowImage } = useBoolean(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const match = useRouteMatch();

  const allImages = useMemo(() => {
    return item.images.reduce<string[]>((acc, image) => {
      if (!image?.url) {
        return acc;
      }
      const imageUrl = getImageWithSize(image?.url, "800x800");
      acc.push(imageUrl);
      return acc;
    }, []);
  }, [item.images]);

  const unitAsText = item.amountPerUnit > 1 ? "ks." : "stk.";

  return item ? (
    <>
      {showImage && allImages.length > 0 && (
        <Lightbox
          mainSrc={allImages[photoIndex]}
          nextSrc={allImages[(photoIndex + 1) % allImages.length]}
          prevSrc={
            allImages[(photoIndex + allImages.length - 1) % allImages.length]
          }
          onCloseRequest={() => setShowImage(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + allImages.length - 1) % allImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % allImages.length)
          }
        />
      )}
      <Link
        to={{
          pathname: `${match.path}/edit/${item.productNumber}`,
        }}
        className={`InventoryListItem`}
      >
        <div className="InventoryListItem__content">
          {windowWidth > 1023 && (
            <Tooltip
              arrow={false}
              distance={0}
              direction="right"
              tipContentHover={true}
              content={
                <div className="Tooltip__content">
                  {navigator.userAgent.match(/iPad/i) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "6px",
                      }}
                    >
                      <ExitButton text="" solidBack={true} />
                    </div>
                  )}
                  {item.images[0] ? (
                    <ImageTooltip imageUrl={item.images[0].url} />
                  ) : (
                    <Icon name="noImageAvail" width={200} />
                  )}
                </div>
              }
            >
              <div className="InventoryListItem__image-icon">
                <Icon name="preview" width={20} />
                <div
                  className="IntentoryListItem__hover-trigger"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowImage(true);
                  }}
                />
              </div>
            </Tooltip>
          )}

          <div className="InventoryListItem__id u-cursor-default InventoryListItem__text">
            {item.productNumber}
          </div>
          <div className="InventoryListItem__name u-cursor-default InventoryListItem__text">
            <div className="InventoryListItem__text-formatted">
              {get(item, "name", " ")}
            </div>
          </div>
          {windowWidth > 1023 && (
            <div className="InventoryListItem__details u-cursor-default InventoryListItem__text">
              <div className="InventoryListItem__text-formatted">
                {get(item, "description", " ")}
              </div>
            </div>
          )}
          {item.alreadyOrdered ? (
            <ItemOrdered windowWidth={windowWidth} />
          ) : item.inCart ? (
            <ItemAddedToCart windowWidth={windowWidth} />
          ) : (
            <div />
          )}
          {windowWidth > 1023 && (
            <div className="InventoryListItem__stock-amount u-cursor-default">
              {" "}
              {item.amount ?? 0} {unitAsText ? unitAsText : ""}
            </div>
          )}
          <div className="InventoryListItem__bar u-cursor-default">
            <ProgressBar
              current={item.amount}
              min={item.minimum}
              max={item.maximum}
            />
          </div>
          <div className="InventoryListItem__refresh u-cursor-default">
            {" "}
            {/* The fuk is this class name? */}
            {item.automaticOrderingState === "active" ? (
              <Icon name="autoOrderActive" width={16} />
            ) : item.automaticOrderingState === "deactivated" ? (
              <Icon name="autoOrderDisabled" width={19} />
            ) : (
              <Icon
                className="disabledAutomaticOrdering"
                name="autoOrderDisabled"
                width={19}
              />
            )}
          </div>
          <div className="InventoryListItem__actions u-cursor-pointer">
            <ListItemActions
              windowWidth={windowWidth}
              canEdit={canEdit}
              add={() => {}}
              remove={() => {}}
              id={item.productNumber}
              toggleEdit={() => {}}
            />
          </div>
        </div>
      </Link>
    </>
  ) : null;
};

const memoized = memo(InventoryListItem, (prev, next) => {
  /** Is everything the same? */
  if (
    isEqual(prev.item, next.item) &&
    prev.canEdit === next.canEdit &&
    prev.windowWidth === next.windowWidth
  ) {
    return true;
  }

  return false;
});

export { memoized as InventoryListItem };
