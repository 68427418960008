import { Icon } from "../Icon/Icon";
import "./ListItemActions.css";

export const ListItemActions = ({
  id,
  toggleEdit,
  remove,
  add,
  windowWidth,
  canEdit,
}) => {
  return (
    // onClick={toggleEdit}
    <div className="ListItemActions">
      <div className="ListItemActions__action ListItemActions__edit u-cursor-pointer">
        {canEdit ? (
          <>
            <div className="ListItemActions__icon">
              <Icon name="editInventory" width={14.95} />
            </div>
            {windowWidth > 1023 && (
              <div className="ListItemActions__text">Rediger</div>
            )}
          </>
        ) : (
          <>
            <div className="ListItemActions__icon">
              <Icon name="editInventory" width={14.95} />
            </div>
            {windowWidth > 1023 && (
              <div className="ListItemActions__text">Detaljer</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
