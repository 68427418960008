import React from 'react';
import Tooltip from 'react-tooltip-lite';

import './AutomaticAdd.css'
import { Icon } from '../Icon/Icon';

export const AutomaticAdd = ({ windowWidth }) => {
  return (
    <div className="AutomaticAdd">
      {windowWidth < 1299 ? <Tooltip content={<div className="AutomaticAdd__text">Automatisk tilføjet</div>} ><div className="AutomaticAdd__icon">
        <Icon name="autoOrderActive" width={16} />
      </div> </Tooltip> : <div className="AutomaticAdd__icon">
          <Icon name="autoOrderActive" width={16} />
        </div>
      }
      {windowWidth > 1299 && <div className="AutomaticAdd__text">
        Automatisk tilføjet
      </div>}
    </div>
  )
}

export const ManualAdd = () => {
  return (
    <div className="ManualAdd">
      <div className="AutomaticAdd__icon">
        <Icon name="autoOrderActive" width={16} />
      </div>
      <div className="AutomaticAdd__text">
        Automatisk tilføjet
      </div>
    </div>
  )
}