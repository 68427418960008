import React from 'react';
import PropTypes from 'prop-types';



import './SelectOption.css'
import { noop } from '../../../Utils/noop';

export const SelectOption = ({ value, isSelected, click, children }) => {
    const classes = [ 'SelectOption' ];

    if ( isSelected ) {
        classes.push( 'is-selected' );
    }

    return (
        <div className={classes.join( ' ' )} onClick={() => click( value )}>
            {children}
        </div>
    );
};

SelectOption.propTypes = {
    value: PropTypes.any,
    isSelected: PropTypes.bool.isRequired,
    click: PropTypes.func.isRequired,
};

SelectOption.defaultProps = {
    isSelected: false,
    click: noop,
};