import React from "react";

import "./BoxLoader.css";
import { Icon } from "../Icon/Icon";

export const BoxLoader = ({
  text = "",
  backgroundOpacity = 1,
  loaderTheme = "light"
}) => {
  return (
    <>
      <div
        className="BoxLoader"
        style={{ backgroundColor: `rgba(183, 198, 210, ${backgroundOpacity})` }}
      >
        <div className={`BoxLoader__border BoxLoader__border--${loaderTheme}`}>
          <Icon name="logoBorder" width={90} />
        </div>
        <div className={`sk-cube-grid sk-cube-grid--${loaderTheme}`}>
          <div className="sk-cube sk-cube1" />
          <div className="sk-cube sk-cube2" />
          <div className="sk-cube sk-cube3" />
          <div className="sk-cube sk-cube4" />
          <div className="sk-cube sk-cube5" />
          <div className="sk-cube sk-cube6" />
          <div className="sk-cube sk-cube7" />
          <div className="sk-cube sk-cube8" />
          <div className="sk-cube sk-cube9" />
        </div>
        <div className="BoxLoader__text">{text}</div>
      </div>
    </>
  );
};
