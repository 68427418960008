import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import { HolidayDocument } from "../../../Generated/graphql";
import "./SuperAdminHolidaysList.css";

export const SuperAdminHolidaysList = ({ holidays }) => {
  return (
    <div className="SuperAdminHolidaysList">
      {holidays.map((holiday) => (
        <SuperAdminHolidaysListItem day={holiday} key={holiday.day} />
      ))}
    </div>
  );
};

const SuperAdminHolidaysListItem = ({ day }) => {
  const history = useHistory();
  const [removeHoliday] = useMutation(removeHolidayMutation, {
    update: (cache) => {
      const oldData = cache.readQuery({ query: HolidayDocument });

      cache.writeQuery({
        query: HolidayDocument,
        data: {
          getHolidays: oldData.holiday.filter((x) => x.day !== day.day),
        },
      });
    },
  });
  return (
    <div className="SuperAdminHolidaysListItem">
      <div className="SuperAdminHolidaysListItem__date">
        <div className="SuperAdminHolidaysListItem__date-text">
          {moment(day.day).format("DD/MM/YYYY")}
        </div>
      </div>
      <div className="SuperAdminHolidaysListItem__day-name">
        <div className="SuperAdminHolidaysListItem__day-name">
          {day.holidayName}
        </div>
      </div>
      <div className="SuperAdminHolidaysListItem__edit">
        <div
          className="SuperAdminHolidaysListItem__link SuperAdminHolidaysListItem__remove-text"
          onClick={() => history.push("/superadmin/holidays/edit/" + day.id)}
        >
          Rediger
        </div>
      </div>
      <div className="SuperAdminHolidaysListItem__remove">
        <div
          className="SuperAdminHolidaysListItem__link SuperAdminHolidaysListItem__remove-text"
          onClick={() => removeHoliday({ variables: { day: day.day } })}
        >
          Slet
        </div>
      </div>
    </div>
  );
};

const removeHolidayMutation = gql`
  mutation removeHoliday($day: String) {
    removeHoliday(day: $day)
  }
`;
