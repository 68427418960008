import { FC, useState } from "react";

import { Icon } from "../Icon/Icon";
import "./SplitSelect.css";

type Props = {
  click: (addState: boolean) => void;
  defaultAddState?: boolean;
  onChange?: (addState: boolean) => void;
};

export const SplitSelect: FC<Props> = ({
  click,
  defaultAddState = true,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [addState, setAddState] = useState(defaultAddState);

  return (
    <div className="SplitSelect">
      <div
        className="SplitSelect__label u-cursor-pointer"
        onClick={() => click(addState)}
      >
        <div className="SplitSelect__label-text u-cursor-pointer">
          {" "}
          {addState ? "Tilføj til lager" : "Undlad fra lager"}
        </div>
      </div>
      <div
        className="SplitSelect__arrow u-cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <Icon name="arrowDown" width={11} className="Icon__arrow-center" />
      </div>
      {expanded && (
        <div className="SplitSelect__options">
          <div
            className="SplitSelect__option  u-cursor-pointer"
            onClick={() => {
              setAddState(!addState);
              setExpanded(!expanded);
              onChange && onChange(!addState);
            }}
          >
            {addState ? "Undlad fra lager" : "Tilføj til lager"}
          </div>
        </div>
      )}
    </div>
  );
};
