import { compact, orderBy, uniq } from "lodash";
import React, { ChangeEvent, Component, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BoxLoader } from "../../Components/BoxLoader/BoxLoader";
import { InventoryListItem } from "../../Components/InventoryListItem/InventoryListItem";
import { NoFoundItems } from "../../Components/NoFoundItems/NoFoundItems";
import { NotificationBar } from "../../Components/NotificationBar/NotificationBar";
import { SearchBar } from "../../Components/SearchBar/SearchBar";
import { SearchContext } from "../../Context/SearchContext";
import {
  InventoryItem,
  useAccountSettingsQuery,
  useInventoryQuery,
  useUserQuery,
} from "../../Generated/graphql";
import { extractGTIN } from "../../Utils/extractGTIN";
import "./Inventory.css";
import { InventoryActions } from "./InventoryActions/InventoryActions";

const customCategories = ["Tørlager", "Vådlager", "Slatlager"];

export const Inventory = () => {
  const history = useHistory();
  const location = useLocation();
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const { data: userData, loading: userLoading } = useUserQuery();

  const { data, loading: inventoryLoading } = useInventoryQuery();
  const { data: accountData } = useAccountSettingsQuery();

  const categories = useMemo<string[]>(() => {
    if (data?.inventory) {
      const orderedInventory = orderBy(data.inventory, ["category"], ["asc"]);
      const categories = orderedInventory.map((item) => item.category);
      const uniqCategories = uniq(categories);
      // A small hack to place the custom products at the end of the list
      const uniqWithoutCustom = uniqCategories.filter(
        (category) => !customCategories.includes(category)
      );

      return [...uniqWithoutCustom, ...customCategories];
    }

    return [];
  }, [data]);

  const canEdit = accountData?.accountSettings?.usersCanEdit;
  const role = userData?.user?.role;

  const allowEdit =
    (canEdit && role === "EMPLOYEE") || role === "ADMIN" ? true : false;

  return (
    <InventoryContent
      categories={categories}
      inventoryItems={data?.inventory || []}
      history={history}
      location={location}
      accountId={accountData?.accountSettings?.id ?? ""}
      setClientSearchTerm={setSearchTerm}
      searchTerm={searchTerm || ""}
      hasErrors={null}
      canEdit={allowEdit}
      isLoading={userLoading || inventoryLoading}
    />
  );
};

type InventoryContentProps = {
  categories: string[];
  inventoryItems: InventoryItem[];
  history: any;
  location: any;
  accountId: string;
  setClientSearchTerm: (term: string) => void;
  searchTerm: string;
  hasErrors: boolean | null;
  canEdit: boolean;
  isLoading: boolean;
};

type InventoryContentState = {
  sortBy: { label: string; value: number }[];
  sortValue: number;
  showNotificationBarSuccess: boolean;
  filteredItems: InventoryItem[];
  windowWidth: number;
  scrollShouldStyle: boolean;
  actionClasses: string[];
  scannerInput: string;
};

class InventoryContent extends Component<
  InventoryContentProps,
  InventoryContentState
> {
  myRef = React.createRef();

  constructor(props: InventoryContentProps) {
    super(props);

    this.state = {
      sortBy: [
        {
          label: "Kategori",
          value: 1,
        },
        {
          label: "Antal",
          value: 2,
        },
        {
          label: "Navn",
          value: 3,
        },
      ],
      sortValue: 1,
      showNotificationBarSuccess: false,
      filteredItems: this.filterProduct(
        this.props.searchTerm && this.props.searchTerm.toLowerCase()
      ),
      windowWidth: 768,
      scrollShouldStyle: false,
      actionClasses: ["Inventory__actions"],
      scannerInput: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  UNSAFE_componentWillReceiveProps(nextProps: InventoryContentProps) {
    if (nextProps.searchTerm) {
      this.setState({
        filteredItems: this.filterProduct(
          nextProps.searchTerm
            ? nextProps.searchTerm.toLowerCase()
            : this.props.searchTerm
        ),
      });
    }
  }

  shouldComponentUpdate(
    newProps: InventoryContentProps,
    newState: InventoryContentState
  ) {
    if (
      this.props.location.pathname.includes("inventory") &&
      newProps.location.pathname.includes("inventory/edit")
    ) {
      return false;
    }
    if (this.props.searchTerm === newProps.searchTerm) {
      return true;
    }
    if (this.props === newProps && this.state === newState) {
      return false;
    } else {
      return true;
    }
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  scannerInputTimer: NodeJS.Timeout | null = null;

  regex = new RegExp("^[a-zA-Z0-9_.-]*$");

  handleKeyPress = (event: KeyboardEvent) => {    
    if (
      event &&
      event.key !== "Shift" &&
      event.key !== "Enter" &&
      event.key !== "Backspace" &&
      event.key !== "Tab" &&
      event.key !== "Control" &&
      event.key !== "Meta" &&
      this.regex.test(event.key)
    ) {
      // Clear the timeout
      if (this.scannerInputTimer) {
        clearTimeout(this.scannerInputTimer);
      }

      this.setState((prevStat) => ({
        scannerInput: prevStat.scannerInput.concat(event.key),
      }));

      // Setup a new timeout
      this.scannerInputTimer = setTimeout(() => {
        // Cleanup the scannerInput
        this.setState({ scannerInput: "" });
      }, 600);
    } else if (event && event.key === "Enter") {
      if (this.props.location.pathname === "/inventory") {
        // We fake the "event" part of it here
        // Change this to just accept the string instead, this is dumb        
        
        const extractedGTIN = extractGTIN(this.state.scannerInput)

        // @ts-ignore
        this.setSearchTerm({ target: { value: extractedGTIN || this.state.scannerInput } });
      }
      this.setState({ scannerInput: "" });
    }
  };

  filterProduct = (term: string) => {
    const searchTerm = term
      ? term.toLowerCase()
      : this.props.searchTerm.toLowerCase();
    return this.props.inventoryItems.reduce<InventoryItem[]>((acc, current) => {
      const barcode = current.barcode;
      const prodNo = current.productNumber;
      const name = current.name.toLowerCase();
      const description = current.description.toLowerCase();
      if (
        barcode.includes(searchTerm) ||
        prodNo.includes(searchTerm) ||
        name.includes(searchTerm) ||
        description.includes(searchTerm)
      ) {
        return [current, ...acc];
      } else {
        return acc;
      }
    }, []);
  };

  valueChangeHandler = (value: number) => {
    this.setState({ sortValue: value });
  };

  goToNew = () => {
    this.props.history.push("/inventory/new");
  };

  setSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.props.setClientSearchTerm(value.toLowerCase());
  };

  clearSearchTerm = () => {
    this.props.setClientSearchTerm("");
  };

  getOrganisedProducts = (
    searchTerm: string,
    sorting: number
  ): { title?: string; items: InventoryItem[] }[] => {
    if (searchTerm === "") {
      switch (sorting) {
        case 2:
          // Sort by amount in stock
          const sorted = orderBy(
            this.props.inventoryItems,
            (item) => item.amount,
            ["desc"]
          );
          return [{ items: sorted }];

        case 3:
          // Sort by name of item
          const sortedByName = orderBy(
            this.props.inventoryItems,
            (item) => item.name
          );
          return [{ items: sortedByName }];

        default:
          const categoriesWithItems = this.props.categories.map((stuff) => {
            return {
              title: stuff,
              items: orderBy(
                compact(
                  this.props.inventoryItems.map((prod) => {
                    if (prod.category === stuff) {
                      return prod;
                    } else {
                      return null;
                    }
                  })
                ),
                (x) => x.name
              ),
            };
          });
          return categoriesWithItems;
      }
    } else {
      if (sorting === 1) {
        const categoriesWithItems = this.props.categories.map(
          (categoryTitle) => {
            return {
              title: categoryTitle,
              items: orderBy(
                compact(
                  this.state.filteredItems.map((prod) => {
                    if (prod.category === categoryTitle) {
                      return prod;
                    } else {
                      return null;
                    }
                  })
                ),
                (x) => x.name
              ),
            };
          }
        );
        return categoriesWithItems;
      } else if (sorting === 2) {
        const sorted = orderBy(
          this.state.filteredItems,
          (item) => item.amount,
          ["desc"]
        );
        return [{ items: sorted }];
      } else if (sorting === 3) {
        const sortedByName = orderBy(
          this.state.filteredItems,
          (item) => item.name
        );
        return [{ items: sortedByName }];
      }
    }

    return [{ items: this.props.inventoryItems }];
  };

  handleScroll = (input: any) => {
    input.persist();
    if (
      input.nativeEvent.srcElement.scrollTop > 0 &&
      this.state.actionClasses.length !== 2
    ) {
      this.setState(
        {
          actionClasses: ["Inventory__actions", "Inventory__actions--shadowed"],
        },
        () => {
          input.nativeEvent.srcElement.scrollTop = 4;
        }
      );
    } else if (
      input.nativeEvent.srcElement.scrollTop === 0 &&
      this.state.actionClasses.length === 2
    ) {
      this.setState({
        actionClasses: ["Inventory__actions"],
      });
    }
  };

  render() {
    return (
      <div className="Inventory">
        <div className="Inventory__header">
          {this.state.windowWidth > 1023 && (
            <div className="Inventory__title">Lager</div>
          )}
          <div className="Inventory__search">
            <SearchBar
              searchTerm={this.props.searchTerm || ""}
              setSearchTerm={(e) => this.setSearchTerm(e)}
              clearSearchTerm={() => this.clearSearchTerm()}
            />
          </div>
        </div>

        <div className="Inventory__dividerbar" />

        <InventoryActions
          classes={this.state.actionClasses}
          valueChangeHandler={this.valueChangeHandler}
          sortValue={this.state.sortValue}
          sortBy={this.state.sortBy}
          goToNew={this.goToNew}
        />
        <div
          style={{ paddingTop: "60px", overflowY: "auto", height: "100%" }}
          className="Inventory__items-wrapper"
          onScroll={this.handleScroll}
        >
          {this.props.hasErrors ? (
            <div className="Inventory__error">
              Der skete en fejl, prøv igen.
            </div>
          ) : this.props.isLoading ? (
            <div className="Receive__loading">
              <BoxLoader text="HENTER LAGER" />
            </div>
          ) : (
            <>
              {this.state.sortValue === 1 &&
                this.getOrganisedProducts(
                  this.props.searchTerm,
                  this.state.sortValue
                )?.map(({ title = "", items },idx) => {
                  return items && items.length > 0 ? (
                    <div className="Inventory__items" key={title+idx}>
                      <div className="Inventory__category-title">{title}</div>
                      <div className="InventoryList__category-items">
                        {items
                          .filter((el) => el !== null)
                          .map((item) => {
                            return (
                              <InventoryListItem
                                key={item.productNumber}
                                windowWidth={this.state.windowWidth}
                                item={item}
                                canEdit={this.props.canEdit}
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : null;
                })}
              {this.state.sortValue === 2 || this.state.sortValue === 3 ? (
                <>
                  <div className="Inventory__category-title">Alle varer</div>
                  <div className="InventoryList__amount-items">
                    {this.getOrganisedProducts(
                      this.props.searchTerm,
                      this.state.sortValue
                    )[0].items.map((item) => {
                      return (
                        <div className="Inventory__items--amount" key={item.id}>
                          <InventoryListItem
                            windowWidth={this.state.windowWidth}
                            item={item}
                            canEdit={this.props.canEdit}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
        {this.props.searchTerm !== "" &&
          this.state.filteredItems.length === 0 && <NoFoundItems />}

        {this.state.showNotificationBarSuccess ? (
          <NotificationBar
            error={false}
            success={this.state.showNotificationBarSuccess}
            hideNotification={() =>
              this.setState({ showNotificationBarSuccess: false })
            }
          />
        ) : null}
      </div>
    );
  }
}
