import React from 'react';

import './ItemAddedToCart.css';
import { Icon } from '../Icon/Icon';

export const ItemAddedToCart = ({ windowWidth }) => {
  return (
    <div className="ItemAddedToCart">
      <Icon name="cartListviewGreen" width={19} />
      {windowWidth > 1399 ? (
        <div className="ItemAddedToCart__text">Tilføjet til bestilling</div>
      ) : windowWidth > 1023 ? (
        <div className="ItemAddedToCart__text">Tilføjet</div>
      ) : null}
    </div>
  );
};
