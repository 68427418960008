import React from 'react';
import { createPortal } from 'react-dom';

import './DeleteStockModal.css';
import { ExitButton } from '../ExitButton/ExitButton';
import { Icon } from '../Icon/Icon';

export const DeleteStockModal = ({ respond, open }) => {
  const modalRoot = document.getElementById('modal-root');
  return open
    ? createPortal(
        <div className="DeleteStockModal__backdrop">
          <div className="DeleteStockModal">
            <div className="DeleteStockModal__close-button">
              <div className="DeleteStockModal__close-text">Fortryd</div>
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="DeleteStockModal__title">
              <div className="DeleteStockModal__title-text">
                Er du sikker på at du vil slette varen?
              </div>
            </div>

            <div className="DeleteStockModal__info">
              <div className="DeleteStockModal__info-icon">
                <Icon name="trashcanWithRingEnabled" width={25} />
              </div>
              <div className="DeleteStockModal__info-text">
                Ved at slette varen, kan du påvirke værdien af lageret. Det kan
                have konsekvenser for dit regnskab.
              </div>
            </div>

            <div className="DeleteStockModal__buttons">
              <div
                className="DeleteStockModal__exit"
                onClick={() => respond(false)}
              >
                Fortryd
              </div>
              <div
                className="DeleteStockModal__confirm"
                onClick={() => respond(true)}
              >
                Slet vare
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
