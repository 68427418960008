import classNames from "classnames";
import { throttle } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Transition } from "react-spring/renderprops.cjs";
import { toast } from "react-toastify";
import { useBoolean } from "usehooks-ts";
import { Zoom } from "../../App";
import {
  AutomaticOrdering,
  InventoryItem,
  Order,
  OrderItem,
  OrderItemInput,
  OrderItemStatus,
  OrderNewItemInput,
  useUpdateOrderItemsMutation,
} from "../../Generated/graphql";
import { AddNewItems } from "../AddNewItems/AddNewItems";
import { Icon } from "../Icon/Icon";
import { OrderComment } from "../OrderComment/OrderComment";
import { OrderDetails } from "../OrderDetails/OrderDetails";
import { ScanItemInModal } from "../ScanItemInModal/ScanItemInModal";
import "./ActiveOrders.css";

export interface IUserOrderInput {
  productNumber: string;
  taken: number;
  excluded: boolean;
  price: number;
}

type ActiveOrdersProps = {
  order: Order;
  inventory: InventoryItem[];
};

const sortedOrderlines = (lines: OrderItem[]) => {

  if (!lines || lines?.length === 0) {
    return [];
  }

  // We want items with status === 'completed' at the bottom, with status 'backorder' in the middle, and status 'active' at the top
  const sortedLines = [...lines].sort((a, b) => {

    if (
      a.status === OrderItemStatus.Completed &&
      b.status !== OrderItemStatus.Completed
    ) {
      return 1;
    } else if (
      a.status !== OrderItemStatus.Completed &&
      b.status === OrderItemStatus.Completed
    ) {
      return -1;
    } else if (
      a.status === OrderItemStatus.Backorder &&
      b.status === OrderItemStatus.Active
    ) {
      return 1;
    } else if (
      a.status === OrderItemStatus.Active &&
      b.status === OrderItemStatus.Backorder
    ) {
      return -1;
    } else {
      return 0;
    }
  });
  return sortedLines;
};

export const ActiveOrders: FC<ActiveOrdersProps> = ({ order, inventory }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [newItems, setNewItems] = useState<OrderNewItemInput[]>([]);
  const [updateItems, setUpdateItems] = useState<OrderItemInput[]>([]);
  const [orderLines, setOrderlines] = useState<OrderItem[]>(
    sortedOrderlines(order.orderLines)
  );
  const [warningMessage, setWarningMessage] = useState<string | undefined>(
    undefined
  );
  const [showScanModal, setShowScanModal] = useState<boolean>(false);
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const {
    value: showNewItemsModal,
    toggle: toggleShowNewItemsModal,
  } = useBoolean(false);

  const [addingToStock, setAddingToStock] = useState<boolean>(false)

  const [updateOrderItems] = useUpdateOrderItemsMutation();

  useEffect(() => {
    setOrderlines(sortedOrderlines(order.orderLines));
  }, [order]);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const changeItemAmount = (productNumber: string, amount: number) => {
    const mappedOrderlines = orderLines.map((line) => {
      if (line.productNumber === productNumber) {
        return {
          ...line,
          amount: amount,
        };
      } else {
        return line;
      }
    });

    setOrderlines(mappedOrderlines);
  };

  const changeExcludedStateForProduct = (
    productNumber: string,
    excluded: boolean
  ) => {

    const mappedOrderlines = orderLines.map((line) => {
      if (line.productNumber === productNumber) {
        return {
          ...line,
          excluded: excluded,
        };
      } else {
        return line;
      }
    });

    setOrderlines(mappedOrderlines);
  };

  const toggleScan = () => setShowScanModal((prev) => !prev);

  // Calls the server to update a single item
  // This shall also go through the whole "new item" process if needed
  const handleAddItem = (item: OrderItemInput) => {
    if (item.excluded) {
      handleUpdateOrderItems([], [item]);
      return;
    }

    // Check if the item is a new, or existing item
    const existingInventoryItem = inventory.find(
      (invItem) => invItem.productNumber === item.productNumber
    );

    // If new, just set it in the "new" state
    // and return
    if (!existingInventoryItem) {
      setNewItems([
        {
          ...item,
          automaticOrder: AutomaticOrdering.Active,
          maximum: 1,
          minimum: 1,
        },
      ]);

      toggleShowNewItemsModal();
      return;
    }

    // If it's an update item - Just run the update, with an empty "new items" array
    handleUpdateOrderItems([], [item]);
  };

  const handleRemoveItems = (productNumbers: string[]) => {
    // Remove any item in product numbers from either new or update items
    const newProductNumbers = productNumbers.filter(
      (number) => !inventory.find((item) => item.productNumber === number)
    );
    const existingProductNumbers = productNumbers.filter((number) =>
      inventory.find((item) => item.productNumber === number)
    );

    setNewItems((prev) =>
      prev.filter((item) => !newProductNumbers.includes(item.productNumber))
    );
    setUpdateItems((prev) =>
      prev.filter(
        (item) => !existingProductNumbers.includes(item.productNumber)
      )
    );
  };

  const handleSelectItems = (productNumbers: string[]) => {
    // Add any item in product numbers from either new or update items

    // This includes existing items and excluded items
    const existingAndExcludedProductNumbers = productNumbers.filter(
      (productNumber) => {
        const orderItem = orderLines.find(
          (line) =>
            line.productNumber === productNumber &&
            line.status === OrderItemStatus.Active
        );

        const inventoryItem = inventory.find(
          (item) => item.productNumber === productNumber
        );

        return inventoryItem || orderItem?.excluded;
      }
    );

    const newProductNumbers = productNumbers.filter((productNumber) => {
      const orderItem = orderLines.find(
        (line) => line.productNumber === productNumber
      );
      const inventoryItem = inventory.find(
        (item) => item.productNumber === productNumber
      );

      return !inventoryItem && !orderItem?.excluded;
    });

    const existingOrderItems: OrderItemInput[] = orderLines
      .filter((line) =>
        existingAndExcludedProductNumbers.includes(line.productNumber)
      )
      .map((item) => ({
        id: item.id,
        excluded: item.excluded,
        price: item.price,
        productNumber: item.productNumber,
        taken: item.amount,
      }));

    const newOrderItems = orderLines
      .filter((line) => newProductNumbers.includes(line.productNumber))
      .map((item) => ({
        id: item.id,
        automaticOrder: AutomaticOrdering.Active,
        excluded: item.excluded,
        maximum: 1,
        minimum: 1,
        price: item.price,
        productNumber: item.productNumber,
        taken: item.amount,
      }));

    setNewItems((prev) => [...prev, ...newOrderItems]);
    setUpdateItems((prev) => [...prev, ...existingOrderItems]);
  };

  const handleUpdateOrderItems = async (
    newItems: OrderNewItemInput[],
    updateItems: OrderItemInput[],
    warningMessage?: string
  ) => {
    if(addingToStock) {
      return
    }

    setAddingToStock(true);

    // This is where we call the mutation, not anywhere else.
    await updateOrderItems({
      variables: {
        items: updateItems,
        newItems: newItems,
        orderId: order.id,
        withWarningMessage: warningMessage,
      },
    })
      .then(() => {
        toast("Ordre opdateret", {
          className: "u-toast-success",
          progressClassName: "u-toast-success-bar",
          transition: Zoom,
        });
        setUpdateItems([]);
        setNewItems([]);
        setAddingToStock(false);
      })
      .catch(() => {
        toast("Fejl ved opdatering", {
          className: "u-toast-error",
          progressClassName: "u-toast-success-bar",
          transition: Zoom,
        });
        setUpdateItems([]);
        setNewItems([]);
        setAddingToStock(false);
      });
  };

  const onNewItemsModalClose = (formattedNewItems: OrderNewItemInput[]) => {
    if(addingToStock) {
      return
    }

      if (formattedNewItems.length === 0) {
        toggleShowNewItemsModal();
        setNewItems([]);
        return;
      }
  
      handleUpdateOrderItems(formattedNewItems, updateItems, warningMessage).then(
        () => {
          toggleShowNewItemsModal();
        }
      );
  };

  const onTriggerUpdateOrderItems = () => {
    if(addingToStock) {
      return
    }

    if (newItems.length === 0 && updateItems.length === 0) {
      return;
    }
    if (newItems.length === 0) {      
      // No "new" items, updating items without showing modal first
      handleUpdateOrderItems([], updateItems)
      return;
    }
    // We have new items, showing modal
    // so the user can setup their new items
    toggleShowNewItemsModal();
  };

  const debouncedTriggerUpdateOrderItems = throttle(onTriggerUpdateOrderItems, 1000)

  const onAddScannedItems = (
    items: OrderItemInput[],
    warningMessage?: string
  ) => {
    if(addingToStock) {
      return
    }

    // Add any item in product numbers from either new or update items
    const existingOrderItems = items.filter((item) =>
      inventory.find(
        (invItem) =>
          invItem.productNumber === item.productNumber || item.excluded
      )
    );

    const newOrderItems = items
      .filter(
        (item) =>
          !inventory.find(
            (invItems) =>
              invItems.productNumber === item.productNumber && !item.excluded
          )
      )
      .map((item) => ({
        id: item.id,
        automaticOrder: AutomaticOrdering.Active,
        excluded: item.excluded,
        maximum: 1,
        minimum: 1,
        price: item.price,
        productNumber: item.productNumber,
        taken: item.taken,
      }));

    // If we have new items, set them in state, so we can do the magic
    if (newOrderItems.length > 0) {
      setWarningMessage(warningMessage);
      setNewItems(newOrderItems);
      setUpdateItems(existingOrderItems);
      toggleShowNewItemsModal();
      return;
    }

    // In case we have no "new items", just go ahead an update
    handleUpdateOrderItems([], existingOrderItems, warningMessage);
  };

  const borderStyle = ["ActiveOrders", "ActiveOrders__not-expanded"];
  const borderStyleExpanded = ["ActiveOrders", "ActiveOrders__expanded"];

  return (
    <div
      className={classNames("ActiveOrder__order", {
        "ActiveOrder__order--expanded": showOrder,
      })}
    >
      <ScanItemInModal
        items={order.orderLines}
        orderNumber={order.id}
        toggleScan={toggleScan}
        addScannedItems={onAddScannedItems}
        hideMe={() => setShowScanModal(false)}
        open={showScanModal}
      />

      <Transition
        items={showNewItemsModal}
        config={{
          tension: 110,
          friction: 20,
          precision: 0.1,
          velocity: 14,
        }}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(showAddNewItemsModal) =>
          showAddNewItemsModal &&
          ((props) => (
            <AddNewItems
              key={order.id}
              items={newItems}
              orderId={order.id}
              close={onNewItemsModalClose}
              style={props}
            />
          ))
        }
      </Transition>

      <div
        className={
          showOrder ? borderStyleExpanded.join(" ") : borderStyle.join(" ")
        }
        onClick={(e) => {
          if (
            e.currentTarget.id !== "ActiveOrder__commentclicker" &&
            !showComments
          ) {
            setShowOrder((prev) => !prev);
          }
        }}
      >
        <div className="ActiveOrders__icon">
          <Icon name="cartListview" width={28} className="Icon__center" />
        </div>
        <div className="ActiveOrders__order">
          <div className="ActiveOrders__firstPart">
            <div className="ActiveOrders__order-number">Ordre {order.id}</div>
            {order.deliveryId ? (<div className="ActiveOrders__delivery-number">
              Leverance {order.deliveryId}
            </div>) : (<></>)}
            {windowWidth > 1024 ? (
              <div className="ActiveOrders__order-date">
                Bestilt{" "}
                {moment(order.orderDate, "YYYYMMDD").format("DD[.] MMMM YYYY")}
              </div>
            ) : (
              <div className="ActiveOrders__order-date">
                {moment(order.orderDate, "YYYYMMDD").format(
                  "DD[.] MMM[.] YYYY"
                )}
              </div>
            )}
          </div>
          <div className="ActiveOrders__secondPart">
            <div className="ActiveOrders__order-amount">
              {order.orderLines.length} varer i alt
            </div>
            <div className="ActiveOrders__order-from">
              Bestilt fra{" "}
              {order.orderedFrom === 2
                ? "lagerstyring"
                : order.orderedFrom === 1
                  ? "webshop"
                  : "internt salg"}
            </div>
          </div>
          <div className="ActiveOrders__thirdPart">
            {order &&
              order.comments &&
              Array.isArray(order.comments) &&
              order.comments.length > 0 && (
                <div
                  id="ActiveOrder__commentclicker"
                  className="ActiveOrder__comment-clicker"
                  onClick={() => setShowComments((prev) => !prev)}
                >
                  <OrderComment
                    comments={order.comments}
                    displayComments={showComments}
                    orderId={order.id}
                  />
                </div>
              )}
          </div>
          <div className="ActiveOrders__see-order u-cursor-pointer">
            <div className="ActiveOrders__see-order-text">Se bestilling</div>
            <div
              style={
                showOrder
                  ? {
                    transform: "translate(11px, -1px)",
                    transition: "transform 100ms ease-out",
                  }
                  : {
                    transform: "translate(11px, 1px) rotate(-90deg)",
                    transition: "transform 100ms ease-out",
                  }
              }
            >
              <Icon name="arrowDown" width={11} />
            </div>
          </div>
        </div>
      </div>
      {showOrder && (
        <OrderDetails
          showMe={showOrder}
          orderLines={orderLines}
          openScan={toggleScan}
          windowWidth={windowWidth}
          changeItemAmount={changeItemAmount}
          addItem={handleAddItem}
          addingToStock={addingToStock}
          removeItems={handleRemoveItems}
          selectItems={handleSelectItems}
          selectedItems={[...newItems, ...updateItems].map(
            (item) => item.productNumber
          )}
          triggerUpdateOrderItems={debouncedTriggerUpdateOrderItems}
          changeExcludedState={changeExcludedStateForProduct}
        />
      )}
    </div>
  );
};
