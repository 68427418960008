import { times } from "lodash";
import { useEffect, useState } from "react";

import {
  useInventoryQuery,
  useWatchedProductsQuery,
  WatchedProduct as WatchedProductType,
} from "../../../Generated/graphql";
import { BoxLoader } from "../../BoxLoader/BoxLoader";
import { Icon } from "../../Icon/Icon";
import "./DashboardProductWatch.css";
import { NoWatchedProducts } from "./NoWatchedProducts/NoWatchedProducts";
import { WatchedProduct } from "./WatchedProduct/WatchedProduct";
import { WatchedProductConfirmAction } from "./WatchedProductConfirmAction/WatchedProductConfirmAction";

type Props = {
  watchedProducts: WatchedProductType[];
  error?: Error;
};

const DashboardProductWatch = ({ watchedProducts, error }: Props) => {
  const {
    data: inventoryData,
    loading: inventoryLoading,
  } = useInventoryQuery();
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedItems, setDisplayedItems] = useState<WatchedProductType[]>(
    []
  );
  const [confirmActionModal, setConfirmActionModal] = useState(false);
  const [watchedProductsOnLoad] = useState(watchedProducts.length || 0);

  useEffect(() => {
    const dataCopy = [...watchedProducts];

    const selectedItems = dataCopy.slice(
      currentPage * itemsPerPage,
      currentPage * itemsPerPage + itemsPerPage
    );

    setDisplayedItems(selectedItems);
  }, [currentPage, watchedProducts]);

  const totalPagesNeeded = Math.ceil(watchedProducts.length / itemsPerPage);

  return (
    <div className="DashboardProductWatch">
      <WatchedProductConfirmAction
        open={confirmActionModal}
        respond={() => setConfirmActionModal(false)}
      />
      <div className="DashboardProductWatch__title">
        <div className="DashboardProductWatch__title-text">
          Produktovervågning
        </div>
        <div className="DashboardProductWatch__icon">
          <Icon name="productWatchIcon" width={45} />
        </div>
      </div>
      {displayedItems.length > 0 && !inventoryLoading && (
        <div className="DashboardProductWatch__items">
          {displayedItems.map((product) => (
            <WatchedProduct
              item={product}
              key={product.id}
              onExitCall={() => setConfirmActionModal(true)}
              inventoryItem={inventoryData?.inventory.find(
                (item) => item.productNumber === product.productNumber
              )}
            />
          ))}
        </div>
      )}
      {(error || displayedItems.length === 0) && (
        <div className="DashboardProductWatch__items">
          <NoWatchedProducts watchedProductsOnLoad={watchedProductsOnLoad} />
        </div>
      )}
      <div className="DashboardProductWatch__more">
        {totalPagesNeeded > 1 &&
          times(totalPagesNeeded, (n) => (
            <MoreDot
              key={n}
              active={n === currentPage}
              click={() => setCurrentPage(n)}
            />
          ))}
      </div>
    </div>
  );
};

const MoreDot = ({
  active = false,
  click,
}: {
  click: () => void;
  active: boolean;
}) => (
  <div className="MoreDot__target" onClick={click}>
    <div className={active ? "MoreDot MoreDot__active" : "MoreDot"} />
  </div>
);

const DashboardProductWatchFetcher = () => {
  const { data, error, loading } = useWatchedProductsQuery({
    fetchPolicy: "network-only",
    pollInterval: 30_000,
  });
  if (loading) return <BoxLoader />;
  return (
    <DashboardProductWatch
      watchedProducts={data?.watchedProducts ?? []}
      error={error}
    />
  );
};

export { DashboardProductWatchFetcher as DashboardProductWatch };
