export const extractGTIN = (input: string): string | null => {
  /**
   * Regular expression to match the GTIN (01) followed by exactly 14 digits
   * https://www.gs1.dk/om-gs1/overblik-over-gs1-standarder/application-identifiers#01
   * */

  const gtinRegex = /01(\d{14})/;

  const match = gtinRegex.exec(input);

  // If a match is found, return the GTIN part without leading zeroes
  if (match && match[1]) {
    return match[1].replace(/^0+/, "");
  }

  // If no match is found, return null
  return null;
};
