import moment from "moment";
import { useEffect, useState } from "react";
import { unitMap } from "../../App";
import { InventoryItem, ScanItem } from "../../Generated/graphql";
import { getImageWithSize } from "../../Utils/getImageWithSize";
import { limitString } from "../../Utils/limitString";
import { noop } from "../../Utils/noop";
import { Icon } from "../Icon/Icon";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { ScanCardActions } from "../ScanCardActions/ScanCardActions";
import { DeleteItem } from "./DeleteItem/DeleteItem";
import "./ScanCard.css";

interface IScanCardProps {
  item: ScanItem;
  stockItem?: InventoryItem | null;
  updateScannedItem: ({
    id,
    amount,
    barcode,
  }: {
    id: string;
    amount: number;
    barcode: string;
  }) => void;
  deleteScannedItem: ({
    id,
    productNumber,
  }: {
    id: string;
    productNumber: string;
  }) => void;
}

export const ScanCard = ({
  item,
  stockItem,
  updateScannedItem,
  deleteScannedItem,
}: IScanCardProps) => {
  const [, updateComponent] = useState(Date.now());

  useEffect(() => {
    const updateInterval = setInterval(() => {
      updateComponent(Date.now());
    }, 600000);

    return () => {
      clearInterval(updateInterval);
    };
  });

  const [flipped, setFlipped] = useState(false);

  const unitAsText = unitMap.find((x) => x.key === stockItem?.unit);
  const image = stockItem?.images[0];

  const isEditable =
    item?.eventTime !== undefined &&
    moment()
      .subtract(48, "hours")
      .isBefore(moment(item?.eventTime));

  if (!stockItem) {
    return null;
  }

  return (
    <div className="ScanCard__flip-container">
      <div
        className={
          flipped
            ? "ScanCard__flipper ScanCard__flip-container-activated"
            : "ScanCard__flipper"
        }
      >
        <div className="ScanCard__front">
          <div className="ScanCard">
            <div className="ScanCard__image">
              {image ? (
                <img
                  src={image ? getImageWithSize(image.url, "400x400") : ""}
                  alt={"Produkt"}
                />
              ) : (
                <Icon name="noImageAvail" width={200} />
              )}
            </div>
            {isEditable && (
              <div
                className="ScanCard__delete-product"
                onClick={() => setFlipped(!flipped)}
              >
                <div className="ScanCard__delete-ring">
                  <Icon name="trashcanTertiaryBlue" width={14} />
                </div>
              </div>
            )}
            <div className="ScanCard__content">
              <div className="ScanCard__title">
                <div className="ScanCard__title--text">{stockItem.name}</div>
              </div>
              <div className="ScanCard__details">{stockItem.description}</div>
              <div className="ScanCard__id">
                Varenr: {limitString(stockItem.productNumber, 5)}
              </div>
              <div className="ScanCard__stockIndicator">
                <div className="ScanCard__stockIndicator__text">
                  Lagerstatus
                </div>
                <div className="ScanCard__stockIndicator__text ScanCard__stockIndicator__inventoryAmount">
                  {stockItem.isCustom
                    ? stockItem.amount - item.amount
                    : stockItem.amount}
                  &nbsp;
                  {unitAsText ? unitAsText.value : ""}
                  &nbsp;på lager
                </div>
                <div className="ScanCard__stockIndicator__bar">
                  <ProgressBar
                    current={
                      stockItem.isCustom
                        ? stockItem.amount - item.amount
                        : stockItem.amount
                    }
                    min={stockItem.minimum}
                    max={stockItem.maximum}
                  />
                </div>
              </div>
            </div>
            <div className="ScanCard__actions">
              <ScanCardActions
                editable={isEditable}
                taken={item?.amount ?? 0}
                setTaken={(v: number) => {
                  if (v === 0) {
                    if (!isEditable) {
                      noop();
                    } else {
                      setFlipped(!flipped);
                    }
                  } else if (stockItem.amount - 1 === -1 && item.amount < v) {
                    noop();
                  } else {
                    updateScannedItem({
                      barcode: item.barcode,
                      id: item.id,
                      amount: v,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="ScanCard__back">
          <DeleteItem
            id={stockItem.productNumber}
            name={stockItem.name}
            description={stockItem.description}
            remove={() => {
              deleteScannedItem({
                id: item.id,
                productNumber: stockItem.productNumber,
              });
              setFlipped(!flipped);
            }}
            regret={() => setFlipped(!flipped)}
          />
        </div>
      </div>
    </div>
  );
};
