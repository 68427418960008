import React, { Component } from "react";
import PropTypes from "prop-types";
import { animated } from "react-spring/renderprops.cjs";

import "./Select.css";

import { noop } from "../../Utils/noop";
import { Icon } from "../Icon/Icon";

export * from "./SelectOption/SelectOption";

export class Select extends Component {
  state = {
    isOpen: false
  };

  node;
  optionsNode;

  toggle = () => {
    this.state.isOpen ? this.close() : this.open();
  };

  open() {
    this.setState({ isOpen: true });
    this.addOutsideClickHandler();
    this.scrollContainerToSelectedOption();
  }

  close() {
    this.setState({ isOpen: false });
    this.removeOutsideClickHandler();
  }

  select(value) {
    this.props.change(value);
    this.close();
  }

  getLabel(value) {
    const selectedChild = React.Children.toArray(this.props.children).find(
      child => child.props.value === value
    );

    return selectedChild
      ? selectedChild.props.children
      : this.props.disabled
      ? "Deaktiveret"
      : "Please select an option";
  }

  scrollContainerToSelectedOption() {
    if (this.optionsNode) {
      const selectedNodes = this.optionsNode.getElementsByClassName(
        "is-selected"
      );

      if (selectedNodes.length > 0 && selectedNodes[0].scrollIntoView) {
        selectedNodes[0].scrollIntoView();
      }
    }
  }

  outsideClickHandler = e => {
    // Don't trigger on the component node
    if (this.node.contains(e.target)) {
      return;
    }

    this.close();
  };

  addOutsideClickHandler() {
    document.addEventListener("mousedown", this.outsideClickHandler);
  }

  removeOutsideClickHandler() {
    document.removeEventListener("mousedown", this.outsideClickHandler);
  }

  render() {
    const childrenWithSelectedPoperty = React.Children.map(
      this.props.children,
      child => {
        return React.cloneElement(child, {
          click: value => this.select(value),
          isSelected: child.props.value === this.props.value
        });
      }
    );

    const classes = ["Select"];

    classes.push(`Select--${this.props.type}`);

    if (this.state.isOpen) {
      classes.push("is-open");
    }

    if (this.props.disabled) {
      classes.push("Select--disabled");
    }

    return (
      <div className={classes.join(" ")} ref={node => (this.node = node)}>
        <div className="Select__element">
          <div
            className="Select__value u-no-text-select"
            onClick={this.props.disabled ? noop : this.toggle}
          >
            {this.getLabel(this.props.value)}
          </div>
          <Icon name="arrowDown" width={11} className="Select__icon" />
        </div>

        <animated.div
          className="Select__options"
          ref={node => (this.optionsNode = node)}
        >
          {childrenWithSelectedPoperty}
        </animated.div>
      </div>
    );
  }
}

Select.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  change: PropTypes.func.isRequired
};

Select.defaultProps = {
  type: "default",
  change: noop
};
