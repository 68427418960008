import { FC } from "react";
import "./ProgressBar.css";

type Props = {
  current?: number;
  min?: number;
  max?: number;
};

export const ProgressBar: FC<Props> = ({ current = 1, min = 1, max = 1 }) => {
  const correctedCurrent = () => {
    let correctedCurrent;
    current > max ? (correctedCurrent = max) : (correctedCurrent = current);

    const roundingStyle = isNaN((100 / max) * correctedCurrent)
      ? { borderRadius: "7px" }
      : (100 / max) * correctedCurrent > 99
      ? { borderRadius: "7px" }
      : { borderRadius: "7px 0 0 7px" };

    return { correctedCurrent, roundingStyle };
  };

  return (
    <div className="ProgressBar">
      {correctedCurrent().correctedCurrent < min ? (
        <span
          className="ProgressBar__bar"
          style={{
            width: (100 / max) * correctedCurrent().correctedCurrent + "%",
            backgroundColor: "#AA2525",
          }}
        />
      ) : correctedCurrent().correctedCurrent < (max / 100) * 25 ? (
        <span
          className="ProgressBar__bar"
          style={{
            width: (100 / max) * correctedCurrent().correctedCurrent + "%",
            backgroundColor: "#D69D23",
          }}
        />
      ) : (
        <span
          className="ProgressBar__bar"
          style={{
            width: (100 / max) * correctedCurrent().correctedCurrent + "%",
            backgroundColor: "#68CEA2",
            ...correctedCurrent().roundingStyle,
          }}
        />
      )}
    </div>
  );
};
