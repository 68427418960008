import moment from "moment";
import { useState } from "react";
import { useTotalsValueQuery } from "../../../Generated/graphql";
import { BoxLoader } from "../../BoxLoader/BoxLoader";
import { DashboardFiveItemList } from "../DashboardFiveItemList/DashboardFiveItemList";
import { monthOptions } from "../DashboardMaterialBuys/DashboardMaterialBuys";
import { DashboardNotEnoughDataToCalculate } from "../DashboardNotEnoughDataToCalculate/DashboardNotEnoughDataToCalculate";
import { DashboardPeriodSelector } from "../DashboardPeriodSelector/DashboardPeriodSelector";
import "./DashboardTotals.css";
import { DashboardTotalsTreeMap } from "./DashboardTotalsTreeMap/DashboardTotalsTreeMap";

export const DashboardTotals = ({
  accountancyYear,
}: {
  accountancyYear: number;
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState({
    name: "Seneste 30 dage",
    value: "30days",
  });
  const { data, error, loading } = useTotalsValueQuery({
    variables: { period: selectedPeriod.value, accountancyYear },
    fetchPolicy: "network-only",
  });

  return (
    <div className="DashboardTotals">
      <div className="DashboardTotals__selector">
        <DashboardPeriodSelector
          hasAccountancyYear={accountancyYear ? true : false}
          setSelectedMonth={setSelectedPeriod}
          selectedMonth={selectedPeriod}
          monthOptions={
            accountancyYear
              ? [
                  ...monthOptions,
                  {
                    name: "Regnskabsår " + moment().format("YYYY"),
                    value: "accountancyYear",
                  },
                  {
                    name:
                      "Regnskabsår " +
                      moment()
                        .subtract(1, "year")
                        .format("YYYY"),
                    value: "lastAccountancyYear",
                  },
                  {
                    name:
                      "Regnskabsår " +
                      moment()
                        .subtract(2, "year")
                        .format("YYYY"),
                    value: "lastlastAccountancyYear",
                  },
                ]
              : monthOptions
          }
        />
      </div>
      {!error && loading && (
        <div className="DashboardTotals__loading">
          {" "}
          <BoxLoader />
        </div>
      )}
      <div className="DashboardTotals__title">
        <div className="DashboardTotals__title-text">Fordeling af totalkøb</div>
      </div>
      {!loading &&
        data &&
        data.totalsValue &&
        !error &&
        data.totalsValue.filter((x) => x.value !== 0).length > 0 && (
          <div className="DashboardTotals__content">
            <div className="DashboardTotals__treemap">
              <DashboardTotalsTreeMap items={data.totalsValue} />
            </div>
            <div className="DashboardTotals__list">
              <DashboardFiveItemList items={data.totalsValue} />
            </div>
          </div>
        )}
      {!loading &&
        data &&
        data.totalsValue &&
        !error &&
        data.totalsValue.filter((x) => x.value !== 0).length <= 0 && (
          <DashboardNotEnoughDataToCalculate />
        )}
      {error && <DashboardNotEnoughDataToCalculate type="error" />}
    </div>
  );
};
