import { FC, PropsWithChildren } from "react";
import "./ShowMoreButton.css";

type Props = {
  onClick: () => void;
};

export const ShowMoreButton: FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
}) => {
  return (
    <button className="ShowMoreButton" onClick={onClick}>
      {children}
    </button>
  );
};
