import { ChangeEvent, CSSProperties, FC } from "react";
import "./TextArea.css";

type Props = {
  value?: string;
  setValue: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  styling?: "default" | "order-comment";
  placeholderText?: string;
  resizable?: CSSProperties["resize"];
  height: CSSProperties["height"];
};

export const TextArea: FC<Props> = ({
  value = "",
  setValue,
  styling = "default",
  placeholderText = "Skriv kommentar...",
  resizable = "none",
  height = "auto",
}) => {
  return (
    <div className="TextArea" style={{ height: height }}>
      <textarea
        value={value}
        onChange={setValue}
        className={`TextArea__reset TextArea__${styling}`}
        autoFocus={true}
        placeholder={placeholderText}
        style={{ resize: resizable }}
      ></textarea>
    </div>
  );
};
