import { Redirect, Route, Switch } from "react-router-dom";
import { Transition } from "react-spring/renderprops.cjs";
import { SlideInComponent } from "../../Components/SlideInComponent/SlideInComponent";
import { SuperAdminAccounts } from "../../Components/SuperAdminAccounts/SuperAdminAccounts";
import { SuperAdminEditAccount } from "../../Components/SuperAdminEditAccount/SuperAdminEditAccount";
import { SuperAdminEditHoliday } from "../../Components/SuperAdminEditHoliday/SuperAdminEditHoliday";
import { SuperAdminHolidays } from "../../Components/SuperAdminHolidays/SuperAdminHolidays";
import { SuperAdminNewAccount } from "../../Components/SuperAdminNewAccount/SuperAdminNewAccount";
import { SuperAdminNewHoliday } from "../../Components/SuperAdminNewHoliday/SuperAdminNewHoliday";
import { AdminMenu } from "./AdminMenu/AdminMenu";
import "./SuperAdmin.css";

export const SuperAdmin = () => {
  return (
    <div className="SuperAdmin">
      <AdminMenu />
      <SuperAdminContent />
    </div>
  );
};

const SuperAdminContent = () => {
  const getLastItemInList = (location: Location) => {
    const list = location.pathname.split("/");
    if (list.length >= 3) {
      return list[3];
    } else {
      return list[2];
    }
  };

  return (
    <div className="SuperAdminContent">
      <Route
        path="/superadmin"
        render={({ location, history }) => (
          <>
            <Switch>
              <Route
                path="/superadmin/accounts"
                render={() => <SuperAdminAccounts />}
              />
              <Route
                path="/superadmin/holidays"
                render={() => <SuperAdminHolidays />}
              />

              <Route
                path="/superadmin"
                exact
                render={() => <Redirect to="/superadmin/accounts" />}
              />
            </Switch>
            <Transition
              native
              // @ts-ignore
              items={location}
              keys={getLastItemInList}
              config={{
                tension: 270,
                friction: 40,
                precision: 0.01,
                velocity: 30,
              }}
              from={{
                opacity: 0,
                transform: "translateX(100%)",
              }}
              enter={{
                opacity: 1,
                transform: "translateX(0%)",
              }}
              leave={{
                opacity: 0,
                transform: "translateX(100%)",
              }}
            >
              {(loc, state) => (style) => {
                return (
                  // @ts-ignore
                  <Switch location={state === "update" ? location : loc}>
                    <Route
                      path="/superadmin/accounts/new"
                      render={(props) =>
                        SlideInComponent({
                          ...props,
                          style,
                          component: <SuperAdminNewAccount />,
                          closeModal: history,
                        })
                      }
                    />
                    <Route
                      path="/superadmin/holidays/new"
                      render={(props) =>
                        SlideInComponent({
                          ...props,
                          style,
                          component: <SuperAdminNewHoliday />,
                          closeModal: history,
                        })
                      }
                    />
                    <Route
                      path="/superadmin/holidays/edit/:id"
                      render={(props) =>
                        SlideInComponent({
                          ...props,
                          style,
                          component: <SuperAdminEditHoliday />,
                          closeModal: history,
                        })
                      }
                    />
                    <Route
                      path="/superadmin/accounts/edit/:accountId"
                      render={(props) =>
                        SlideInComponent({
                          ...props,
                          style,
                          component: <SuperAdminEditAccount />,
                          closeModal: history,
                        })
                      }
                    />
                  </Switch>
                );
              }}
            </Transition>
          </>
        )}
      />
    </div>
  );
};
