import { FC } from "react";

import "./InventoryActions.css";
import { Select, SelectOption } from "../../../Components/Select/Select";
import { Button } from "../../../Components/Button/Button";
import { Icon } from "../../../Components/Icon/Icon";
import { ExportComponentWithDates } from "../../../Components/ExportComponentWithDates/ExportComponentWithDates";
import {
  useAccountSettingsQuery,
  useUserQuery,
} from "../../../Generated/graphql";

type Props = {
  classes: string[];
  valueChangeHandler: (value: number) => void;
  sortValue: number;
  sortBy: {
    label: string;
    value: number;
  }[];
  goToNew: () => void;
};

export const InventoryActions: FC<Props> = ({
  classes,
  valueChangeHandler,
  sortValue,
  sortBy,
  goToNew,
}) => {
  const { data: accountData } = useAccountSettingsQuery();
  const { data: userData } = useUserQuery();

  const canEdit = accountData?.accountSettings?.usersCanExport;
  const role = userData?.user?.role;

  const allowEdit =
    (canEdit && role === "EMPLOYEE") || role === "ADMIN" ? true : false;

  return (
    <div className={classes.join(" ")}>
      <div className="Inventory__actions-backdrop">
        <div className="Inventory__sorting">
          Sorter efter:
          <Select
            className="ToggleInputControl__select-input"
            change={(v: any) => valueChangeHandler(v)}
            value={sortValue}
            type="default"
          >
            {sortBy.map(({ label, value }) => (
              <SelectOption key={value} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </div>

        {allowEdit && <ExportComponentWithDates withBorders={true} />}

        <div className="Inventory__newItem">
          <Button buttonStyle="newItem" width="129px" click={goToNew}>
            <Icon name="add" width={12} height={12} />
            <div style={{ transform: "translateY(-1px)" }}>Opret ny vare</div>
          </Button>
        </div>
      </div>
    </div>
  );
};
