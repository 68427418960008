import React from "react";
import { noop } from "../../Utils/noop";
import { Icon } from "../Icon/Icon";
import "./ExitButton.css";

export const ExitButton = ({ click = noop, text = "", solidBack = false }) => {
  return (
    <div className="ExitButton" onClick={click}>
      <div className="ExitButton__text">{text}</div>
      <div
        className="ExitButton__icon"
        style={{ backgroundColor: solidBack ? "#fff" : null }}
      >
        <Icon
          name="closeBlue"
          width={20}
          height={20}
          className="Icon__center"
        />
      </div>
    </div>
  );
};
