import {
  AutomaticOrdering,
  OrderNewItemInput,
  Product,
} from "../../../Generated/graphql";
import { ModalButton } from "../../ModalButton/ModalButton";
import { NumberInput } from "../../NumberInput/NumberInput";
import { PrintBarcode } from "../../PrintBarcode/PrintBarcode";
import "./AddNewItemsItem.css";

interface IAddNewItemsItemProps {
  item: OrderNewItemInput;
  change: (prodNo: string, value: number, name: "minimum" | "maximum") => void;
  changeOrderState: (prodNo: string) => void;
  product?: Product;
}

export const AddNewItemsItem = ({
  item,
  product,
  change,
  changeOrderState,
}: IAddNewItemsItemProps) => {
  return (
    <div className="AddNewItemsItem">
      <div className="ScanItemInModalItem__itemDetails">
        <div className="ScanItemInModalItem__name" style={{ color: "#0C2C52" }}>
          {product?.productData.name ?? ""}
        </div>
        <div className="ScanItemInModalItem__id" style={{ color: "#6D7A85" }}>
          {item.productNumber}
        </div>
      </div>
      <div className="ScanItemInModalItem__minimum">
        <NumberInput
          withButtons={false}
          value={item.minimum}
          change={(e) =>
            change(item.productNumber, Number(e.target.value), "minimum")
          }
        />
      </div>

      <div className="ScanItemInModalItem__wanted">
        <NumberInput
          withButtons={false}
          value={item.maximum}
          change={(e) =>
            change(item.productNumber, Number(e.target.value), "maximum")
          }
        />
      </div>
      <div className="ScanItemInModalItem__autoReoder">
        <ModalButton
          buttonStyle="toggleButton"
          iconName={
            item.automaticOrder === AutomaticOrdering.Active
              ? "autoOrderActive"
              : "orderingDisabled"
          }
          labelStyle="toggleLabel"
          autoOrderState={item.automaticOrder}
          width={19}
          click={() => changeOrderState(item.productNumber)}
        />
      </div>
      <div className="ScanItemInModalItem__label">
        <PrintBarcode
          barcode={product?.productData.barcode}
          name={product?.productData.name || ""}
          prodNo={item.productNumber}
          description={product?.productData.description}
        />
      </div>
    </div>
  );
};
