import { get } from "lodash";
import { CartItem } from "../../Generated/graphql";
import { getImageWithSize } from "../../Utils/getImageWithSize";
import { Icon } from "../Icon/Icon";
import { NumberInput } from "../NumberInput/NumberInput";
import "./OrderNewItem.css";

interface OrderNewItemProps {
  item: CartItem;
  addOne: (e?: any) => void;
  minusOne: (e?: any) => void;
  change: (e?: any) => void;
  removeItem: (e?: any) => void;
}

export const OrderNewItem = ({
  item,
  addOne,
  minusOne,
  change,
  removeItem,
}: OrderNewItemProps) => {
  const image = get(item, "images[0].url", null);

  return (
    <div className="OrderNewItem">
      <div className="OrderNewItem__image">
        {image ? (
          <img
            className="OrderNewItem__image-tag"
            src={getImageWithSize(image, "65x65")}
            alt="Fejl"
          />
        ) : (
          <Icon name="noImageAvail" width={65} />
        )}
      </div>
      <div className="OrderNewItem__item-text">
        <div className="OrderNewItem__name">{get(item, "name", " ")}</div>
        <div className="OrderNewItem__id">Varenr. {item.productNumber}</div>
        <div className="OrderNewItem__description">
          <div className="OrderNewItem__description-text">
            {get(item, "description", " ")}
          </div>
        </div>
      </div>
      <div className="OrderNewItem__number-input">
        <NumberInput
          value={item.amount}
          addOneFunction={addOne}
          minusOneFunction={minusOne}
          change={change}
        />
      </div>
      <div className="OrderNewItem__icon u-cursor-pointer" onClick={removeItem}>
        <Icon name="trashcanTertiaryBlue" width={9} />
      </div>
    </div>
  );
};
