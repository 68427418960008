import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import "./AdminMenu.css";
import { SettingsMenuItem } from "../../../Components/SettingsMenuItem/SettingsMenuItem";

export const AdminMenu = () => {
  const location = useLocation();
  const history = useHistory();
  return (
    <div className="AdminMenu">
      <div className="AdminMenu__title">
        <div className="AdminMenu__title-text">Administration</div>
        <div className="AdminMenu__sub-title-text">Lagerstyring</div>
      </div>
      <div className="UserSettings__menu-item UserSettings__menu-item-first">
        <SettingsMenuItem
          label="Konti"
          click={() => {
            history.push("/superadmin/accounts");
          }}
          active={location.pathname === "/superadmin/accounts" ? true : false}
        />
        <SettingsMenuItem
          label="Helligdage"
          click={() => {
            history.push("/superadmin/holidays");
          }}
          active={location.pathname === "/superadmin/holidays" ? true : false}
        />
      </div>
    </div>
  );
};
