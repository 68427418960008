import { get } from "lodash";
import { FC, Fragment } from "react";
import { unitMap } from "../../../App";
import { OrderItem, useProductQuery } from "../../../Generated/graphql";
import { Icon } from "../../Icon/Icon";
import { NumberInput } from "../../NumberInput/NumberInput";
import "./ScanItemInModalItem.css";

type Props = {
  item: OrderItem & {
    isError: boolean;
  };
  change: (productNumber: string, amount: number | string) => void;
  removeItem: (productNumber: string) => void;
};

export const ScanItemInModalItem: FC<Props> = ({
  item,
  change,
  removeItem,
}) => {
  const { data, error, loading } = useProductQuery({
    variables: {
      productNumber: item.productNumber,
    },
  });

  if (error) return <div>Error...</div>;
  if (loading) return <div />;
  const itemStyles = ["ScanItemInModalItem"];
  if (item.isError) {
    itemStyles.push("ScanItemInModalItem__isError");
  }

  const unitAsText = unitMap.find(
    (x) => x.key === data?.product.productData.unit
  );

  return (
    <div className={itemStyles.join(" ")}>
      <div className="ScanItemInModalItem__itemDetails">
        <div
          className="ScanItemInModalItem__name"
          style={{ color: item.isError ? "#AA2525" : "#0C2C52" }}
        >
          {get(data, "product.productData.name", " ")}
        </div>
        <div
          className="ScanItemInModalItem__id"
          style={{ color: item.isError ? "#AA2525" : "#6D7A85" }}
        >
          {item.productNumber} -{" "}
          {get(data, "product.productData.description", " ")}
        </div>
      </div>
      <div className="ScanItemInModalItem__adjustments">
        {item.isError ? (
          <div className="ScanItemInModalItem__notInOrder">
            <div className="ScanItemInModalItem__notInOrder-icon">
              <Icon name="notification" width={16} />
            </div>
            <div className="ScanItemInModalItem__notInOrder-text">
              Ikke en del af ordre
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="ScanItemInModalItem__amountOrdered">
              <div className="ScanItemInModalItem__amountOrdered-amount">
                {item.amount}
              </div>
              <div className="ScanItemInModalItem__amountOrdered-unit">
                {unitAsText?.value}
              </div>
            </div>
            <div className="ScanItemInModalItem__amountReceived">
              <NumberInput
                withButtons={false}
                value={item.taken}
                change={(e) => {
                  change(item.productNumber, e.target.value);
                }}
              />
            </div>
          </Fragment>
        )}
        <div
          className="ScanItemInModalItem__deleteMe"
          onClick={() => removeItem(item.productNumber)}
        >
          <Icon name="trashcanTertiaryBlue" width={9} />
        </div>
      </div>
    </div>
  );
};
