import moment from "moment";
import { FC, useState } from "react";
import {
  InventoryItemHistoryDocument,
  InventoryItemHistoryEntry,
  useRegretInventoryItemHistoryEntryMutation,
} from "../../../Generated/graphql";
import { Icon } from "../../Icon/Icon";
import { UndoStockChangeModal } from "../UndoStockChangeModal/UndoStockChangeModal";
import "./ItemHistoryEntry.css";

type Props = {
  entry: InventoryItemHistoryEntry;
  unit: string;
  canEdit: boolean;
};

export const ItemHistoryEntry: FC<Props> = ({ entry, unit, canEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [changed, setChanged] = useState(entry.eventReverted);

  const [regretEvent] = useRegretInventoryItemHistoryEntryMutation({
    update: (cache, { data }) => {
      setChanged(true);
      const prevData = cache.readQuery<{
        inventoryItemHistory: InventoryItemHistoryEntry[];
      }>({
        query: InventoryItemHistoryDocument,
        variables: { productNumber: entry.productNumber },
      });

      const regretInventoryItemHistoryEntry =
        data?.regretInventoryItemHistoryEntry;

      if (!regretInventoryItemHistoryEntry) {
        return;
      }

      const newData = {
        ...prevData,
        inventoryItemHistory: prevData?.inventoryItemHistory.map((event) => {
          if (event.id === regretInventoryItemHistoryEntry.id) {
            return regretInventoryItemHistoryEntry;
          } else {
            return event;
          }
        }),
      };

      cache.writeQuery({
        query: InventoryItemHistoryDocument,
        data: newData,
        variables: { prodNo: entry.productNumber },
      });
    },
  });

  const formattedDate = moment(entry.eventTime).format("DD.MM.YYYY HH.mm");
  const formattedEventLocation =
    entry.eventAction === "manual"
      ? "Manuel redigering"
      : entry.eventAction === "scan"
      ? "Scan"
      : entry.eventAction === "receive"
      ? `Modtagelse${
          entry.fromOrderNumber ? ` - Ordre ${entry.fromOrderNumber}` : ""
        }`
      : "Ukendt";

  const handleResponse = async (response: boolean) => {
    if (response) {
      setShowModal(false);
      await regretEvent({
        variables: {
          regretInventoryItemHistoryEntryId: entry.id,
        },
      });
    } else {
      setShowModal(false);
    }
  };

  return (
    <div className="ItemHistoryEntry">
      <UndoStockChangeModal open={showModal} respond={handleResponse} />
      <div className="ItemHistoryEntry__information">
        <div
          className="ItemHistoryEntry__information-date"
          style={
            changed
              ? {
                  textDecoration: "line-through",
                  color: "#0C2C52",
                  opacity: 0.3,
                }
              : undefined
          }
        >
          {formattedDate}
        </div>
        <div
          className="ItemHistoryEntry__information-location"
          style={
            changed
              ? {
                  textDecoration: "line-through",
                  color: "#0C2C52",
                  opacity: 0.3,
                }
              : undefined
          }
        >
          {formattedEventLocation}
        </div>
        {changed ? (
          <div className="ItemHistoryEntry__information-revertTime">
            <div className="ItemHistoryEntry__information-revertTime-icon">
              <Icon name="undoIcon" width={13} />
            </div>
            <div className="ItemHistoryEntry__information-revertTime-text">
              {moment(entry.revertTime).format("DD.MM.YYYY HH.mm")}
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="ItemHistoryEntry__amount"
        style={
          changed
            ? { backgroundColor: "#f4f4f4" }
            : entry.eventType === "add"
            ? { backgroundColor: "rgba(83,166,130,0.1)" }
            : { backgroundColor: "rgba(170,37,37,0.1)" }
        }
      >
        <div
          className="ItemHistoryEntry__amount-number"
          style={
            changed
              ? { textDecoration: "line-through", color: "#c7c7c7" }
              : entry.eventType === "add"
              ? { color: "#53A682" }
              : { color: "#AA2525" }
          }
        >
          {entry.eventType === "add"
            ? `+ ${entry.productIds.length} ${unit}`
            : `- ${entry.productIds.length} ${unit}`}
        </div>
      </div>
      {canEdit && (
        <div
          className={
            changed ||
            moment(new Date())
              .subtract(48, "hours")
              .isAfter(moment(entry.eventTime))
              ? "ItemHistoryEntry__undo ItemHistoryEntry__undo__hidden"
              : "ItemHistoryEntry__undo u-cursor-pointer"
          }
          onClick={() => {
            if (
              !changed &&
              moment(new Date())
                .subtract(48, "hours")
                .isBefore(moment(entry.eventTime))
            ) {
              setShowModal(true);
            }
          }}
        >
          <div className="ItemHistoryEntry__undo-button">
            <Icon name="undoIcon" width={19} />
          </div>
        </div>
      )}
    </div>
  );
};
