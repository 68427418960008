import React, { FC } from "react";
import { CartItem } from "../../Generated/graphql";
import "./OrderList.css";
import { OrderListItem } from "./OrderListItem/OrderListItem";

type Props = {
  orderItems: CartItem[];
  orderId: string;
  windowWidth: number;
};

export const OrderList: FC<Props> = ({ orderItems, orderId, windowWidth }) => {
  return (
    <div className="OrderList">
      {orderItems.map((item) => (
        <OrderListItem
          key={item.productNumber}
          windowWidth={windowWidth}
          orderItem={item}
          orderId={orderId}
        />
      ))}
    </div>
  );
};
