import { useState } from "react";

import moment, { Moment } from "moment";
import { capitalizeString } from "../../../../Utils/capitalizeString";
import { Icon } from "../../../Icon/Icon";
import { DashboardPopOutMenu } from "../../DashboardPopOutMenu/DashboardPopOutMenu";
import "./DashboardOrderCalendarMenu.css";

type Props = {
  selectedMonth: Moment;
  currentMonth: string;
  setSelectedMonth: (value: Moment) => void;
};

export const DashboardOrderCalendarMenu = ({
  selectedMonth,
  currentMonth,
  setSelectedMonth,
}: Props) => {
  const [displayState, setDisplayState] = useState(false);

  const formattedSelectedMonth = moment(selectedMonth).format("MMMM YYYY");

  const displaySelectedMonth =
    formattedSelectedMonth === currentMonth
      ? "Denne måned"
      : capitalizeString(formattedSelectedMonth);

  const generateLastSixMonths = () => {
    return [
      {
        name: capitalizeString(moment().format("MMMM YYYY")),
        value: moment(),
      },
      {
        name: capitalizeString(
          moment()
            .subtract(1, "month")
            .format("MMMM YYYY")
        ),
        value: moment().subtract(1, "month"),
      },
      {
        name: capitalizeString(
          moment()
            .subtract(2, "month")
            .format("MMMM YYYY")
        ),
        value: moment().subtract(2, "month"),
      },
      {
        name: capitalizeString(
          moment()
            .subtract(3, "month")
            .format("MMMM YYYY")
        ),
        value: moment().subtract(3, "month"),
      },
      {
        name: capitalizeString(
          moment()
            .subtract(4, "month")
            .format("MMMM YYYY")
        ),
        value: moment().subtract(4, "month"),
      },
      {
        name: capitalizeString(
          moment()
            .subtract(5, "month")
            .format("MMMM YYYY")
        ),
        value: moment().subtract(5, "month"),
      },
    ];
  };

  return (
    <>
      {" "}
      <div
        className="DashboardOrderCalendarMenu"
        onClick={() => setDisplayState(true)}
        style={{ pointerEvents: displayState ? "none" : "auto" }}
      >
        <div className="DashboardOrderCalendarMenu__month">
          {displaySelectedMonth}
        </div>
        <div className="DashboardOrderCalendarMenu__menu-point">
          <Icon name="iconChange" width={3} />
        </div>
      </div>
      {displayState && (
        <DashboardPopOutMenu
          options={generateLastSixMonths()}
          setDisplayState={() => setDisplayState(false)}
          setSelected={(e: { name: string; value: moment.Moment }) => {
            setDisplayState(false);
            setSelectedMonth(e.value);
          }}
        />
      )}
    </>
  );
};
