import { FC, PropsWithChildren } from "react";
import "./SettingsContainer.css";

type Props = {
  headerTexts: [string, string];
};

export const SettingsContainer: FC<PropsWithChildren<Props>> = ({
  children,
  headerTexts,
}) => {
  return (
    <div className="SettingsContainer">
      <div className="SettingsContainer__Header">
        {headerTexts.map((text) => (
          <h2 key={text} className="SettingsContainer__Header__Text">
            {text}
          </h2>
        ))}
      </div>
      {children}
    </div>
  );
};
