import React from 'react';

import './ItemOrdered.css';
import { Icon } from '../Icon/Icon';

export const ItemOrdered = ({ windowWidth }) => {
  return (
    <div className="ItemOrdered">
      <Icon name="orderTruckGreen" width={19} />
      {windowWidth > 1023 && <div className="ItemOrdered__text">Bestilt</div>}
    </div>
  );
};
