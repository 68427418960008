import React from "react";
import IconList from "./Icon-list";
import "./Icon.css";

interface IIconProps {
  name: string;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
  style?: string;
  opacity?: number;
  [x: string]: any;
}

export const Icon = ({
  name = "loading",
  width = undefined,
  height = undefined,
  alt = undefined,
  className = undefined,
  style = undefined,
  opacity = 100,
  ...props
}: IIconProps) => {
  const styles = Object.assign({}, style, {
    width: `${width}px`,
    height: `${height}px`,
    opacity: opacity,
  });

  return (
    <div
      className={`Icon ${className}`}
      style={styles}
      dangerouslySetInnerHTML={{ __html: IconList[name] }}
      {...props}
    ></div>
  );
};
