import React, { FC } from "react";
import { Cart, CartItem } from "../../Generated/graphql";
import { OrderContact } from "../OrderContact/OrderContact";
import { OrderList } from "../OrderList/OrderList";
import "./CurrentActiveOrder.css";

type Props = {
  currentOrder: Cart | null;
  triggerOrderNow: (items: CartItem[]) => void;
  windowWidth: number;
};

export const CurrentActiveOrder: FC<Props> = ({
  currentOrder,
  triggerOrderNow,
  windowWidth,
}) => {
  return (
    <div className="Orders__content">
      <div className="Orders__contact">
        {currentOrder && (
          <OrderContact
            orderNow={() => triggerOrderNow(currentOrder.orderLines)}
            orderLines={currentOrder.orderLines}
            orderId={currentOrder.id}
            hasComment={currentOrder.comment ? true : false}
          />
        )}
      </div>
      <div className="Orders__order-list">
        {currentOrder &&
        currentOrder.orderLines &&
        currentOrder.orderLines.length > 0 ? (
          <OrderList
            windowWidth={windowWidth}
            orderItems={currentOrder.orderLines}
            orderId={currentOrder.id}
          />
        ) : (
          <div className="Orders__order-list-empty">Ingen varer tilføjet</div>
        )}
      </div>
    </div>
  );
};
