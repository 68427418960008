import React from "react";
import { createPortal } from "react-dom";

import "./OrdersDateChangedBanner.css";
import { Icon } from "../Icon/Icon";

export const OrdersDateChangedBanner = ({ show }) => {
  const root = document.getElementById("order_date_changed");

  return show
    ? createPortal(
        <div className="OrdersDateChangedBanner">
          <div className="OrdersDateChangedBanner__title">
            <div className="OrdersDateChangedBanner__icon">
              <Icon name="notification" width={15} />
            </div>
            <div className="OrdersDateChangedBanner__title-text">
              Bemærk: Tidspunktet for din kommende bestilling er ændret på grund
              af helligdag
            </div>
          </div>
        </div>,
        root
      )
    : null;
};
