import { get } from "lodash";
import { FC, useState } from "react";
import { Redirect } from "react-router-dom";
import { BoxLoader } from "../../Components/BoxLoader/BoxLoader";
import { DashboardInventoryValue } from "../../Components/Dashboard/DashboardInventoryValue/DashboardInventoryValue";
import { DashboardMaterialBuys } from "../../Components/Dashboard/DashboardMaterialBuys/DashboardMaterialBuys";
import { DashboardOrderCalendar } from "../../Components/Dashboard/DashboardOrderCalendar/DashboardOrderCalendar";
import { DashboardProductWatch } from "../../Components/Dashboard/DashboardProductWatch/DashboardProductWatch";
import { DashboardTotals } from "../../Components/Dashboard/DashboardTotals/DashboardTotals";
import { useAccountSettingsQuery, useUserQuery } from "../../Generated/graphql";
import "./Dashboard.css";

export const Dashboard = () => {
  const [shadowed, setShadow] = useState(false);

  const { data, error, loading } = useUserQuery();
  const {
    data: accountData,
    error: accountError,
    loading: accountLoading,
  } = useAccountSettingsQuery();

  if (loading || error || accountLoading || accountError) {
    return <BoxLoader />;
  }

  // Using the any type here for now - It's wrong and should be fixed
  const handleScrollEvent = (event: any) => {
    if (shadowed && event.target.scrollTop > 1) {
      return;
    }

    if (!shadowed && event.target.scrollTop === 0) {
      return;
    }

    if (!shadowed && event.target.scrollTop > 1) {
      setShadow(true);
    }

    if (shadowed && event.target.scrollTop < 1) {
      setShadow(false);
    }
  };

  if (!["SUPERADMIN", "DISTRIBUTOR", "ADMIN"].includes(data?.user.role ?? "")) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  } else {
    const accountancyYear = get(
      accountData,
      "accountSettings.accountancy_year",
      null
    );
    return (
      <>
        <DashboardTitle title="Overblik" />
        <TopDividerBar shadow={shadowed} />
        <div className="Dashboard" onScroll={handleScrollEvent}>
          <div className="Dashboard__inventory-value">
            <DashboardInventoryValue
              accountancyYear={accountancyYear === 1 ? null : accountancyYear}
            />
          </div>
          <div className="Dashboard__stale-items">
            <DashboardProductWatch />
          </div>
          <div className="Dashboard__buys">
            <DashboardMaterialBuys
              accountancyYear={accountancyYear === 1 ? null : accountancyYear}
            />
          </div>
          <div className="Dashboard__totals">
            <DashboardTotals
              accountancyYear={accountancyYear === 1 ? null : accountancyYear}
            />
          </div>
          <div className="Dashboard__calendar">
            <DashboardOrderCalendar />
          </div>
        </div>
      </>
    );
  }
};

const DashboardTitle: FC<{ title: string }> = ({ title }) => (
  <div className="DashboardTitle">
    <div className="DashboardTitle__text">{title}</div>
  </div>
);

const TopDividerBar: FC<{ shadow: boolean }> = ({ shadow }) => (
  <div
    className="TopDividerBar"
    style={{
      boxShadow: shadow ? "rgba(0, 0, 0, 0.25) 0px 7px 7px 0px" : undefined,
      transform: shadow ? "translateX(-38px)" : undefined,
    }}
  />
);

export const DASHBOARD_COLORS = [
  "#4092D7",
  "#EAC75A",
  "#53A682",
  "#0C2C52",
  "#A1B4C4",
];
