export const makeSSORequest = <T>(
  query: string,
  variables: object = {}
): Promise<T> => {
  return fetch(`${process.env.REACT_APP_SSO_BASE_URL}/api/graphql`, {
    method: "POST",
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(({ data }) => data);
};

export type AppDetails = {
  id: string;
  name: string;
  supportAccess: boolean;
};

export const getAppDetails = ({
  accountId,
}: {
  accountId: string;
}): Promise<{ app: AppDetails }> => {
  const query = `
  query app(
    $accountId: String
  ) {
    app(
      appId: $accountId,
    ) {
        id,
        name,
        supportAccess
    }
  }
`;

  return makeSSORequest<{ app: AppDetails }>(query, { accountId });
};

export const setSupportAccess = ({
  accountId,
  access,
}: {
  accountId: string;
  access: boolean;
}) => {
  const query = `
  mutation updateSupportStatus(
    $accountId: String,
    $access: Boolean
  ) {
    updateSupportStatus(
      appId: $accountId,
      canSupport: $access
    ) {
        id,
        supportAccess
    }
  }
`;

  type SSOApp = {
    id: string;
    supportAccess: boolean;
    name?: string;
  };

  return makeSSORequest<{ updateSupportStatus: SSOApp }>(query, {
    accountId,
    access,
  });
};
