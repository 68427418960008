import { useAccountSettingsQuery } from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import "./SettingsAccess.css";
import { SettingsAccessContent } from "./SettingsAccessContent/SettingsAccessContent";

export const SettingsAccess = () => {
  const { data, loading, error } = useAccountSettingsQuery();
  if (loading) return <BoxLoader />;
  if (error) return <div>{error.message}</div>;
  return (
    <div className="SettingsAccess">
      <div className="SettingsAccess__title">
        <div className="SettingsAccess__title-text">Kontoindstillinger</div>
      </div>
      <div className="Settings__dividerbar" />
      <div className="SettingsAccess__content">
        {data && <SettingsAccessContent settings={data.accountSettings} />}
      </div>
    </div>
  );
};
