import moment from "moment";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, withRouter } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import {
  AccountsDocument,
  AccountSettings,
  useCreateAccountMutation,
} from "../../Generated/graphql";
import { Button } from "../Button/Button";
import { Checkbox } from "../Checkbox/Checkbox";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../ListItem/ListItem";
import { DateCalendar } from "../SuperAdminNewHoliday/SuperAdminNewHoliday";
import { TextInput } from "../TextInput/TextInput";
import "./SuperAdminNewAccount.css";

const SuperAdminNewAccount = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [custno, setCustno] = useState("");
  const [cvr, setCvr] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [showDatepicker, setShowDatepicker] = useState(false);

  const [hasLechler, setHasLecher] = useState(false);
  const [hasSpiesHecker, setHasSpiesHecker] = useState(false);
  const [hasStandox, setHasStandox] = useState(false);
  const [hasPercotop, setHasPercotop] = useState(false);

  const [createNewAccount] = useCreateAccountMutation({
    update: (cache, { data }) => {
      const accounts = cache.readQuery<{ accounts: AccountSettings[] }>({
        query: AccountsDocument,
      });

      if (!accounts) {
        return;
      }

      if (!data?.createAccount) {
        return;
      }

      cache.writeQuery({
        query: AccountsDocument,
        data: {
          accounts: accounts.accounts.concat(data.createAccount),
        },
      });
    },
  });

  const handleSetDate = (input: Date | Date[]) => {
    setShowDatepicker(!showDatepicker);
    if (Array.isArray(input)) {
      setStartDate(input[0]);
      return;
    }
    setStartDate(input);
  };

  return (
    <div className="SuperAdminNewAccount">
      <div
        className="EditItem__close"
        onClick={() => history.push("superadmin/accounts")}
      >
        <div className="EditItem__closeborder">
          <Icon name="closeBlue" width={20} height={20} />
        </div>
      </div>
      <div className="SuperAdminNewAccount__header">
        <div className="SuperAdminNewAccount__title">
          <div className="SuperAdminNewAccount__title-text">Opret konto</div>
        </div>
      </div>

      <div className="SuperAdminNewAccount__content">
        <div
          className="SuperAdminNewAccount__item"
          style={{ borderTop: "1px solid #a1b4c4" }}
        >
          <ListItem title="Firma">
            <TextInput
              name="name"
              textAlign="right"
              value={name}
              change={(e: any) => setName(e.target.value)}
            />
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Visma Kunde nr.">
            <TextInput
              name="customerNumber"
              textAlign="right"
              value={custno}
              change={(e: any) => setCustno(e.target.value)}
            />
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="CVR">
            <TextInput
              name="cvr"
              textAlign="right"
              value={cvr}
              change={(e: any) => setCvr(e.target.value)}
            />
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Dato">
            <Tooltip
              className="SuperAdminNewHoliday__value"
              useHover={false}
              isOpen={showDatepicker}
              content={
                <DateCalendar date={startDate} setDate={handleSetDate} />
              }
              arrow={true}
            >
              <div className="SuperAdminNewHoliday__value">
                <TextInput
                  name="startDate"
                  textAlign="right"
                  value={moment(startDate).format("DD/MM/YYYY")}
                  onClick={() => setShowDatepicker(!showDatepicker)}
                  change={() => {}}
                />
              </div>
            </Tooltip>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__seperator"></div>
        <div className="SuperAdminNewAccount__item">
          <div className="SuperAdminNewAccount__item--title">
            Produkt systemer
          </div>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Lechler">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasLecher} checked={hasLechler} />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Spies Hecker">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasSpiesHecker} checked={hasSpiesHecker} />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Standox">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasStandox} checked={hasStandox} />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Percotop">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasPercotop} checked={hasPercotop} />
            </div>
          </ListItem>
        </div>
      </div>

      <div className="SuperAdminNewAccount__footer">
        <div className="SuperAdminNewAccount__button">
          <Button
            buttonStyle="newItem"
            width="107px"
            click={() => {
              createNewAccount({
                variables: {
                  args: {
                    id: custno,
                    name,
                    cvr,
                    hasLechler,
                    hasPercotop,
                    hasSpiesHecker,
                    hasStandox,
                    startDate: moment(startDate).format(),
                  },
                },
              }).then(() => {
                history.goBack();
              });
            }}
          >
            <div>Opret</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const routed = withRouter(SuperAdminNewAccount);

export { routed as SuperAdminNewAccount };
