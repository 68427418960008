import classNames from "classnames";
import { FC } from "react";
import { InventoryItem, Product } from "../../../Generated/graphql";
import { getImageWithSize } from "../../../Utils/getImageWithSize";
import { Icon } from "../../Icon/Icon";
import "./ItemSearchBarResults.css";

type Props = {
  items: Product[];
  addItem: (arg: Product) => void;
  stockItems: InventoryItem[];
  errors: any;
  exists: any;
};

export const ItemSearchBarResults: FC<Props> = ({
  items,
  addItem,
  stockItems = [],
  errors,
  exists
}) => {
  return (
    <div className="ItemSearchBarResults">
      {items.map((item) => (
        <ItemSearchBarResult
          item={item}
          addItem={addItem}
          key={item.prodNo}
          stockItems={stockItems}
        />
      ))}
      {errors && (
        <div className="ItemSearchBarResults__no-items">
          <div className="ItemSearchBarResults__no-items-text">
            Der skete en fejl under søgningen
          </div>
        </div>
      )}
      {!errors && !exists && items.length === 0 && (
        <div className="ItemSearchBarResults__no-items">
          <div className="ItemSearchBarResults__no-items-text">Intet resultat</div>
        </div>
      )}
      {!errors && exists && (
        <div className="ItemSearchBarResults__no-items">
        <div className="ItemSearchBarResults__no-items-text">Allerede oprettet</div>
      </div>
      )}
    </div>
  );
};

type ItemSearchBarResultProps = {
  item: Product;
  addItem: (item: Product) => void;
  stockItems: InventoryItem[];
};

const ItemSearchBarResult: FC<ItemSearchBarResultProps> = ({
  item,
  addItem,
  stockItems,
}) => {
  const isInStock = stockItems
    ? stockItems.find((x) => x.productNumber === item.prodNo)
    : null;

  const handleAddItem = () => {
    if (isInStock) {
      return;
    }

    addItem(item);
  };

  const productImage: string | undefined = item.customData?.billeder?.[0]?.url;

  return (
    <div
      className="ItemSearchBarResult u-cursor-pointer"
      title={item.productData.name}
      onClick={handleAddItem}
    >
      <div
        className={classNames("ItemSearchBarResult__image", {
          "ItemSearchBarResult__image--added": isInStock,
        })}
      >
        {productImage ? (
          <img src={getImageWithSize(productImage, "100x100")} alt="Produkt" />
        ) : (
          <Icon name="noImageAvail" width={55} opacity={isInStock ? 0.5 : 1} />
        )}
      </div>
      <div
        className={classNames("ItemSearchBarResult__content", {
          "ItemSearchBarResult__content--grayed": isInStock,
        })}
      >
        <div className="ItemSearchBarResult__name">{item.productData.name}</div>
        <div className="ItemSearchBarResult__details">
          {item.prodNo} · {item.productData?.description}
        </div>
      </div>
      {isInStock && (
        <div className="ItemSearchBarResults__overlay ItemSearchBarResults__exists">
          <Icon name="checkmarkGreen" width={10} />
          <div className="ItemSearchBarResults__created">Oprettet</div>
        </div>
      )}
      {!isInStock && (
        <div className="ItemSearchBarResults__overlay">
          <div className="ItemSearchBarResults__pick-me">Vælg</div>
        </div>
      )}
    </div>
  );
};
