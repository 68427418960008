import { da } from "date-fns/locale";
import moment from "moment";
import { FC, forwardRef, useState } from "react";
// @ts-ignore
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import { useEffectOnce } from "usehooks-ts";
import { Zoom } from "../../../App";
import {
  AccountSettings,
  useUpdateAccountSettingsMutation,
} from "../../../Generated/graphql";

import { capitalizeString } from "../../../Utils/capitalizeString";
import { getAppDetails, setSupportAccess } from "../../../Utils/makeSSORequest";
import { noop } from "../../../Utils/noop";
import { Button } from "../../Button/Button";
import { Checkbox } from "../../Checkbox/Checkbox";
import { Icon } from "../../Icon/Icon";
import { ListItem } from "../../ListItem/ListItem";
import "./SettingsAccessContent.css";

registerLocale("da", da);

type Props = {
  settings: AccountSettings;
};

export const SettingsAccessContent: FC<Props> = ({ settings }) => {
  const [canEdit, setCanEdit] = useState(settings.usersCanEdit);
  const [canExport, setCanExport] = useState(settings.usersCanExport);
  const [accountancyYear, setAccountancyYear] = useState(
    settings.accountancyYear ?? 1
  );
  const [hasSupportAccess, setHasSupportAccess] = useState(false);

  useEffectOnce(() => {
    getAppDetails({ accountId: settings.id }).then((response) => {
      setHasSupportAccess(response.app.supportAccess);
    });
  });

  const handleSetSupportAccess = () => {
    setSupportAccess({
      accountId: settings.id,
      access: hasSupportAccess,
    }).catch(() => setHasSupportAccess(!hasSupportAccess));
  };

  const calculatedEndOfAccountancyYear = moment()
    .month(accountancyYear - 1)
    .date(1)
    .add(1, "year")
    .subtract(1, "day");

  const [updateSettings] = useUpdateAccountSettingsMutation({
    variables: {
      args: {
        usersCanEdit: canEdit === settings.usersCanEdit ? undefined : canEdit,
        usersCanExport:
          canExport === settings.usersCanExport ? undefined : canExport,
        accountancyYear:
          accountancyYear === settings.accountancyYear
            ? undefined
            : accountancyYear,
      },
    },
  });

  // Currently, the accountancy_year is base 1
  const handleDateChange = (event: Date) => {
    const month = event.getMonth();

    setAccountancyYear(month + 1);
  };

  return (
    <>
      <div className="SettingsAccessContent">
        <div className="SettingsAccess__item">
          <ListItem title="Tillad adgang for support">
            <div className="SettingsAccess__checkbox">
              <Checkbox
                checked={hasSupportAccess}
                change={() => setHasSupportAccess((prev) => !prev)}
              />
            </div>
          </ListItem>
        </div>
        <div className="SettingsAccess__item">
          <ListItem title="Brugere må redigere lager">
            <div className="SettingsAccess__checkbox">
              <Checkbox checked={canEdit} change={() => setCanEdit(!canEdit)} />
            </div>
          </ListItem>
        </div>
        <div className="SettingsAccess__item">
          <ListItem title="Brugere må exportere lager">
            <div className="SettingsAccess__checkbox">
              <Checkbox
                checked={canExport}
                change={() => setCanExport(!canExport)}
              />
            </div>
          </ListItem>
        </div>
        <div className="SettingsAccess__item SettingsAccess__inventory">
          <ListItem title="Regnskabsperiode">
            <div className="SettingsAccess__checkbox" style={{ zIndex: 25 }}>
              <ReactDatePicker
                renderCustomHeader={({
                  decreaseMonth,
                  increaseMonth,
                  date,
                }: any) => (
                  <CalendarHeader
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    date={date}
                  />
                )}
                locale="da"
                selected={moment()
                  .month(accountancyYear - 1)
                  .date(1)
                  .toDate()}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
                calendarClassName="SettingsAccess__calendar"
                formatWeekDay={(data: string) =>
                  capitalizeString(data).charAt(0)
                }
              />
              <ReactDatePicker
                locale="da"
                selected={calculatedEndOfAccountancyYear.toDate()}
                onChange={() => {}}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDisabledDateInput />}
                disabled
              />
            </div>
          </ListItem>
        </div>
      </div>
      <div className="SettingsAccess__actions">
        <div className="SettingsNewUser__confirm-button">
          <Button
            buttonStyle="newItem"
            labelStyle="createButton"
            click={() => {
              Promise.allSettled([
                updateSettings(),
                handleSetSupportAccess(),
              ]).then(() => {
                toast("Ændringer gemt", {
                  className: "u-toast-success",
                  progressClassName: "u-toast-success-bar",
                  transition: Zoom,
                });
              });
            }}
          >
            Gem ændringer
          </Button>
        </div>
      </div>
    </>
  );
};

const CustomDateInput = forwardRef<any, any>(
  ({ value, onClick = noop }, ref) => {
    // We need to provide a onChange function for the datepicker, otherwise we get an error. Using defaultValue does not update the inputfield on click
    const handleDateChange = (event: any) => {
      return event;
    };
    return (
      <input
        className="CustomDateInput"
        onChange={handleDateChange}
        value={value}
        onClick={onClick}
        ref={ref}
      ></input>
    );
  }
);

const CustomDisabledDateInput = forwardRef<any, any>(
  ({ value, onClick }, ref) => {
    return (
      <input
        className="CustomDisabledDateInput"
        readOnly
        value={value}
        onClick={onClick}
        ref={ref}
      ></input>
    );
  }
);

const CalendarHeader = ({ date, increaseMonth, decreaseMonth }: any) => {
  const monthDisplay = moment(date).format("MMMM");
  const yearDisplay = moment(date).format("YYYY");

  return (
    <div className="CalendarHeader">
      <div className="CalendarHeader__back-button">
        <CalendarPrevLabel click={decreaseMonth} />
      </div>
      <div className="CalendarHeader__month">
        {capitalizeString(monthDisplay) + " " + yearDisplay}
      </div>
      <div className="CalendarHeader__next-button">
        <CalendarNextLabel click={increaseMonth} />
      </div>
    </div>
  );
};

const CalendarNextLabel = ({ click }: any) => (
  <div className="CalendarNextLabel CalendarLabel" onClick={click}>
    <Icon name="arrowDown" width={14} />
  </div>
);

const CalendarPrevLabel = ({ click }: any) => (
  <div className="CalendarPrevLabel CalendarLabel" onClick={click}>
    <Icon name="arrowDown" width={14} />
  </div>
);
