import { ApolloConsumer } from "@apollo/client";
import { clamp } from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Zoom } from "../../App";
import {
  CartItem,
  Product,
  useAddItemsToCartMutation,
  useInventoryQuery,
} from "../../Generated/graphql";
import { Button } from "../Button/Button";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import { ItemSearchBar } from "../ItemSearchBar/ItemSearchBar";
import { OrderNewItem } from "../OrderNewItem/OrderNewItem";
import "./OrdersAddItem.css";

export const OrdersAddItem = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [addedItems, setAddedItems] = useState<CartItem[]>([]);
  const history = useHistory();
  const [addItemsToCart] = useAddItemsToCartMutation();
  const { data } = useInventoryQuery();

  const addOneFunction = (productNumber: string) => {
    const updateState = addedItems.map((item) => {
      if (item.productNumber === productNumber) {
        return {
          ...item,
          amount: item.amount + 1,
        };
      } else {
        return item;
      }
    });

    setAddedItems(updateState);
  };

  const minusOneFunction = (productNumber: string) => {    
    setAddedItems((currentAddedItems) =>
      currentAddedItems.map((item) => {
        if (item.productNumber === productNumber) {          
          return {
            ...item,
            amount: item.amount - 1 < 1 ? 1 : item.amount - 1,
          };
        } else {          
          return item;
        }
      })
    );
  };

  const onChangeHandler = (productNumber: string, value: any) => {
    setAddedItems((currentAddedItems) =>
      currentAddedItems.map((item) => {
        if (item.productNumber === productNumber) {
          return {
            ...item,
            amount: clamp(+value, 1, 1000),
          };
        } else {
          return item;
        }
      })
    );
  };

  const removeItem = (productNumber: string) => {
    const stateCopy = [...addedItems];
    setAddedItems(stateCopy.filter((x) => x.productNumber !== productNumber));
  };

  const addItem = (input: Product) => {
    if (!addedItems.find((x) => x.productNumber === input.prodNo)) {
      const newCartItem: CartItem = {
        amount: 1,
        manuallyAdded: true,
        name: input.productData.name,
        images:
          input.customData?.billeder?.map(
            (image: { url: string; name: string; sorting: number }) => ({
              url: image.url,
              name: image.name,
            })
          ) ?? [],
        productNumber: input.prodNo!,
        description: input.productData.description,
      };
      setAddedItems((items) => [newCartItem, ...items]);
    } else {
      // Update the item
      const updated = addedItems.map((item) => {
        if (item.productNumber === input.prodNo) {
          return {
            ...item,
            amount: item.amount + 1,
          };
        }
        return item;
      });

      setAddedItems(updated);
    }
  };

  const addItems = () => {
    if (isAdding) {
      return;
    }
    setIsAdding(true);
    addItemsToCart({
      variables: {
        items: addedItems,
      },
    })
      .then(() => {
        setIsAdding(false);
        toast("Varer tilføjet", {
          className: "u-toast-success",
          progressClassName: "u-toast-success-bar",
          transition: Zoom,
        });

        history.goBack();
      })
      .catch(() => {
        setIsAdding(false);
        toast("Fejl ved tilføjelse", {
          className: "u-toast-error",
          progressClassName: "u-toast-success-bar",
          transition: Zoom,
        });
      });
  };

  return (
    <div className="OrdersAddItem">
      <div className="AddNewItems__exit">
        <ExitButton click={() => history.goBack()} />
      </div>
      <div className="OrdersAddItem__title">Tilføj varer til bestilling</div>
      <div className="OrdersAddItem__search">
        <ApolloConsumer>
          {(client) => (
            <ItemSearchBar
              addItem={addItem}
              client={client}
              makingNewItem={false}
              stockItems={data?.inventory ?? []}
            />
          )}
        </ApolloConsumer>
      </div>
      {addedItems.length > 0 && (
        <div
          className="OrdersAddItem__added-items"
          style={{
            borderTop: "1px solid #D8D8D8",
          }}
        >
          {addedItems.map((item) => (
            <OrderNewItem
              key={item.productNumber}
              item={item}
              addOne={() => addOneFunction(item.productNumber)}
              minusOne={() => minusOneFunction(item.productNumber)}
              change={(value: any) =>
                onChangeHandler(item.productNumber, value.target.value)
              }
              removeItem={() => removeItem(item.productNumber)}
            />
          ))}
        </div>
      )}
      {addedItems.length > 0 && (
        <div className="NewItem__footer" style={{ right: 0 }}>
          <div className="NewItem__footer-changesSaved">
            <Button buttonStyle="newItem" width="207px" click={addItems}>
              <Icon name="add" width={12} />
              <div>Tilføj varer til bestilling</div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
