import React, { useEffect, useRef } from "react";

import "./DashboardPopOutMenu.css";

export const DashboardPopOutMenu = ({
  options,
  setDisplayState,
  setSelected
}) => {
  const mainEl = useRef(null);
  const outsideClickHandler = e => {
    if (e.srcElement.parentElement !== mainEl.current) {
      setDisplayState();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", outsideClickHandler);

    return () => document.removeEventListener("mousedown", outsideClickHandler);
  });

  return (
    <div className="DashboardPopOutMenu" ref={mainEl}>
      {options.map(option => (
        <DashboardPopOutMenuItem
          key={option.name}
          option={option}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
};

const DashboardPopOutMenuItem = ({ option, setSelected }) => {
  return (
    <div
      className="DashboardPopOutMenuItem"
      onClick={() => setSelected(option)}
    >
      <div className="DashboardPopOutMenuItem__name">{option.name}</div>
    </div>
  );
};
