import { ChangeEvent, FC } from "react";
import { Icon } from "../Icon/Icon";
import "./SearchBar.css";

type Props = {
  searchTerm: string;
  setSearchTerm: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchTerm: () => void;
};

export const SearchBar: FC<Props> = ({
  searchTerm,
  setSearchTerm,
  clearSearchTerm,
}) => {
  return (
    <div className="SearchBar">
      <input
        className="SearchBar__input"
        type="text"
        placeholder="Søg..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e);
        }}
      />
      <div
        className={
          searchTerm.length > 0
            ? "SearchBar__icon SearchBar__icon-clickable"
            : "SearchBar__icon"
        }
        onClick={() => {
          if (searchTerm.length > 0) {
            clearSearchTerm();
          }
        }}
      >
        <Icon
          name={searchTerm.length > 0 ? "clearIcon" : "search"}
          width={30}
        />
      </div>
    </div>
  );
};
