import { createContext, FC, PropsWithChildren, useState } from "react";

export const SearchContext = createContext<{
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}>({
  searchTerm: "",
  setSearchTerm(value) {},
});

export const SearchContextProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const contextValue = { searchTerm, setSearchTerm };
  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
// export const SearchContextConsumer = ({ children }) => {
//   return <SearchContext.Consumer>{children}</SearchContext.Consumer>;
// };
