import moment from "moment";
import { FC, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { DASHBOARD_COLORS } from "../../../Containers/Dashboard/Dashboard";
import { useMaterialValuesQuery } from "../../../Generated/graphql";
import { BoxLoader } from "../../BoxLoader/BoxLoader";
import { DashboardFiveItemList } from "../DashboardFiveItemList/DashboardFiveItemList";
import { DashboardNotEnoughDataToCalculate } from "../DashboardNotEnoughDataToCalculate/DashboardNotEnoughDataToCalculate";
import { DashboardPeriodSelector } from "../DashboardPeriodSelector/DashboardPeriodSelector";
import "./DashboardMaterialBuys.css";

type Props = {
  accountancyYear: number;
};

export const DashboardMaterialBuys: FC<Props> = ({ accountancyYear }) => {
  const [selectedPeriod, setSelectedPeriod] = useState({
    name: "Seneste 30 dage",
    value: "30days",
  });
  const { data, error, loading } = useMaterialValuesQuery({
    variables: { period: selectedPeriod.value, accountancyYear },
    fetchPolicy: "network-only",
  });

  return (
    <div className="DashboardMaterialBuys">
      <div className="DashboardMaterialBuys__selector">
        <DashboardPeriodSelector
          setSelectedMonth={setSelectedPeriod}
          selectedMonth={selectedPeriod}
          hasAccountancyYear={!accountancyYear ? false : true}
          monthOptions={
            accountancyYear
              ? [
                  ...monthOptions,
                  {
                    name: "Regnskabsår " + moment().format("YYYY"),
                    value: "accountancyYear",
                  },
                  {
                    name:
                      "Regnskabsår " +
                      moment()
                        .subtract(1, "year")
                        .format("YYYY"),
                    value: "lastAccountancyYear",
                  },
                  {
                    name:
                      "Regnskabsår " +
                      moment()
                        .subtract(2, "year")
                        .format("YYYY"),
                    value: "lastlastAccountancyYear",
                  },
                ]
              : monthOptions
          }
        />
      </div>
      {loading && <BoxLoader />}
      {error && <DashboardNotEnoughDataToCalculate type="error" />}
      {data?.materialValues && (
        <>
          <div className="DashboardMaterialBuys__title">
            <div className="DashboardMaterialBuys__title-text">
              Materialekøb
            </div>
          </div>
          {data.materialValues.length > 1 && (
            <div className="DashboardMaterialBuys__data">
              <div className="DashboardMaterialBuys__ring-chart">
                <RingChart data={data.materialValues} />
              </div>
              <div className="DashboardMaterialBuys__list">
                <DashboardFiveItemList items={data.materialValues} />
              </div>
            </div>
          )}
          {data.materialValues.length <= 1 && (
            <DashboardNotEnoughDataToCalculate />
          )}
        </>
      )}
    </div>
  );
};

type RingChartProps = {
  data: any[];
};

const RingChart: FC<RingChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="80%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          cy="50%"
          innerRadius="70%"
          outerRadius="90%"
        >
          {data.map((_: any, index: number) => (
            <Cell
              key={DASHBOARD_COLORS[index]}
              fill={DASHBOARD_COLORS[index]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export const monthOptions = [
  { name: "Seneste 30 dage", value: "30days" },
  { name: "Seneste 12 måneder", value: "12months" },
  { name: "I år", value: "year" },
  { name: "Sidste år", value: "lastyear" },
];
