import React, { useState } from "react";
import moment from "moment";

import "./HistoryEntry.css";
import { Icon } from "../../Icon/Icon";
import { OrderDisplay } from "../OrderDisplay/OrderDisplay";

export const HistoryEntry = ({ item }) => {
  const [showOrder, setShowOrder] = useState(false);
  const formattedTime = moment(item.createdAt).format("DD[.] MMM YYYY, hh:mm");

  return (
    <div className="HistoryEntry">
      {showOrder && (
        <OrderDisplay
          order={item.extraData.order}
          show={showOrder}
          set={() => setShowOrder(false)}
        />
      )}
      <div className="HistoryEntry__time">
        <div className="HistoryEntry__time-text">{formattedTime}</div>
      </div>
      <div className="HistoryEntry__operation-group">
        <div
          className="HistoryEntry__operation-group-dot"
          style={
            item.operationGroup === "positive"
              ? { backgroundColor: "#53a682" }
              : item.operationGroup === "neutral"
              ? { backgroundColor: "#d69d23" }
              : { backgroundColor: "#AA2525" }
          }
        ></div>
      </div>
      <div className="HistoryEntry__action">
        {item.operationType !== "item-edited" && (
          <div className="HistoryEntry__action-text">{item.message}</div>
        )}
        {item.operationType === "item-edited" && (
          <SettingsItemEdited item={item} />
        )}
      </div>
      {(item.operationType === "automatic-order" ||
        item.operationType === "manual-order" ||
        item.operationType === "accepted-warning") && (
        <div className="HistoryEntry__details">
          <div
            className="HistoryEntry__details-clicky-boi"
            onClick={() => setShowOrder(!showOrder)}
          >
            <Icon name="cartListview" width={19} />
            <div className="HistoryEntry__details-clicky-boi--text">
              Se bestilling
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SettingsItemEdited = ({ item }) => {
  const minDiff = item.extraData.newMin - item.extraData.oldMin;
  const maxDiff = item.extraData.newMax - item.extraData.oldMax;

  return (
    <div className="SettingsItemEdited">
      <div className="SettingsItemEdited__message">{item.message},</div>
      {item.extraData.changedMin && (
        <div className="SettingsItemEdited__minimum">
          <div className="SettingsItemEdited__minimum--static">
            Minimumsbeholdning ændret til
          </div>
          <div className="SettingsItemEdited__minimum--changedto">
            {item.extraData.newMin}
          </div>
          <div
            className="SettingsItemEdited__minimum--changedamount"
            style={
              minDiff > 0
                ? { backgroundColor: "#53A682" }
                : { backgroundColor: "#AA2525" }
            }
          >
            <div className="SettingsItemEdited__minimum--changedamount--text">
              {formatPostiveAndNegativeNumbers(minDiff)}
            </div>
          </div>
        </div>
      )}
      {item.extraData.changedMax && (
        <div className="SettingsItemEdited__maximum">
          <div className="SettingsItemEdited__maximum--static">
            {item.extraData.changedMin
              ? "og maksimumbeholdning ændret til"
              : "Maksimumbeholdning ændret til"}
          </div>
          <div className="SettingsItemEdited__maximum--changedto">
            {item.extraData.newMax}
          </div>
          <div
            className="SettingsItemEdited__maximum--changedamount"
            style={
              maxDiff > 0
                ? { backgroundColor: "#53A682" }
                : { backgroundColor: "#AA2525" }
            }
          >
            <div className="SettingsItemEdited__maximum--changedamount--text">
              {formatPostiveAndNegativeNumbers(maxDiff)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const formatPostiveAndNegativeNumbers = number => {
  if (number > 0) {
    return `+${number}`;
  } else {
    return number.toString();
  }
};
