import { get, orderBy } from "lodash";
import moment from "moment";
import { forwardRef } from "react";
import { InventoryItem } from "../../Generated/graphql";

import { formatLargeNumber } from "../../Utils/formatLargeNumber";
import { groupItemsByCategory } from "../../Utils/groupItemsByCategory";
import "./ExportComponent.css";

type Props = {
  stockItems: InventoryItem[];
  date: Date;
  accountName: string;
  ref: any;
};

export const PrintableInventory = forwardRef<any, Props>(
  ({ stockItems, date, accountName }, ref) => {
    const totalInventoryValue = stockItems.find(
      (x) => x.productNumber === "Total værdi"
    );

    const productItems = stockItems.filter(
      (item) => item.productNumber !== "Total værdi"
    );

    const sortedItems = orderBy(productItems, (x) => x.category);

    const groupedItems = groupItemsByCategory(sortedItems);

    return (
      <div style={{ margin: "1cm" }} ref={ref}>
        <div
          className="PrintableInventory__header"
          style={{
            display: "flex",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <div
            className="PrintableInventory__header-text"
            style={{
              fontSize: "2rem",
              fontWeight: "700",
            }}
          >
            {`Lagerbeholdning - ${moment(date).format("lll")}`}
          </div>
          <div
            className="PrintableInventory__header-name"
            style={{
              fontSize: "2rem",
              fontWeight: "700",
              display: "inline-block",
              alignSelf: "flex-end",
              marginLeft: "auto",
            }}
          >
            {accountName}
          </div>
        </div>
        <div
          className="PrintableInventory__column"
          style={{
            position: "relative",
            display: "grid",
            gridTemplateColumns: "88px 2fr 1fr 1fr 1fr 10% 10%",
            padding: "5px 10px",
            borderBottom: "1px solid #000",
            breakInside: "avoid",
          }}
        >
          <div
            className="PrintableInventory__column-name"
            style={{ alignSelf: "center" }}
          >
            Produkt nr.
          </div>
          <div
            className="PrintableInventory__column-name"
            style={{ alignSelf: "center" }}
          >
            Navn
          </div>
          <div
            className="PrintableInventory__column-name"
            style={{ alignSelf: "center" }}
          >
            Beskrivelse
          </div>
          <div
            className="PrintableInventory__column-name"
            style={{ alignSelf: "center" }}
          >
            Kategori
          </div>
          <div
            className="PrintableInventory__column-name PrintableInventory__column-value-right"
            style={{
              alignSelf: "center",
              justifySelf: "flex-end",
              marginLeft: "auto",
            }}
          >
            Beholdning
          </div>
          <div
            className="PrintableInventory__column-name PrintableInventory__column-value-right"
            style={{
              alignSelf: "center",
              justifySelf: "flex-end",
              marginLeft: "auto",
            }}
          >
            Værdi
          </div>
          <div
            className="PrintableInventory__column-name PrintableInventory__column-value-right"
            style={{
              alignSelf: "center",
              justifySelf: "flex-end",
              marginLeft: "auto",
            }}
          >
            Værdi pr. stk.
          </div>
        </div>
        {groupedItems &&
          groupedItems.length > 0 &&
          groupedItems.map((grouped) => {
            return (
              <>
                <div
                  key={grouped.category}
                  className="PrintableInventory__row"
                  style={{
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    padding: "10px",
                    borderBottom: "1px solid #000",
                    breakInside: "avoid",
                    fontWeight: 700,
                  }}
                >
                  {grouped.category}
                </div>
                {grouped.items.map((item) => {
                  if (item.productNumber === "Total værdi") {
                    return null;
                  }
                  const unitAsText = item.amountPerUnit > 1 ? "ks." : "stk.";
                  return (
                    <div
                      key={item.productNumber}
                      className="PrintableInventory__row"
                      style={{
                        position: "relative",
                        display: "grid",
                        gridTemplateColumns: "88px 2fr 1fr 1fr 1fr 10% 10%",
                        padding: "10px",
                        borderBottom: "1px solid #000",
                        breakInside: "avoid",
                      }}
                    >
                      <div className="PrintableInventory__column-value">
                        {item.productNumber}
                      </div>
                      <div className="PrintableInventory__column-value">
                        {get(item, "productData.name", " ")}
                      </div>
                      <div className="PrintableInventory__column-value">
                        {get(item, "productData.description", " ")}
                      </div>
                      <div className="PrintableInventory__column-value">
                        {item.category}
                      </div>
                      <div
                        className="PrintableInventory__column-value PrintableInventory__column-value-right"
                        style={{
                          alignSelf: "center",
                          justifySelf: "flex-end",
                          marginLeft: "auto",
                        }}
                      >
                        {item.amount === 0
                          ? "0 " + unitAsText
                          : item.amount + " " + unitAsText}
                      </div>
                      <div
                        className="PrintableInventory__column-value PrintableInventory__column-value-right"
                        style={{
                          alignSelf: "center",
                          justifySelf: "flex-end",
                          marginLeft: "auto",
                        }}
                      >
                        {!item ||
                        !item.price ||
                        item.price === 0 ||
                        isNaN(item.price)
                          ? "0,00"
                          : item.price.toFixed(2)}
                      </div>
                      <div
                        className="PrintableInventory__column-value PrintableInventory__column-value-right"
                        style={{
                          alignSelf: "center",
                          justifySelf: "flex-end",
                          marginLeft: "auto",
                        }}
                      >
                        {!item ||
                        !item.price ||
                        item.price === 0 ||
                        isNaN(item.price)
                          ? "0,00"
                          : (
                              parseFloat(item.price.toFixed(2)) / item.amount
                            ).toFixed(2)}
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}
        <div
          style={{
            margin: "20px",
            float: "right",
            fontSize: "1.3rem",
            fontWeight: "700",
          }}
        >
          {`Total værdi: ${formatLargeNumber(
            totalInventoryValue?.price?.toFixed(2)
          )}`}
        </div>
      </div>
    );
  }
);
