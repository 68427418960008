import { FC, useEffect, useState } from "react";
import { Icon } from "../Icon/Icon";
import "./OrdersToday.css";

type Props = {
  amountOrderedToday: number;
  orderError: boolean;
};

export const OrdersToday: FC<Props> = ({ orderError, amountOrderedToday }) => {
  const [pendingOrders, setPendingOrders] = useState(orderError);

  useEffect(() => {
    setPendingOrders(orderError);
  }, [orderError]);

  return amountOrderedToday > 0 || pendingOrders ? (
    <div className="OrdersToday">
      <div className="OrdersToday__icon">
        <Icon
          name={pendingOrders ? "ovalLoader" : "checkmarkGreen"}
          width={13}
        />
      </div>
      <div className="OrdersToday__text">
        {pendingOrders
          ? "Bestiller varer..."
          : amountOrderedToday > 1
          ? amountOrderedToday + " bestillinger afsendt i dag"
          : amountOrderedToday + " bestilling afsendt i dag"}
      </div>
    </div>
  ) : null;
};
