import { FC } from "react";
import { Icon } from "../Icon/Icon";
import "./Profile.css";

type Props = {
  username: string;
  droppedDown: boolean;
};

export const Profile: FC<Props> = ({ username, droppedDown }) => {
  return (
    <div
      className="Profile u-cursor-pointer"
      style={
        droppedDown
          ? {
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              borderRadius: "4px",
              border: "1px solid #A1B4C4",
              transition: "all 100ms linear",
            }
          : {}
      }
    >
      <div className="Profile__icon">
        <Icon name="employee" width={16} />
      </div>

      <div className="Profile__text">
        <div className="Profile__username">{username}</div>
      </div>

      <div className="Profile__arrowIcon">
        <Icon name="arrowDown" width={9} />
      </div>
    </div>
  );
};
