import { FC } from "react";
// @ts-ignore
import Barcode from "react-barcode";
import PrintComponents from "react-print-components";
import { Icon } from "../Icon/Icon";
import "./PrintBarcode.css";

type Props = {
  barcode: string;
  name: string;
  prodNo: string;
  description: string;
  optionalText?: string;
  iconPosition?: "left" | "right";
};

export const PrintBarcode: FC<Props> = ({
  barcode,
  name,
  prodNo,
  optionalText = "",
  iconPosition = "right",
  description,
}) => {
  const pos = ` iconPosition-${iconPosition}`;
  return (
    <>
      <PrintComponents
        trigger={
          <div className="PrintBarcode">
            <div className={pos}>
              <div className="PrintBarcode__print-icon u-cursor-pointer">
                <Icon name="printItems" width={16} />
              </div>
              {optionalText && (
                <div className="PrintBarcode__print-optionaltext u-cursor-pointer">
                  {" "}
                  {optionalText}{" "}
                </div>
              )}
            </div>
          </div>
        }
      >
        <PrintableBarcode
          barcode={barcode}
          name={name}
          prodNo={prodNo}
          description={description}
        />
      </PrintComponents>
    </>
  );
};

type PrintableBarcodeProps = {
  barcode: string;
  name: string;
  prodNo: string;
  description: string;
};

export const PrintableBarcode: FC<PrintableBarcodeProps> = ({
  barcode,
  name,
  prodNo,
  description,
}) => {
  return (
    <div className="PrintableBarcode">
      <div className="PrintableBarcode__content">
        <div className="PrintableBarcode__subcontent">
          <div className="PrintableBarcode__name">{name}</div>
          <div className="PrintableBarcode__prodNo">
            {prodNo} · {description}
          </div>
        </div>
        <div className="PrintableBarcode__company">
          <Icon name="bdjLogoText" width={80} />
        </div>
      </div>

      <div className="PrintableBarcode__barcode">
        <Barcode
          value={barcode}
          width={1.3}
          height={38}
          fontSize={10}
          margin={0}
          marginBottom={3}
        />
      </div>
    </div>
  );
};
