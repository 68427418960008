export const shortNames = [
  {
    name: "Digitale services",
    short: "DS"
  },
  {
    name: "Loyalitetsprogrammer",
    short: "LP"
  },
  {
    name: "Værkstedsudstyr",
    short: "VU"
  }
];
