import { FC, useState } from "react";

import { OrderItem } from "../../../Generated/graphql";
import { ExitButton } from "../../ExitButton/ExitButton";
import "./ScanItemInModalAmountError.css";
import { ScanItemInModalAmountErrorItem } from "./ScanItemInModalAmountErrorItem/ScanItemInModalAmountErrorItem";

type Props = {
  handleErrorAmountCorrected: (answer: boolean) => void;
  toggleClose: () => void;
  onChangeAmountHandler: (prodNo: string, amount: number) => void;
  allScannedItems: (OrderItem & { isError: boolean })[];
  removeItem: (productNumber: string) => void;
};

export const ScanItemInModalAmountError: FC<Props> = ({
  handleErrorAmountCorrected,
  toggleClose,
  onChangeAmountHandler,
  allScannedItems,
  removeItem,
}) => {
  const [unequalAmountScanned, setUnequalAmountScanned] = useState<
    (OrderItem & { isError: boolean })[]
  >(allScannedItems.filter((x) => x.amount !== x.taken));

  const receiveButtonColoration =
    allScannedItems.filter((x) => x.amount !== x.taken).length === 0
      ? [
          "ScanItemInModalAmountError__receive-now",
          "ScanItemInModalAmountError__receive-now-noerrors",
        ]
      : [
          "ScanItemInModalAmountError__receive-now",
          "ScanItemInModalAmountError__receive-now-errors",
        ];

  return (
    <>
      <div className="ScanItemInModalAmountError__header">
        <div className="ScanItemInModalAmountError__exit">
          <ExitButton click={() => toggleClose()} />
        </div>
        <div className="ScanItemInModalAmountError__title">
          <div className="ScanItemInModalAmountError__title-text">
            Er der sket en fejl?
          </div>
          <div className="ScanItemInModalAmountError__subtitle">
            Det scannede antal stemmer ikke overens med bestillingen
          </div>
        </div>
      </div>
      <div className="ScanItemInModalAmountError__content">
        <div className="ScanItemInModalAmountError__scannedItems">
          <div className="ScanItemInModalAmountError__static-area">
            <div className="ScanItemInModalAmountError__amount-ordered ScanItemInModalAmountError__static-text">
              Bestilt antal
            </div>
            <div className="ScanItemInModalAmountError__amount-received ScanItemInModalAmountError__static-text">
              Modtaget antal
            </div>
          </div>
          <div className="ScanItemInModalAmountError__items">
            {unequalAmountScanned.length > 0
              ? unequalAmountScanned.map((item) => (
                  <ScanItemInModalAmountErrorItem
                    item={item}
                    key={item.productNumber}
                    change={(prodNo: string, amount: number) =>
                      onChangeAmountHandler(prodNo, amount)
                    }
                    removeItem={(id: string) => {
                      setUnequalAmountScanned((prevState) =>
                        prevState.filter((x) => x.productNumber !== id)
                      );
                      removeItem(id);
                    }}
                  />
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="ScanItemInModalAmountError__information-area" />

      <div className="ScanItemInModalAmountError__actions u-cursor-pointer">
        <div
          className="ScanItemInModalAmountError__cancel-button"
          onClick={() => handleErrorAmountCorrected(false)}
        >
          Fortryd
        </div>
        <div
          className={receiveButtonColoration.join(" ")}
          onClick={() => handleErrorAmountCorrected(true)}
        >
          Bekræft antal
        </div>
      </div>
    </>
  );
};
