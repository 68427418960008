import { ChangeEvent, FC, Fragment, useState } from "react";

import { clamp } from "lodash";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Zoom } from "../../App";
import { useAddCustomItemMutation } from "../../Generated/graphql";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../ListItem/ListItem";
import { NumberInput } from "../NumberInput/NumberInput";
import { Select, SelectOption } from "../Select/Select";
import "./ItemAddManually.css";

const categoryList = [
  {
    label: "Vådlager",
    value: "Vådlager",
  },
  {
    label: "Tørlager",
    value: "Tørlager",
  },
  {
    label: "Slatlager",
    value: "Slatlager",
  },
];
const unitList = [
  {
    label: "Stykvis (stk.)",
    value: "stk",
  },
  {
    label: "Ruller (rl.)",
    value: "rl",
  },
  {
    label: "Pakker (pk.)",
    value: "pk",
  },
];

type Props = {
  resetToSearch: () => void;
};

export const ItemAddManually: FC<Props> = ({ resetToSearch }) => {
  const history = useHistory();

  const [addCustomItem] = useAddCustomItemMutation();
  const [barcode, setBarcode] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [inStock, setInStock] = useState(0);
  const [name, setName] = useState("");
  const [supplier, setSupplier] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState(0);

  const sendItemToAdd = () => {
    addCustomItem({
      variables: {
        category: category,
        inStock,
        name,
        barcode,
        description,
        price,
        supplier,
        units: unit,
      },
    })
      .then(() => {
        toast("Vare oprettet", {
          className: "u-toast-success",
          progressClassName: "u-toast-success-bar",
          transition: Zoom,
        });
        history.push("/inventory");
      })
      .catch(() => {
        toast("Fejl ved oprettelse", {
          className: "u-toast-error",
          progressClassName: "u-toast-success-bar",
          transition: Zoom,
        });
      });
  };

  return (
    <Fragment>
      <div className="ItemAddManually">
        <div className="AddItemDetails__edit" onClick={() => resetToSearch()}>
          <Icon name="editBtn" width={25} />
          <div className="AddItemDetails__edit-text">Fortryd</div>
        </div>
        <div className="EditItem__item">
          <div className="EditItem__option EditItem__name">
            <ListItem title="Navn">
              <TextInputField
                value={name}
                name="name"
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__description">
            <ListItem title="Beskrivelse">
              <TextInputField
                value={description}
                name="description"
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__barcode">
            <ListItem title="Stregkode">
              <TextInputField
                value={barcode}
                name="barcode"
                onChange={(e) => setBarcode(e.currentTarget.value)}
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__supplier">
            <ListItem title="Leverandør">
              <TextInputField
                value={supplier}
                name="supplier"
                onChange={(e) => setSupplier(e.currentTarget.value)}
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__price">
            <ListItem title="Pris pr. stk.">
              <TextInputField
                value={price.toString()}
                name="price"
                onChange={(e) =>
                  setPrice(clamp(Number(e.currentTarget.value), 0, Infinity))
                }
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__category">
            <ListItem title="Kategori" style={{ zIndex: 500 }}>
              <SelectInputField
                value={category}
                name="category"
                options={categoryList}
                onChange={(value) => setCategory(value.toString())}
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__category">
            <ListItem title="Enheder">
              <SelectInputField
                value={unit}
                name="unit"
                options={unitList}
                onChange={(value) => setUnit(value.toString())}
              />
            </ListItem>
          </div>
          <div className="EditItem__option EditItem__inStock">
            <ListItem title="Aktuel beholdning">
              <div className="EditItem__currentStock">
                <div className="EditItem__numberInput">
                  <NumberInput
                    value={inStock}
                    unit={unit}
                    addOneFunction={() => setInStock((prev) => prev + 1)}
                    minusOneFunction={() =>
                      setInStock((prev) => clamp(prev - 1, 0, Infinity))
                    }
                    change={(e) =>
                      setInStock(
                        clamp(Number(e.currentTarget.value), 0, Infinity)
                      )
                    }
                  />
                </div>
              </div>
            </ListItem>
          </div>
        </div>
      </div>
      <div
        className="NewItem__footer NewItem__is_manual_button"
        style={{ right: 0 }}
      >
        <div className="NewItem__footer-changesSaved">
          <Button buttonStyle="newItem" width="107px" click={sendItemToAdd}>
            <Icon name="add" width={12} />
            <div>Opret vare</div>
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

type TextInputFieldProps = {
  value: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
};

const TextInputField: FC<TextInputFieldProps> = ({
  value,
  name,
  readOnly = false,
  onChange,
}) => {
  return (
    <input
      type="text"
      className="EditItem__TextInputField-input"
      value={value}
      name={name}
      readOnly={readOnly}
      onChange={onChange}
    />
  );
};

type SelectInputFieldProps = {
  value: string;
  options: { label: string; value: string | number }[];
  name: string;
  onChange: (value: string | number) => void;
};

const SelectInputField: FC<SelectInputFieldProps> = ({
  value,
  options,
  name,
  onChange,
}) => {
  return (
    <div className="EditItem__SelectInputField">
      <div className="EditItem__select">
        <Select change={onChange} value={value} type="editItem">
          {options.map(({ label, value }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </div>
    </div>
  );
};
