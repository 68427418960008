import moment from "moment";
import "moment/locale/da";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Zoom } from "../../App";
import { Button } from "../../Components/Button/Button";
import { CurrentActiveOrder } from "../../Components/CurrentActiveOrder/CurrentActiveOrder";
import { DelayedOrders } from "../../Components/DelayedOrders/DelayedOrders";
import { Icon } from "../../Components/Icon/Icon";
import { OrderNowModal } from "../../Components/OrderNowModal/OrderNowModal";
import { OrdersToday } from "../../Components/OrdersToday/OrdersToday";
import {
  useCartsQuery,
  useOrderCartMutation,
  useOrdersQuery,
} from "../../Generated/graphql";
import "./Orders.css";

moment.locale("da");

export const Cart = () => {
  const history = useHistory();
  const [orderNowBox, setOrderNowBox] = useState(false);
  const [windowWidth, setWindowWidth] = useState(768);
  const [scrollValue, setScrollValue] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [ordering, setOrdering] = useState(false);

  const { data } = useCartsQuery();
  const { data: orders } = useOrdersQuery();

  const [sendOrder] = useOrderCartMutation();

  const todaysOrders: number = useMemo(() => {
    if (!orders) {
      return 0;
    }

    const orderedToday = orders.orders.filter(
      (order) => order.orderDate === moment().format("YYYYMMDD")
    );

    return orderedToday.length;
  }, [orders]);

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  const currentCart = useMemo(() => {
    if (data && data.carts.length > 0) {
      return data.carts[0];
    }
    return null;
  }, [data]);

  const nextCarts = useMemo(() => {
    if (data && data.carts.length > 1) {
      return data.carts.filter((_, index) => index > 0);
    }
    return [];
  }, [data]);

  const addNewItem = () => {
    history.push("/orders/add");
  };

  /**
   * Triggers the ordering of the current cart
   */
  const orderNow = (shouldOrderNowResponse: boolean) => {
    if (!shouldOrderNowResponse) {
      setOrderNowBox(false);
    } else {
      if (!data?.carts[0]) {
        return;
      }

      setOrdering(true);
      sendOrder({ variables: { cartId: data.carts[0].id } })
        .then(() => {
          setOrderNowBox(false);
          setOrderError(true);
          setOrdering(false);
        })
        .catch(() => {
          setOrderNowBox(false);
          setOrdering(false);
          toast("Fejl ved afsendelse", {
            className: "u-toast-error",
            progressClassName: "u-toast-success-bar",
            transition: Zoom,
          });
        });
    }
  };

  return (
    <div className="Orders u-no-text-select">
      <OrderNowModal
        normalSendDate={
          currentCart
            ? moment(+currentCart.automaticOrderDate).format("LL")
            : ""
        }
        response={orderNow}
        open={orderNowBox}
        ordering={ordering}
      />
      <div className="Orders__header">
        <div className="Orders__title">Dine bestillinger</div>
      </div>
      <div className="Orders__dividerbar" />
      <div
        className="Orders__actions"
        style={{
          boxShadow: scrollValue ? "0 4px 7px 0 rgba(0,0,0,0.15)" : undefined,
          height: scrollValue ? "49px" : "66px",
        }}
      >
        <div className="Orders__actions-backdrop">
          <OrdersToday
            orderError={orderError}
            amountOrderedToday={todaysOrders}
          />
          {windowWidth > 1023 && (
            <div className="Orders__next-order-date">
              Næste automatiske bestilling:{" "}
              <b>
                {currentCart?.automaticOrderDate
                  ? moment(+currentCart.automaticOrderDate).format(
                      "dddd [d. ] Do MMMM [kl. ] HH"
                    )
                  : null}
              </b>
            </div>
          )}

          <div className="Order__add-items">
            <Button buttonStyle="newItem" width="200px" click={addNewItem}>
              <Icon name="add" width={12} />
              <div>Tilføj varer til bestilling</div>
            </Button>
          </div>
        </div>
      </div>
      <div
        className="Orders__content-wrapper"
        onScroll={(e) => {
          if (!scrollValue && e.currentTarget.scrollTop > 8) {
            setScrollValue(true);
          } else if (scrollValue && e.currentTarget.scrollTop <= 5) {
            setScrollValue(false);
          }
        }}
      >
        <div className="Orders__order-date">
          Din bestilling til{" "}
          <div className="Order__order-date-next">
            {currentCart?.automaticOrderDate
              ? moment(+currentCart.automaticOrderDate).format(
                  "dddd [d. ] Do MMMM"
                )
              : null}
          </div>
        </div>
        <CurrentActiveOrder
          currentOrder={currentCart}
          windowWidth={windowWidth}
          triggerOrderNow={() => setOrderNowBox(true)}
        />
        {nextCarts.map((order) =>
          order && order.orderLines && order.orderLines.length > 0 ? (
            <DelayedOrders
              order={order}
              key={order.id}
              windowWidth={windowWidth}
            />
          ) : null
        )}
      </div>
    </div>
  );
};
