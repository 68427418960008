import { FC, useState } from "react";

import { toast } from "react-toastify";
import { Zoom } from "../../../App";
import {
  AccountSettings,
  AccountSettingsDocument,
  useUpdateAccountSettingsMutation,
} from "../../../Generated/graphql";
import { AutomaticOrderToggleButton } from "../../AutomaticOrderToggleButton/AutomaticOrderToggleButton";
import { Button } from "../../Button/Button";
import { Checkbox } from "../../Checkbox/Checkbox";
import { ListItem } from "../../ListItem/ListItem";
import { Select, SelectOption } from "../../Select/Select";
import "./SettingsCompanyContent.css";

type Props = {
  settings: AccountSettings;
};

export const SettingsCompanyContent: FC<Props> = ({ settings }) => {
  const [scanOutCounting, setScanOutCounting] = useState(
    settings.countOnScan ?? false
  );

  const [globalAutoOrdering, setGlobalAutoOrdering] = useState(
    settings.hasAutomaticOrdering
  );

  const [avoidHolidays, setAvoidHolidays] = useState(settings.avoidHolidays);

  const [autoOrderData, setAutoOrderData] = useState(
    settings.automaticOrderDays
  );

  const [interval, setInterval] = useState(settings.automaticOrderInterval);

  const [updateSettings] = useUpdateAccountSettingsMutation({
    variables: {
      args: {
        countOnScan: scanOutCounting,
        hasAutomaticOrdering: globalAutoOrdering,
        automaticOrderDays: autoOrderData,
        automaticOrderInterval: interval,
        // avoid holidays?
      },
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: AccountSettingsDocument,
        data: {
          accountSettings: data?.updateAccountSettings,
        },
      });

      return data;
    },
  });

  const handleSetDateAndTime = (value: any, day: any, type: any) => {
    const autoOrderDataCopy = [...autoOrderData];

    if (type === "day") {
      if (autoOrderDataCopy.find((x) => x?.day === value)) {
        // Update it, maybe even remove it.
        const orderDataFiltered = autoOrderDataCopy.filter(
          (x) => x?.day !== value
        );
        setAutoOrderData(orderDataFiltered || []);
      } else {
        setAutoOrderData((prevState) => [
          { day: value, time: "10:00" },
          ...prevState,
        ]);
      }
    } else if (type === "time") {
      if (autoOrderDataCopy.find((x) => x?.day === day)) {
        const orderDataFiltered = autoOrderDataCopy.filter(
          (x) => x?.day !== day
        );
        setAutoOrderData([{ day, time: value }, ...orderDataFiltered]);
      }
    }
  };

  return (
    <>
      <div className="SettingsAccessContent">
        <div className="SettingsAccess__item">
          <ListItem title="Aktivér optælling ved indscanning">
            <div className="SettingsAccess__checkbox">
              <Checkbox
                checked={scanOutCounting}
                change={() => setScanOutCounting(!scanOutCounting)}
              />
            </div>
          </ListItem>
        </div>
        <div className="SettingsAccess__item">
          <ListItem title="Automatisk genbestilling">
            <div className="SettingsAccess__checkbox">
              <Checkbox
                checked={globalAutoOrdering}
                change={() => setGlobalAutoOrdering(!globalAutoOrdering)}
              />
            </div>
          </ListItem>
        </div>
        <div className="SettingsAccess__item">
          <ListItem title="Juster automatisk genbestilling for helligedage">
            <div className="SettingsAccess__checkbox">
              <Checkbox
                checked={avoidHolidays}
                change={() => setAvoidHolidays(!avoidHolidays)}
              />
            </div>
          </ListItem>
        </div>
        <div className="SettingsAccess__item">
          <ListItem title="Interval for automatisk genbestilling">
            <div className="SettingsAccess__checkbox" style={{ zIndex: 25 }}>
              <Select
                change={(v: any) => {
                  setInterval(v);
                }}
                value={interval}
                type="editItem"
              >
                {intervalMap.map(({ label, value }) => (
                  <SelectOption key={value} value={value}>
                    {label}
                  </SelectOption>
                ))}
              </Select>
            </div>
          </ListItem>
        </div>
        {options.map((option, index) => {
          const checked = autoOrderData.find((x) => x?.day === option.value)
            ? true
            : false;
          const disabledStyling = checked
            ? { backgroundColor: "#fff" }
            : { backgroundColor: "#F1F4F6" };
          return (
            <div
              className="SettingsAccess__item"
              style={{
                ...disabledStyling,
                position: "relative",
                padding: "8px 20px",
              }}
              key={option.value}
            >
              <div className="ListItem">
                <div className="SettingsAccess__checkbox-wrapper">
                  <AutomaticOrderToggleButton
                    click={() =>
                      handleSetDateAndTime(option.value, null, "day")
                    }
                    value={checked}
                  />
                </div>
                <div className="ListItem__title">{option.label}</div>

                <div className="SettingsCompanyContent__indent" />
                <div
                  className="SettingsAccess__checkbox"
                  style={{
                    zIndex: 24 - index,
                    // width: '300px',
                    display: "flex",
                  }}
                >
                  <Select
                    disabled={!checked}
                    change={(v: any) => {
                      handleSetDateAndTime(v, option.value, "time");
                    }}
                    value={
                      autoOrderData.find((x) => x?.day === option.value)
                        ? autoOrderData.find((x) => x?.day === option.value)
                            ?.time
                        : "00:00"
                    }
                    type="editItem"
                  >
                    {timeMap.map(({ label, value }) => (
                      <SelectOption key={value} value={value}>
                        {!checked ? "Deaktiveret" : label}
                      </SelectOption>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="SettingsAccess__actions">
        <div className="SettingsNewUser__confirm-button">
          <Button
            buttonStyle="newItem"
            labelStyle="createButton"
            click={() => {
              return updateSettings().then(() => {
                toast("Informationer opdateret", {
                  className: "u-toast-success",
                  progressClassName: "u-toast-success-bar",
                  transition: Zoom,
                });
              });
            }}
          >
            Gem ændringer
          </Button>
        </div>
      </div>
    </>
  );
};

const intervalMap = [
  {
    label: "Ugenligt",
    value: "weekly",
  },
  {
    label: "Hver 2. uge",
    value: "biweekly",
  },
  {
    label: "Hver 3. uge",
    value: "triweekly",
  },
  {
    label: "Månedligt",
    value: "monthly",
  },
];

const options = [
  {
    label: "Mandag",
    value: "monday",
  },
  {
    label: "Tirsdag",
    value: "tuesday",
  },
  {
    label: "Onsdag",
    value: "wednesday",
  },
  {
    label: "Torsdag",
    value: "thursday",
  },
  {
    label: "Fredag",
    value: "friday",
  },
];

const timeMap = [
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "20:00",
    value: "20:00",
  },
  {
    label: "21:00",
    value: "21:00",
  },
  {
    label: "22:00",
    value: "22:00",
  },
];
