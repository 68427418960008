import React from 'react';

import './Checkbox.css'
import { Icon } from '../Icon/Icon';

export const Checkbox = ({ checked, partiallyChecked = false, change }) => {
  return (
    <div className="Checkbox" onClick={() => change(!checked)}>
      {checked ? <div className="Checkbox__box">
        <div className="Checkbox__checked">
          <Icon name="checkmarkWhite" width={12} height={18} />
        </div>
      </div> : partiallyChecked ? <div className="Checkbox__partiallyChecked">
        <Icon name="subtractWhite" width={12} className="Icon__center" />
      </div> : null}
    </div>
  )
}