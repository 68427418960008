import { makeVar } from "@apollo/client";
import { ReactNode } from "react";
import {
  CartsDocument,
  InventoryDocument,
  InventoryItem,
  Order,
  OrdersDocument,
  Scan,
  ScansDocument,
  useKeepAliveSubscription,
  useOnCartsUpdatedSubscription,
  useOnInventoryItemAddedSubscription,
  useOnInventoryItemDeletedSubscription,
  useOnInventoryItemUpdatedSubscription,
  useOnOrderAddedSubscription,
  useOnOrderDeletedSubscription,
  useOnOrderErrorSubscription,
  useOnOrderUpdatedSubscription,
  useOnScansUpdatedSubscription,
} from "../../Generated/graphql";

export const orderServiceErrorVar = makeVar({
  firstErrorTime: "",
  currentlyActive: false,
});

export const Subscribers = ({
  accountId,
  children,
}: {
  accountId: string;
  children: ReactNode;
}) => {
  useKeepAliveSubscription();

  // On each subscription callback we update the reactive var
  useOnOrderErrorSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data?.onOrderError;
      if (data) {
        orderServiceErrorVar({
          firstErrorTime: data.currentlyActive ? data.firstErrorTime : "",
          currentlyActive: data.currentlyActive,
        });
      }
    },
  });

  useOnCartsUpdatedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;

      if (subscriptionData.data && subscriptionData.data?.onCartsUpdated) {
        cache.writeQuery({
          query: CartsDocument,
          data: {
            carts: subscriptionData.data?.onCartsUpdated,
          },
        });
      }
    },
  });

  useOnInventoryItemUpdatedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ inventory: InventoryItem[] }>({
        query: InventoryDocument,
      });

      if (!previous) {
        return;
      }

      // Map the inventory, and replace the old stock with the new one
      const updatedInventory = previous.inventory.map((item) => {
        if (item.id === subscriptionData.data?.onInventoryItemUpdated.id) {
          return subscriptionData.data.onInventoryItemUpdated;
        }
        return item;
      });

      cache.writeQuery({
        query: InventoryDocument,
        data: {
          inventory: updatedInventory,
        },
      });
    },
  });

  useOnInventoryItemAddedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ inventory: InventoryItem[] }>({
        query: InventoryDocument,
      });

      if (!previous || !subscriptionData.data?.onInventoryItemAdded) {
        return;
      }

      // Map the inventory, and replace the old stock with the new one
      const updatedInventory = [
        ...previous.inventory,
        subscriptionData.data?.onInventoryItemAdded,
      ];

      cache.writeQuery({
        query: InventoryDocument,
        data: {
          inventory: updatedInventory,
        },
      });
    },
  });

  useOnInventoryItemDeletedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ inventory: InventoryItem[] }>({
        query: InventoryDocument,
      });

      if (!previous) {
        return;
      }

      const deletedProductId = subscriptionData.data?.onInventoryItemDeleted.id;

      // Filter the inventory, to remove the item from the list
      const updatedInventory = previous.inventory.filter(
        (item) => item.id !== deletedProductId
      );

      cache.writeQuery({
        query: InventoryDocument,
        data: {
          inventory: updatedInventory,
        },
      });
    },
  });

  useOnOrderUpdatedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ orders: Order[] }>({
        query: OrdersDocument,
      });

      if (!previous) {
        return;
      }

      const newData = subscriptionData.data?.onOrderUpdated;

      if (!newData) {
        return;
      }

      const updatedUserOrders = previous.orders.map((order) => {
        if (order.id === newData.id) {
          return newData;
        } else {
          return order;
        }
      });

      cache.writeQuery({
        query: OrdersDocument,
        data: {
          orders: updatedUserOrders,
        },
      });
    },
  });

  useOnOrderAddedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ orders: Order[] }>({
        query: OrdersDocument,
      });

      if (!previous) {
        return;
      }

      const newData = subscriptionData.data?.onOrderAdded;

      if (!newData) {
        return;
      }

      const updatedUserOrders = [newData, ...previous.orders];

      cache.writeQuery({
        query: OrdersDocument,
        data: {
          orders: updatedUserOrders,
        },
      });
    },
  });

  useOnOrderDeletedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ orders: Order[] }>({
        query: OrdersDocument,
      });

      if (!previous) {
        return;
      }

      const newData = subscriptionData.data?.onOrderDeleted;

      if (!newData) {
        return;
      }

      const updatedUserOrders = previous.orders.filter(
        (order) => order.id !== newData.id
      );

      cache.writeQuery({
        query: OrdersDocument,
        data: {
          orders: updatedUserOrders,
        },
      });
    },
  });

  useOnScansUpdatedSubscription({
    variables: {
      accountId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.cache;
      const previous = cache.readQuery<{ scans: Scan }>({
        query: ScansDocument,
      });

      if (!previous) {
        return;
      }

      const newData = subscriptionData.data?.onScansUpdated;

      if (!newData) {
        return;
      }

      cache.writeQuery({
        query: ScansDocument,
        data: {
          scans: {
            ...previous.scans,
            items: newData.items,
          },
        },
      });
    },
  });

  return <>{children}</>;
};
