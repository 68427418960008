import React from "react";
import { orderButtonState } from "../../Utils/oldOrderButtonEnum";
import { Icon } from "../Icon/Icon";
import "./OrderButton.css";

export const OrderButton = ({
  buttonStyle,
  labelStyle,
  state,
  click,
  disabled = false,
}) => {
  const button = ["OrderButton"];
  const label = ["OrderButton__label"];

  if (buttonStyle) {
    button.push(`OrderButton__${buttonStyle}`);
  }

  if (labelStyle) {
    label.push(`OrderButton__${labelStyle}`);
  }

  if (disabled) {
    button.push("OrderButton__disabled");
  }

  return (
    <div className={button.join(" ")} onClick={disabled ? () => {} : click}>
      {state === orderButtonState.ready ? (
        <div className="OrderButton__fragment">
          <Icon name="cartListview" width={18.2} />
          <div className={label.join(" ")}>Tilføj til kurv</div>
        </div>
      ) : state === orderButtonState.loading ? (
        <div className="OrderButton__fragment">
          <div className="OrderButton__spinning">
            <Icon name="buttonSpinner" width={18} height={18} />
          </div>
        </div>
      ) : (
        <div className="OrderButton__fragment">
          <Icon name="checkmarkWhite" width={11.2} />
          <div className={label.join(" ")}>Bestilt</div>
        </div>
      )}
    </div>
  );
};
