import React from "react";

import "./NoWatchedProducts.css";

export const NoWatchedProducts = ({ watchedProductsOnLoad }) => {
  return (
    <div className="NoWatchedProducts">
      <AnimatedWatcherIcon />
      <div className="NoWatchedProducts__title">
        {watchedProductsOnLoad > 0 ? (
          <div className="NoWatchedProducts__title-text">
            Ikke flere anbefalinger i øjeblikket
          </div>
        ) : (
          <div className="NoWatchedProducts__title-text">
            Ingen anbefalinger i øjeblikket
          </div>
        )}
      </div>

      <div className="NoWatchedProducts__status">
        <div className="NoWatchedProducts__status-text">
          Analyserer{" "}
          <span className="NoWatchedProducts__status-text--dot NoWatchedProducts__status-text--dot1">
            .
          </span>
          <span className="NoWatchedProducts__status-text--dot NoWatchedProducts__status-text--dot2">
            .
          </span>
          <span className="NoWatchedProducts__status-text--dot NoWatchedProducts__status-text--dot3">
            .
          </span>
        </div>
      </div>
    </div>
  );
};

const AnimatedWatcherIcon = () => (
  <div className="AnimatedWatcherIcon">
    <div className="AnimatedWatcherIcon__magnifyingglass">
      <div className="AnimatedWatcherIcon__magni__cicles">
        <div className="AnimatedWatcherIcon__magni-circle1">
          <div className="AnimatedWatcherIcon__magni-circle2"></div>
        </div>
        <div className="AnimatedWatcherIcon__magni-circle-sticky-boi"></div>
      </div>
    </div>
    <div className="AnimatedWatcherIcon__content">
      <div className="AnimatedWatcherIcon__Box"></div>
      <div className="AnimatedWatcherIcon__Box"></div>
      <div className="AnimatedWatcherIcon__Box"></div>
    </div>
  </div>
);
