import React, { Component } from 'react';
import { Icon } from '../Icon/Icon';

import './OfflineIndicator.css';

export class OfflineIndicator extends Component {
  render() {
    return (
      <div className="OfflineIndicator">
        <div className="OfflineIndicator__box">
          <div className="OfflineIndicator__content">
            <div className="OfflineIndicator__content__icon">
              <Icon name="lostConnection" width={42} />
            </div>
            <div className="OfflineIndicator__content__text">
              <div className="OfflineIndicator__content__title">
                Ingen internetforbindelse
              </div>
              <div className="OfflineIndicator__content__subtitle">
                Du kan ikke benytte systemet når du er offline. Forsøg at
                genskabe internetforbindelsen
              </div>
            </div>
          </div>
          <div className="OfflineIndicator__actions">
            <div className="OfflineIndicator__actions__timer">
              {`Venter på forbindelse...`}{' '}
            </div>
            <div className="OfflineIndicator__actions__spinner">
              <Icon name="buttonSpinner" width={18} />{' '}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
