import React from "react";
import { createPortal } from "react-dom";

import "./WatchedProductConfirmAction.css";
import { Icon } from "../../../Icon/Icon";
import { ExitButton } from "../../../ExitButton/ExitButton";

export const WatchedProductConfirmAction = ({ open, respond }) => {
  const secondaryModal = document.querySelector("#secondary-modal");

  return open
    ? createPortal(
        <div className="WatchedProductConfirmAction__backdrop">
          <div className="WatchedProductConfirmAction">
            <div className="WatchedProductConfirmAction__close-button">
              <div className="WatchedProductConfirmAction__close-text">
                Fortryd
              </div>
              <ExitButton click={() => respond("close-confirm")} />
            </div>
            <div className="WatchedProductConfirmAction__title">
              <div className="WatchedProductConfirmAction__title-text">
                Din anbefaling er godkendt
              </div>
            </div>

            <div className="WatchedProductConfirmAction__info">
              <div className="WatchedProductConfirmAction__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="WatchedProductConfirmAction__info-text">
                Dine indstillinger er ændret og træder i kraft øjeblikkeligt.
              </div>
            </div>

            <div className="WatchedProductConfirmAction__buttons">
              <div
                className="WatchedProductConfirmAction__exit"
                onClick={() => respond("close-confirm")}
              >
                Bekræft
              </div>
            </div>
          </div>
        </div>,
        secondaryModal
      )
    : null;
};
