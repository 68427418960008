import { FC, useEffect, useState } from "react";

import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import "./AppUpdatedIndicator.css";

type Props = {
  updateNowHandler: () => void;
};

export const AppUpdatedIndicator: FC<Props> = ({ updateNowHandler }) => {
  const [time, setTimer] = useState("10:00");

  const startTimer = (duration: number) => {
    let timer = duration;
    let minutes = 0;
    let seconds = 0;
    setInterval(() => {
      minutes = timer / 60;
      seconds = timer % 60;

      minutes = minutes < 10 ? 0 + minutes : minutes;
      seconds = seconds < 10 ? 0 + seconds : seconds;

      setTimer(Math.floor(minutes) + ":" + seconds);

      if (timer === 1) {
        updateNowHandler();
      }

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer(60 * 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="AppUpdatedIndicator">
      <div className="AppUpdatedIndicator__box">
        <div className="AppUpdatedIndicator__content">
          <div className="AppUpdatedIndicator__content__icon">
            <Icon name="appUpdatedIcon" width={42} />
          </div>
          <div className="AppUpdatedIndicator__content__text">
            <div className="AppUpdatedIndicator__content__title">
              En ny opdatering er klar
            </div>
            <div className="AppUpdatedIndicator__content__subtitle">
              Vi forbedrer hele tiden lagerstyringen for at sikre dig markedets
              bedste system.
            </div>
          </div>
        </div>
        <div className="AppUpdatedIndicator__actions">
          <div className="AppUpdatedIndicator__actions__timer">{`Opdaterer om ${time} minutter`}</div>
          <div className="AppUpdatedIndicator__actions__button">
            <Button
              buttonStyle="appUpdate"
              labelStyle="appUpdate"
              click={() => updateNowHandler()}
            >
              Opdater nu
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
