import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import "./ItemExistsInInventoryModal.css";

export const ItemExistsInInventoryModal = ({ anim = {}, respond }) => {
  return (
    <div className="ItemExistsInInventoryModal__backdrop" style={anim}>
      <div className="ItemExistsInInventoryModal">
        <div className="ItemExistsInInventoryModal__close-button">
          <div className="ItemExistsInInventoryModal__close-text">Fortryd</div>
          <ExitButton click={() => respond()} />
        </div>
        <div className="ItemExistsInInventoryModal__title">
          <div className="ItemExistsInInventoryModal__title-text">
            Varen er allerede oprettet på lageret
          </div>
        </div>

        <div className="ItemExistsInInventoryModal__info">
          <div className="ItemExistsInInventoryModal__info-icon">
            <Icon name="infoIcon" width={25} />
          </div>
        </div>

        <div className="ItemExistsInInventoryModal__buttons">
          <div
            className="ItemExistsInInventoryModal__confirm"
            onClick={() => respond()}
          >
            Ok
          </div>
        </div>
      </div>
    </div>
  );
};
