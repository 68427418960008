import React from "react";
import { Icon } from "../Icon/Icon";

import "./ScanCardActions.css";

export const ScanCardActions = ({ taken, setTaken, editable }) => {
  return (
    <div className="ScanCardActions">
      {editable && (
        <div
          className="ScanCardActions__action ScanCardActions__takeMore u-cursor-pointer"
          onClick={() => setTaken(taken + 1)}
        >
          <div className="ScanCardActions__icon ScanCardActions__takeMore-icon">
            <Icon name="subtractRed" width={24} />
          </div>
        </div>
      )}

      <div
        className="ScanCardActions__amount"
        style={{ width: !editable ? "100%" : "25%" }}
      >
        <div className="ScanCardActions__amount-text">-{taken}</div>
      </div>

      {editable && (
        <div
          className="ScanCardActions__action ScanCardActions__takeLess u-cursor-pointer"
          onClick={() => setTaken(taken - 1)}
        >
          <div className="ScanCardActions__icon ScanCardActions__takeLess-icon">
            <Icon name="cardAdd" width={24} />
          </div>
        </div>
      )}
    </div>
  );
};
