import React, { Component, Fragment } from 'react';
import Quagga from 'quagga';

import './Scanner.css';
import { Icon } from '../Icon/Icon';

export class ScannerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      devices: [],
      detected: false,
      loading: false,
      facingMode: 'environment'
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    await this.ensureCameraAccess()
      // Handle no camera found
      .catch(e => {
        alert('Du har intet kamera. Fejl: ' + e);
        this.props.toggleCamera();
      });

    await this.initQuagga();
    this.setState({ loading: false });
    this._onDetected();
  }

  componentWillUnmount() {
    Quagga.offDetected(this._onDetected);
    Quagga.stop();
  }

  initQuagga = () => {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.querySelector('#ScannerElement'),
          constraints: {
            facingMode: this.state.facingMode
          }
        },
        decoder: {
          readers: ['ean_reader', 'code_128_reader']
        },
        frequency: 0.5
      },
      function(err) {
        if (err) {
          return err;
        }
        Quagga.start();
      }
    );
  };

  _onDetected = () => {
    Quagga.onDetected(data => {
      if (data) {
        if (data.codeResult && data.codeResult.code) {
          this.setState({ detected: true }, () =>
            setTimeout(() => this.setState({ detected: false }), 400)
          );
          this.props.returnItem(data.codeResult.code);
        }
      }
    });
  };

  ensureCameraAccess() {
    if (!('mediaDevices' in navigator)) {
      return Promise.reject('No mediaDevices capabilitiy');
    }

    // We have devices, non need to check again
    if (this.state.devices.length > 0) {
      return Promise.resolve();
    }

    return (
      navigator.mediaDevices
        // Get a video stream, prompting the access modal if necessary
        .getUserMedia({ video: { facingMode: 'environment' } })
        // Then close the returned streams
        .then(mediaStream => {
          // Stop each stream
          mediaStream
            .getTracks()
            .forEach(mediaStreamTrack => mediaStreamTrack.stop());
          // Null the source object. Not sure if necessary (https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/stop#Stopping_a_video_stream)
          mediaStream.srcObject = null;
        })
        // Now save the relevant media devices to state
        .then(() => navigator.mediaDevices.enumerateDevices())
        // We only want video devices
        .then(devices => devices.filter(device => device.kind === 'videoinput'))
        // Reject if no video devices
        .then(devices =>
          devices.length > 0
            ? devices
            : Promise.reject('No video devices found')
        )
        // Update the state
        .then(devices => this.setState({ devices }))
    );
  }

  setNextCamera = () => {
    this.setState(
      state => ({
        facingMode: state.facingMode === 'environment' ? 'user' : 'environment'
      }),
      () => this.initQuagga()
    );
  };

  render() {
    return (
      <div className="Scanner">
        {this.state.loading && (
          <div className="Scanner__loading">
            {' '}
            <Icon name="ovalLoader" width={80} />{' '}
          </div>
        )}
        <div id="ScannerElement" />
        {this.state.detected && <div className="Scanner__detectedFlash" />}
        {!this.state.loading && (
          <Fragment>
            <div className="Scanner__actions">
              {this.props.flipMode && this.state.devices.length > 1 && (
                <div
                  className="Scanner__camera-flip"
                  onClick={() => this.setNextCamera()}
                >
                  <Icon name="cameraFlip" width={30} />
                </div>
              )}
              <div
                className="Scanner__close u-cursor-pointer"
                onClick={() => this.props.toggleCamera()}
              >
                <Icon name="cameraClose" width={30} />
              </div>
            </div>
            <div className="Scanner__draw-square">
              <Icon name="cameraCenter" width={173} />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
