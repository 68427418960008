import { ApolloProvider } from "@apollo/client/react/context";
import { createBrowserHistory as createHistory } from "history";
import Cookies from "js-cookie";
import { Component, FC, Fragment, ReactNode } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Transition } from "react-spring/renderprops.cjs";
import { cssTransition, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AddCommentToActiveOrder } from "./Components/AddCommentToActiveOrder/AddCommentToActiveOrder";
import { AppUpdatedIndicator } from "./Components/AppUpdatedIndicator/AppUpdatedIndicator";
import { Authorized } from "./Components/Authorized/Authorized";
import { EditItem } from "./Components/EditItem/EditItem";
import { NewItem } from "./Components/NewItem/NewItem";
import { OrdersAddItem } from "./Components/OrdersAddItem/OrdersAddItem";
import { SlideInComponent } from "./Components/SlideInComponent/SlideInComponent";
import { ToastCloseButton } from "./Components/ToastCloseButton/ToastCloseButton";
import { Dashboard } from "./Containers/Dashboard/Dashboard";
import { Inventory } from "./Containers/Inventory/Inventory";
import { Layout } from "./Containers/Layout/Layout";
import { Cart } from "./Containers/Orders/Orders";
import { Receive } from "./Containers/Receive/Receive";
import { Scan } from "./Containers/Scan/Scan";
import { SuperAdmin } from "./Containers/SuperAdmin/SuperAdmin";
import { UserSettings } from "./Containers/UserSettings/UserSettings";
import { OfflineContextProvider } from "./Context/OfflineContext";
import { SearchContextProvider } from "./Context/SearchContext";
import { setupApolloClient } from "./Utils/setupApolloClient";

const client = setupApolloClient();

const authenticationCookieConfig = { expires: 360 };

class App extends Component<
  any,
  {
    showUpdatedNotification: boolean;
  }
> {
  currentVersion = null;
  appCheckInterval: NodeJS.Timer | null = null;
  history = createHistory();

  constructor(props: any) {
    super(props);

    this.state = {
      showUpdatedNotification: false,
    };
  }

  async componentDidMount() {
    this.handleCookies();
    if (window.location.href.includes("local")) {
      return;
    } else {
      this.currentVersion = await this.getAppVersion();
      this.appCheckInterval = setInterval(this.checkAppVersion, 60000);
    }
  }

  componentWillUnmount() {
    if (this.appCheckInterval) {
      clearInterval(this.appCheckInterval);
    }
  }

  getLastItemInList = (location: Location) => {
    const list = location.pathname.split("/");
    if (list.length === 2) {
      return list[1];
    } else {
      return list[2];
    }
  };

  getAppVersion = () => {
    return fetch(`${window.location.origin}/build_version.json`)
      .then((response) => response.json())
      .then((responseAsJson) => responseAsJson.version)
      .catch((err) => new Error(`Error fetching version: ${err}`));
  };

  checkAppVersion = () => {
    this.getAppVersion()
      .then((newVersion) => {
        if (
          newVersion !== this.currentVersion &&
          !newVersion.toString().includes("Error")
        ) {
          this.setState({ showUpdatedNotification: true });
        }
      })
      .catch((err) => new Error(`There's an error: ${err}`));
  };

  /**
   * Remove any persisted account or distributor ids from cookies
   */
  removeAuthenticationIdCookies = () => {
    Cookies.remove("account_id", authenticationCookieConfig);
    Cookies.remove("distributor_id", authenticationCookieConfig);
  };

  removeApplicationSpecificCookies = () => {
    Cookies.remove("access_level", authenticationCookieConfig);
    Cookies.remove("app_id", authenticationCookieConfig);
  };

  handleCookies = () => {
    const queryParams = new URL(document.URL).searchParams;

    const distributorId = queryParams.get("distributor_id");
    const accountId = queryParams.get("account_id");
    const accessLevel = queryParams.get("access_level");
    const appId = queryParams.get("app_id");

    if (accessLevel) {
      this.removeApplicationSpecificCookies();

      Cookies.set("access_level", accessLevel, authenticationCookieConfig);

      if (accessLevel === "SUPERADMIN" && !appId) {
        this.history.push("/superadmin");
      }
    }

    if (appId) {
      Cookies.remove("app_id", authenticationCookieConfig);

      Cookies.set("app_id", appId, authenticationCookieConfig);

      if (accessLevel === "SUPERADMIN") {
        this.history.push(`/superadmin/accounts/edit/${appId}`);
      }
    }

    if (distributorId) {
      this.removeAuthenticationIdCookies();

      Cookies.set("distributor_id", distributorId, authenticationCookieConfig);

      return;
    }

    if (accountId) {
      this.removeAuthenticationIdCookies();

      Cookies.set("account_id", accountId, authenticationCookieConfig);

      return;
    }
  };

  reloadPage = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  render() {
    return (
      <OfflineContextProvider>
        <ApolloProvider client={client}>
          <Router history={this.history}>
            <>
              <Route
                render={({ location, history, ...rest }) => (
                  <div className="App" id="AppComponent">
                    {this.state.showUpdatedNotification && (
                      <AppUpdatedIndicator
                        updateNowHandler={() => this.reloadPage()}
                      />
                    )}
                    <Authorized accessLevel={Cookies.get("access_level")}>
                      <Switch>
                        <SearchContextProvider>
                          <Route
                            path="/"
                            render={({ location, history, ...rest }) => {
                              return (
                                <>
                                  <Layout>
                                    <div className="App__content">
                                      <Switch>
                                        <Route
                                          path="/superadmin"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <SuperAdmin />,
                                            })
                                          }
                                        />
                                        <Route
                                          path="/inventory"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <Inventory />,
                                            })
                                          }
                                        />
                                        <Route
                                          path="/orders"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <Cart />,
                                            })
                                          }
                                        />
                                        <Route
                                          path="/incoming"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <Receive />,
                                            })
                                          }
                                        />
                                        <Route
                                          path="/settings"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <UserSettings />,
                                            })
                                          }
                                        />
                                        <Route
                                          path="/dashboard"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <Dashboard />,
                                            })
                                          }
                                        />
                                        <Route
                                          exact
                                          path="/"
                                          render={() =>
                                            AnimatedAuthComponent({
                                              component: <Scan />,
                                            })
                                          }
                                        />
                                      </Switch>
                                      <Transition
                                        native
                                        // @ts-ignore
                                        items={location}
                                        keys={this.getLastItemInList}
                                        config={{
                                          tension: 270,
                                          friction: 40,
                                          precision: 0.01,
                                          velocity: 30,
                                        }}
                                        from={{
                                          opacity: 0,
                                          transform: "translateX(100%)",
                                        }}
                                        enter={{
                                          opacity: 1,
                                          transform: "translateX(0%)",
                                        }}
                                        leave={{
                                          opacity: 0,
                                          transform: "translateX(100%)",
                                        }}
                                      >
                                        {(loc, state) => (style) => {
                                          return (
                                            <Fragment>
                                              <Switch
                                                // @ts-ignore
                                                location={
                                                  state === "update"
                                                    ? location
                                                    : loc
                                                }
                                              >
                                                <Route
                                                  exact
                                                  path="/inventory/edit/:productId"
                                                  render={(props) =>
                                                    SlideInComponent({
                                                      ...props,
                                                      style,
                                                      component: <EditItem />,
                                                      closeModal: history,
                                                    })
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/inventory/new/:barcode"
                                                  render={(props) =>
                                                    SlideInComponent({
                                                      ...props,
                                                      style,
                                                      component: <NewItem />,
                                                      closeModal: history,
                                                    })
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/inventory/new"
                                                  render={(props) =>
                                                    SlideInComponent({
                                                      ...props,
                                                      style,
                                                      component: <NewItem />,
                                                      closeModal: history,
                                                    })
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/orders/add"
                                                  render={(props) =>
                                                    SlideInComponent({
                                                      ...props,
                                                      style,
                                                      component: (
                                                        <OrdersAddItem />
                                                      ),
                                                      closeModal: history,
                                                    })
                                                  }
                                                />
                                                <Route
                                                  path="/orders/:orderId/comment"
                                                  render={(props) =>
                                                    SlideInComponent({
                                                      ...props,
                                                      style,
                                                      component: (
                                                        <AddCommentToActiveOrder />
                                                      ),
                                                      closeModal: history,
                                                    })
                                                  }
                                                />
                                              </Switch>
                                            </Fragment>
                                          );
                                        }}
                                      </Transition>
                                      <ToastContainer
                                        position={toast.POSITION.BOTTOM_RIGHT}
                                        autoClose={8000}
                                        closeButton={<ToastCloseButton />}
                                      />
                                    </div>
                                  </Layout>
                                  <div id="modal-root" />
                                  <div id="secondary-modal" />
                                </>
                              );
                            }}
                          />
                        </SearchContextProvider>
                      </Switch>
                    </Authorized>
                  </div>
                )}
              />
            </>
          </Router>
        </ApolloProvider>
      </OfflineContextProvider>
    );
  }
}

export default App;

type AnimatedAuthComponentProps = {
  component: ReactNode;
};

const AnimatedAuthComponent: FC<AnimatedAuthComponentProps> = ({
  component,
}) => {
  return <div className="mainRoute">{component}</div>;
};

export const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
});

export const unitMap = [
  {
    key: "1",
    value: "stk.",
  },
  {
    key: "2",
    value: "kg.",
  },
  {
    key: "3",
    value: "kolli",
  },
  {
    key: "4",
    value: "sække",
  },
  {
    key: "5",
    value: "l.",
  },
  {
    key: "6",
    value: "par",
  },
  {
    key: "7",
    value: "trl.",
  },
  {
    key: "8",
    value: "M3.",
  },
];
