import { FC } from "react";
import { useHistory } from "react-router-dom";
import { AccountSettings } from "../../Generated/graphql";
import "./AccountListItem.css";

type Props = {
  account: AccountSettings;
};

export const AccountListItem: FC<Props> = ({ account }) => {
  const history = useHistory();
  return (
    <div className="AccountListItem">
      <div className="AccountListItem__name">{account.accountName}</div>
      {/* <div className="AccountListItem__addUser">
        <div
          className="AccountListItem__link"
          onClick={() => {
            history.push(`/superadmin/accounts/user/${account.id}`);
          }}
        >
          Tilføj bruger
        </div>
      </div> */}
      <div className="AccountListItem__edit">
        <div
          className="AccountListItem__link"
          onClick={() => {
            history.push(`/superadmin/accounts/edit/${account.id}`);
          }}
        >
          Rediger
        </div>
      </div>
      <div className="AccountListItem__delete" />
    </div>
  );
};
