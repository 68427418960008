import { FC } from "react";
import { useHistory } from "react-router-dom";
import { CartItem, useUserQuery } from "../../Generated/graphql";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import "./OrderContact.css";

type Props = {
  contactName?: string;
  contactPhone?: string;
  orderNow: () => void;
  orderLines: CartItem[];
  orderId: string;
  hasComment: boolean;
};

export const OrderContact: FC<Props> = ({
  contactName = "internt salg",
  contactPhone = "4466 6800",
  orderNow,
  orderLines,
  orderId,
  hasComment,
}) => {
  const history = useHistory();
  const { data: userData } = useUserQuery();

  const role = userData?.user?.role;
  const allowEdit = role === "ADMIN" ? true : false;
  const canOrderNow = orderLines.length > 0 && allowEdit;

  return (
    <div className="OrderContact u-no-text-select">
      <div className="OrderContact__image-container">
        <Icon name="callIcon" width={36} height={36} />
      </div>
      <div className="OrderContact__text">
        <div className="OrderContact__static-text">
          Spørgsmål til din ordre?
        </div>
        <div className="OrderContact__contact-details">
          Kontakt {contactName} på telefon {contactPhone}
        </div>
      </div>
      <div
        className={
          orderLines.length === 0
            ? "OrderContact__order-now"
            : "OrderContact__order-now OrderContact__order-now--disabled"
        }
      >
        <div
          className="OrderContact__comment"
          onClick={() => history.push(`orders/${orderId}/comment`)}
        >
          <div className="OrderContact__comment-icon">
            <Icon name="commentIcon" width={12} />
          </div>
          <div className="OrderContact__comment-text">
            {hasComment ? "Rediger kommentar" : "Tilføj kommentar"}
          </div>
        </div>
        <div className="OrderContant__order-button">
          <Button
            buttonStyle="createButton"
            labelStyle="createText"
            click={orderNow}
            inactive={!canOrderNow}
          >
            <Icon name="cartListview" width={18} />
            <div> Bestil nu</div>
          </Button>
        </div>
      </div>
    </div>
  );
};
