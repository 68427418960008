import { ApolloClient, useApolloClient } from "@apollo/client";
import _ from "lodash";
import { Component, Fragment, useMemo } from "react";
import { RouterProps, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Transition } from "react-spring/renderprops.cjs";
import { toast } from "react-toastify";
import { Zoom } from "../../App";
import { Icon } from "../../Components/Icon/Icon";
import { ScanCard } from "../../Components/ScanCard/ScanCard";
import { ScanCardInactive } from "../../Components/ScanCardInactive/ScanCardInactive";
import { ScanItemNotFoundModal } from "../../Components/ScanItemNotFoundModal/ScanItemNotFoundModal";
import { ScanNotEnoughStock } from "../../Components/ScanNotEnoughStock/ScanNotEnoughStock";
import { ScanNotInInventoryModal } from "../../Components/ScanNotInInventoryModal/ScanNotInInventoryModal";
import { ScannerComponent } from "../../Components/Scanner/Scanner";
import {
  AddScannedItemMutationFn,
  DeleteScannedItemMutationFn,
  InventoryItem,
  ProductDocument,
  ScanItem,
  Scan as ScanType,
  ScansDocument,
  UpdateScannedItemMutationFn,
  useAddScannedItemMutation,
  useDeleteScannedItemMutation,
  useInventoryQuery,
  useScansQuery,
  useUpdateScannedItemMutation,
} from "../../Generated/graphql";
import { IProduct } from "../../Interfaces/IProduct";
import { extractGTIN } from "../../Utils/extractGTIN";
import "./Scan.css";

export const Scan = () => {
  const history = useHistory();
  const { data: scannedItems } = useScansQuery();
  const { data: inventoryData } = useInventoryQuery();
  const [addScannedItemMutation] = useAddScannedItemMutation();
  const [updateScannedItemMutation] = useUpdateScannedItemMutation();
  const [deleteScannedItemMutation] = useDeleteScannedItemMutation();
  const client = useApolloClient();

  const usersScannedItems = scannedItems?.scans?.items || [];

  const inventory = useMemo(
    () =>
      inventoryData && inventoryData.inventory ? inventoryData.inventory : [],
    [inventoryData]
  );

  return (
    <ScanContent
      history={history}
      scannedItems={usersScannedItems}
      addScannedItemMutation={addScannedItemMutation}
      deleteScannedItemMutation={deleteScannedItemMutation}
      updateScannedItemMutation={updateScannedItemMutation}
      apolloClient={client}
      inventory={inventory}
    />
  );
};

interface IScanContentProps {
  history: RouterProps["history"];
  scannedItems: ScanType["items"];
  inventory: InventoryItem[];
  addScannedItemMutation: AddScannedItemMutationFn;
  deleteScannedItemMutation: DeleteScannedItemMutationFn;
  updateScannedItemMutation: UpdateScannedItemMutationFn;
  apolloClient: ApolloClient<any>;
}

interface IScanContentState {
  scannedItems: ScanType["items"];
  scanning: boolean;
  scannerInput: string;
  windowWidth: number;
  showBackArrow: boolean;
  showForwardArrow: boolean;
  showScanErrorModal: boolean;
  showScanNotInInventoryModal: boolean;
  isDoingWork: boolean;
  showNotEnoughStock: boolean;
  lastBarcodeScanned: string;
  activeBarcodeObject: Pick<ScanItem, "id" | "barcode" | "amount"> | null;
  settings: any;
}

export class ScanContent extends Component<
  IScanContentProps,
  IScanContentState
> {
  constructor(props: IScanContentProps) {
    super(props);

    this.state = {
      scannedItems: props.scannedItems,
      scanning: false,
      scannerInput: "",
      windowWidth: 768,
      showBackArrow: false,
      showForwardArrow: true,
      showScanErrorModal: false,
      showScanNotInInventoryModal: false,
      isDoingWork: false,
      showNotEnoughStock: false,
      lastBarcodeScanned: "",
      activeBarcodeObject: null,
      settings: {
        className: "Scan__slider",
        speed: 500,
        focusOnSelect: false,
        dots: true,
        infinite: false,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: false,
        nextArrow:
          this.state &&
          this.props.scannedItems &&
          this.props.scannedItems.length > 1 ? (
            <SlickButtonFix>
              <Icon name="slideRight" width={68} className="Scan__slide" />
            </SlickButtonFix>
          ) : (
            <SlickButtonFix>
              <div style={{ opacity: 0 }} />
            </SlickButtonFix>
          ),
        prevArrow: (
          <SlickButtonFix>
            <Icon
              name="slideRight"
              width={68}
              className="Scan__slide"
              opacity={this.state?.showBackArrow ? 0.2 : 0}
            />
          </SlickButtonFix>
        ),
        appendDots: (dots: any[]) => {
          return (
            <div
              style={{
                height: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                bottom: 0,
              }}
            >
              {dots.map((dot: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor:
                        dot.props.className !== ""
                          ? "rgba(0,0,0,0.7)"
                          : "rgba(0,0,0,0.3)",
                      width: "50px",
                      height: "6px",
                      marginLeft: "10px",
                      borderRadius: "3px",
                      transition: "all 200ms linear",
                      cursor: "pointer",
                    }}
                    onClick={dot.props.children.props.onClick}
                  />
                );
              })}
            </div>
          );
        },
        afterChange: (currentSlide: number) => {
          if (currentSlide >= 1 && this.state) {
            this.setState({ showBackArrow: true });
          } else {
            this.setState({ showBackArrow: false });
          }
        },
      },
    };

    this.addScannedItem = _.debounce(this.addScannedItem, 1000);
  }

  slider: Slider | null = null;

  scannerInputTimerFunc: NodeJS.Timeout | null = null;

  activeBarcodeObjectTimer: NodeJS.Timeout | null = null;

  activeCardIsDoneTimer: NodeJS.Timeout | null = null;

  regex = new RegExp("([A-Z0-9])");

  handleKeyPress = (event: KeyboardEvent) => {
    if (
      event &&
      event.key !== "Shift" &&
      event.key !== "Enter" &&
      this.regex.test(event.key)
    ) {
      this.setState((prevStat) => ({
        scannerInput: prevStat.scannerInput.concat(event.key),
      }));
    } else if (event && event.key === "Enter") {
      // Do whatever you need to with the barcode

      const extractedGTIN = extractGTIN(this.state.scannerInput)


      this.handleScannedItem(extractedGTIN || this.state.scannerInput);
      this.setState({ scannerInput: "" });
    }
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("keydown", this.handleKeyPress);
    this.rollingScannerReset();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener("keydown", this.handleKeyPress);
    this.cancelRollingScannerReset();
    this.resetActiveBarcodeObject();
  }

  resetScanner = () => {
    this.setState({ scannerInput: "" });
  };

  rollingScannerReset = () => {
    this.scannerInputTimerFunc = setTimeout(() => {
      if (this.state.scannerInput.length > 0) {
        this.resetScanner();
      }
      this.rollingScannerReset();
    }, 2000);
  };

  cancelRollingScannerReset = () => {
    if (this.scannerInputTimerFunc) {
      clearTimeout(this.scannerInputTimerFunc);
    }
  };

  updateWindowDimensions = () => {
    if (window && window.innerWidth) {
      this.setState({ windowWidth: window.innerWidth });
    }
  };

  handleScannedItem = (barcode: string) => {
    if (!barcode) {
      return;
    }

    const { activeBarcodeObject } = this.state;

    if (
      activeBarcodeObject &&
      activeBarcodeObject.barcode &&
      activeBarcodeObject.barcode === barcode
    ) {
      this.updateScannedItem({
        ...activeBarcodeObject,
        amount: activeBarcodeObject.amount + 1,
      });
    } else {
      this.addScannedItem(barcode);
    }
  };

  addScannedItem = (barcode: string) => {
    const currentInventory = this.props.inventory;
    const foundItem = currentInventory.find((x) => x?.barcode === barcode);

    // If we don't have that item on inventory
    // Query the server, and ask if it's even a product
    if (!foundItem) {
      this.props.apolloClient
        .query<{ product: IProduct }>({
          query: ProductDocument,
          variables: { barcode: barcode },
        })
        .then((productQueryResult) => {
          if (productQueryResult.data.product) {
            const { product } = productQueryResult.data;
            this.setState({
              isDoingWork: false,
              showScanNotInInventoryModal: true,
              lastBarcodeScanned: product.productData.barcode,
            });
          } else {
            throw new Error("Cant find that item, sorry");
          }
        })
        .catch(() => {
          this.setState({
            isDoingWork: false,
            showScanErrorModal: true,
          });
        });

      return;
    }

    if (foundItem?.amount === 0) {
      this.setState({ showNotEnoughStock: true });
      return;
    }

    this.props
      .addScannedItemMutation({
        variables: {
          barcode: foundItem.barcode,
        },
        update: (cache, next) => {
          const prev: ScanType | null = cache.readQuery({
            query: ScansDocument,
          });

          if (!prev) {
            return;
          }

          if (!next.data?.addScannedItem) {
            return;
          }

          cache.writeQuery({
            data: {
              ...prev,
              items: next.data?.addScannedItem.items,
            },
            query: ScansDocument,
          });
        },
      })
      .then(() => {
        this.setState({
          activeBarcodeObject: {
            barcode: foundItem.barcode,
            amount: 1,
            id: this.props.scannedItems[0]?.id!,
          },
        });
      })
      .then(() => {
        if (this.slider) {
          this.slider.slickGoTo(1, true);
        }
        this.setupScanTimer();
      })
      .catch(() => {
        toast("Fejl ved scanning", {
          className: "u-toast-error",
          progressClassName: "u-toast-error-bar",
          transition: Zoom,
        });
      });
  };

  // Update taken in the new amount as amount!
  updateScannedItem = ({
    id,
    amount,
    barcode,
  }: {
    id: string;
    amount: number;
    barcode: string;
  }) => {
    const currentInventory = this.props.inventory;
    const foundItem = currentInventory.find((x) => x?.barcode === barcode);

    if (!foundItem) {
      // Show dialog telling us we don't have this item in inventory
      return;
    }

    if (foundItem.amount === 0) {
      this.setState({ showNotEnoughStock: true });
      return;
    }

    this.props
      .updateScannedItemMutation({
        variables: {
          updateScannedItemId: id,
          prodNo: foundItem.productNumber,
          newAmount: amount,
        },
      })
      .then(async () => {
        if (
          this.state.activeBarcodeObject?.id &&
          this.state.activeBarcodeObject?.barcode
        ) {
          this.setState((prevState) => {
            return {
              activeBarcodeObject: {
                ...prevState.activeBarcodeObject!,
                amount: amount,
              },
            };
          });
        }
        if (this.activeBarcodeObjectTimer) {
          clearTimeout(this.activeBarcodeObjectTimer);
        }
        this.setupScanTimer();
      });
  };

  deleteScannedItem = ({
    id,
    productNumber,
  }: {
    id: string;
    productNumber: string;
  }) => {
    this.setState((state) => ({
      scannedItems: state.scannedItems.filter((item) => item.id !== id),
    }));
    this.props
      .deleteScannedItemMutation({
        variables: {
          scanId: id,
          prodNo: productNumber,
        },
      })
      .then(async () => {
        if (this.state.activeBarcodeObject?.id === id) {
          this.setState({ activeBarcodeObject: null });
          this.slider?.slickGoTo(0);
        }
      });
  };

  resetActiveBarcodeObject = () => {
    if (this.activeBarcodeObjectTimer) {
      clearTimeout(this.activeBarcodeObjectTimer);
    }
  };

  setupScanTimer = () => {
    this.activeBarcodeObjectTimer = setTimeout(() => {
      if (this.slider) {
        this.slider.slickGoTo(0, false);
      }
      this.setState({ activeBarcodeObject: null });
    }, 30000);
  };

  toggleCamera = () => {
    this.setState({ scanning: !this.state.scanning });
  };

  handleOnModalClose = () => {
    this.setState({ showScanErrorModal: false });
  };

  handleOnScanNotInInventoryModalClose = (response: boolean) => {
    this.setState({ showScanNotInInventoryModal: false }, () => {
      if (response) {
        this.props.history.push(
          "/inventory/new/" + this.state.lastBarcodeScanned
        );
      }
    });
  };

  render() {
    const { scannedItems, inventory } = this.props;
    const {
      activeBarcodeObject,
      settings,
      scanning,
      showNotEnoughStock,
      showScanNotInInventoryModal,
      showScanErrorModal,
      windowWidth,
    } = this.state;
    return (
      <Fragment>
        <ScanNotEnoughStock
          open={showNotEnoughStock}
          respond={() => this.setState({ showNotEnoughStock: false })}
        />
        <ScanNotInInventoryModal
          open={showScanNotInInventoryModal}
          respond={this.handleOnScanNotInInventoryModalClose}
        />
        <ScanItemNotFoundModal
          open={showScanErrorModal}
          respond={this.handleOnModalClose}
        />
        <div className="Scan">
          <div className="Scan__title">
            {windowWidth > 1023 ? (
              <h1 className="Scan__title-text">
                <b>Scan varer</b> der hentes fra lager
              </h1>
            ) : (
              <h1 className="Scan__title-text">
                <b>Scan varer</b>
              </h1>
            )}
            <div
              className="Scan__camera u-cursor-pointer"
              onClick={() => this.toggleCamera()}
            >
              <Icon
                name={
                  scanning ? "cameraToolbarActive" : "cameraToolbarInactive"
                }
                width={46}
                height={46}
              />
            </div>
            <div className="Scan__camera-divider" />
          </div>

          <div className="Scan__carousel">
            <Slider {...settings} ref={(ref) => (this.slider = ref)}>
              <div className="Scan__ScanMe" style={{ width: "423px" }}>
                {activeBarcodeObject ? (
                  <div className="newScannedItem">
                    <ScanCard
                      item={
                        this.props.scannedItems.find(
                          (item) => item.id === activeBarcodeObject?.id
                        )!
                      }
                      stockItem={inventory.find(
                        (stockItem) =>
                          stockItem!.barcode === activeBarcodeObject?.barcode
                      )}
                      updateScannedItem={this.updateScannedItem}
                      deleteScannedItem={this.deleteScannedItem}
                    />
                  </div>
                ) : (
                  <ScanCardInactive animation={{}} />
                )}
              </div>
              {scannedItems &&
                scannedItems.map((item) => {
                  const stockItem = this.props.inventory.find(
                    (inventoryItem) => inventoryItem?.barcode === item.barcode
                  );

                  if (!stockItem) {
                    return null;
                  }

                  return (
                    <div
                      className="Scan__Card"
                      style={{ width: "423px" }}
                      key={item.id}
                    >
                      <ScanCard
                        stockItem={stockItem}
                        item={item}
                        updateScannedItem={this.updateScannedItem}
                        deleteScannedItem={this.deleteScannedItem}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
        <Transition
          items={scanning}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          config={{ tension: 170, friction: 26, velocity: 25 }}
        >
          {(show) =>
            show &&
            ((props) => (
              <div className="Scan__scanner" style={props}>
                <ScannerComponent
                  flipMode={true}
                  returnItem={(barcode: string) =>
                    scanning ? this.handleScannedItem(barcode) : {}
                  }
                  toggleCamera={() => this.toggleCamera()}
                />
              </div>
            ))
          }
        </Transition>
      </Fragment>
    );
  }
}

// ! This is what you get when library maintainers doesn't wanna fix their stuff...
const SlickButtonFix = ({
  currentSlide,
  slideCount,
  children,
  ...props
}: any) => <div {...props}>{children}</div>;
