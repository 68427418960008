import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import Tooltip from "react-tooltip-lite";
import { noop } from "../../Utils/noop";
import { Icon } from "../Icon/Icon";
import "./NumberInput.css";

interface NumberInputProps {
  value: number;
  change: (e: ChangeEvent<HTMLInputElement>) => void;
  changeFormat?: (e?: ChangeEvent<HTMLInputElement>) => void;
  unit?: string;
  name?: string;
  addOneFunction?: () => void;
  minusOneFunction?: () => void;
  withButtons?: boolean;
  hasError?: boolean;
  withUnits?: boolean;
  canEdit?: boolean;
}

export const NumberInput = ({
  value,
  unit = "stk.",
  change,
  changeFormat = noop,
  name = "",
  addOneFunction = noop,
  minusOneFunction = noop,
  withButtons = true,
  hasError = false,
  withUnits = true,
  canEdit = true,
}: NumberInputProps) => {
  const [toggled, setToggled] = useState(false);
  const outsideRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: TouchEvent) => {
    if (outsideRef.current && !outsideRef.current.contains(e.target as Node)) {
      setToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
    };
  });

  const highlight = (e: MouseEvent) => {
    const el = e.currentTarget as EventTarget & HTMLInputElement;
    if (document.activeElement !== el) {
      setTimeout(() => {
        el.select();
      }, 0);
    }
  };

  const styles = ["NumberInput"];

  if (hasError) {
    styles.push("NumberInput__has-error");
  }

  if (!canEdit) {
    styles.push("NumberInput__cannot-edit");
  }

  return (
    <div className={styles.join(" ")}>
      {navigator.userAgent.match(/iPad/i) && hasError && (
        <div
          className="NumberInput__error-icon"
          onClick={() => setToggled(!toggled)}
          ref={outsideRef}
        >
          <Tooltip
            content={
              <div
                style={{
                  padding: "5px 5px 9px 5px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  maxWidth: "200px",
                  textAlign: "center",
                }}
              >
                Ønsket beholdning må ikke være mindre end minimumsbeholdning
              </div>
            }
            className="NumberInput__error-icon--target"
            background="rgba(170,37,37,1)"
            color="#fff"
            distance={10}
            direction="left"
            isOpen={toggled}
          >
            <Icon name="infoIcon" width={18} />
          </Tooltip>
        </div>
      )}
      {!navigator.userAgent.match(/iPad/i) && hasError ? (
        <Tooltip
          content={
            <div
              style={{
                padding: "5px 5px 9px 5px",
                fontSize: "12px",
                lineHeight: "16px",
                maxWidth: "200px",
                textAlign: "center",
              }}
            >
              Ønsket beholdning må ikke være mindre end minimumsbeholdning
            </div>
          }
          styles={{ display: "flex", maxWidth: "100%" }}
          background="rgba(170,37,37,1)"
          color="#fff"
          distance={3}
        >
          {withButtons && canEdit && (
            <>
              <div
                className="NumberInput__addOne NumberInput__button u-cursor-pointer"
                onClick={addOneFunction}
              >
                <Icon name="addBlue" width={12} className="Icon__center" />
              </div>
              <div
                className="NumberInput__minusOne NumberInput__button u-cursor-pointer"
                onClick={minusOneFunction}
              >
                <Icon name="subtractBlue" width={12} className="Icon__center" />
              </div>
            </>
          )}
          <input
            className="NumberInput__field"
            type="text"
            value={isNaN(value) ? 0 : value}
            onChange={change}
            onBlur={changeFormat}
            name={name}
            onMouseDown={highlight}
            disabled={!canEdit}
          />
        </Tooltip>
      ) : (
        <>
          {withButtons && canEdit && (
            <>
              <div
                className="NumberInput__addOne NumberInput__button u-cursor-pointer"
                onClick={addOneFunction}
              >
                <Icon name="addBlue" width={12} className="Icon__center" />
              </div>
              <div
                className="NumberInput__minusOne NumberInput__button u-cursor-pointer"
                onClick={minusOneFunction}
              >
                <Icon name="subtractBlue" width={12} className="Icon__center" />
              </div>
            </>
          )}
          <input
            className={
              withUnits
                ? "NumberInput__field"
                : "NumberInput__field NumberInput__field--nounits"
            }
            type="text"
            value={isNaN(value) ? 0 : value}
            onChange={change}
            onBlur={changeFormat}
            name={name}
            onMouseDown={highlight}
            disabled={!canEdit}
          />
          {withUnits && <div className="NumberInput__unit">{unit}</div>}
        </>
      )}
    </div>
  );
};
