import moment from "moment";
import { useEffect, useState } from "react";
// @ts-ignore
import { Calendar } from "react-calendar";
import { useHistory, useParams } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import {
  Holiday,
  HolidaysDocument,
  useHolidayQuery,
  useUpdateHolidayMutation,
} from "../../Generated/graphql";
import { capitalizeString } from "../../Utils/capitalizeString";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../ListItem/ListItem";
import { TextInput } from "../TextInput/TextInput";
import "./SuperAdminEditHoliday.css";

export const SuperAdminEditHoliday = () => {
  const params = useParams<{ id: string }>();
  const { data } = useHolidayQuery({
    variables: { holidayId: params.id },
    fetchPolicy: "cache-and-network",
  });

  const history = useHistory();

  const [date, setDate] = useState(new Date());
  const [name, setName] = useState("");

  const [showDatepicker, setShowDatepicker] = useState(false);

  useEffect(() => {
    if (data && data.holiday.holidayName) {
      setDate(moment(data?.holiday.day).toDate());
      setName(data?.holiday.holidayName);
    }
  }, [data]);

  const [updateSelectedHoliday] = useUpdateHolidayMutation({
    update: (cache, { data }) => {
      const oldData = cache.readQuery<{ holidays: Holiday[] }>({
        query: HolidaysDocument,
      });

      if (data && data.updateHoliday) {
        const newList = oldData?.holidays.map((x) => {
          if (x.id === data.updateHoliday.id) {
            return data.updateHoliday;
          } else {
            return x;
          }
        });

        cache.writeQuery({
          query: HolidaysDocument,
          data: {
            holidays: newList,
          },
        });
      }
    },
  });

  const handleSetDate = (input: Date) => {
    setShowDatepicker(!showDatepicker);
    setDate(input);
  };

  return (
    <div className="SuperAdminNewHoliday">
      <div
        className="EditItem__close"
        onClick={() => history.push("/superadmin/holidays")}
      >
        <div className="EditItem__closeborder">
          <Icon name="closeBlue" width={20} height={20} />
        </div>
      </div>
      <div className="SuperAdminNewAccount__header">
        <div className="SuperAdminNewAccount__title">
          <div className="SuperAdminNewAccount__title-text">Ret helligdag</div>
        </div>
      </div>
      <div
        className="SuperAdminNewAccount__item"
        style={{ borderTop: "1px solid #a1b4c4" }}
      >
        <ListItem title="Dato">
          <Tooltip
            className="SuperAdminNewHoliday__value"
            useHover={false}
            isOpen={showDatepicker}
            content={<EditDateCalendar date={date} setDate={handleSetDate} />}
            arrow={true}
          >
            <div className="SuperAdminNewHoliday__value">
              <TextInput
                name="date"
                textAlign="right"
                value={moment(date).format("DD/MM/YYYY")}
                onClick={() => setShowDatepicker(!showDatepicker)}
                change={() => {}}
              />
            </div>
          </Tooltip>
        </ListItem>
      </div>
      <div
        className="SuperAdminNewAccount__item"
        style={{ borderTop: "1px solid #a1b4c4" }}
      >
        <ListItem title="Navn">
          <TextInput
            name="name"
            textAlign="right"
            value={name}
            change={(e: any) => setName(e.target.value)}
          />
        </ListItem>
      </div>
      <div className="SuperAdminNewAccount__footer">
        <div className="SuperAdminNewAccount__button">
          <Button
            buttonStyle="newItem"
            width="157px"
            click={() => {
              if (!data?.holiday.id) {
                return;
              }

              return updateSelectedHoliday({
                variables: {
                  updateHolidayId: data.holiday.id,
                  holidayName: name,
                  day: moment(date)
                    .hour(12)
                    .format(),
                },
              }).then(() => history.goBack());
            }}
          >
            <div>Gem ændringer</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const EditDateCalendar = ({
  date,
  setDate,
}: {
  date: Date;
  setDate: (date: Date) => void;
}) => (
  <Calendar
    value={date}
    onChange={(newDate: Date) => {
      setDate(newDate);
    }}
    minDetail="month"
    // minDate={new Date()}
    formatShortWeekday={(locale: string, day: Date) => {
      return day
        .toLocaleDateString(locale, { weekday: "long" })
        .toUpperCase()
        .substring(0, 1);
    }}
    locale="da"
    tileContent={({ view, date }: { view: "month" | "year"; date: Date }) => {
      return (
        <div className="ExportDatepicker__calendar-tile-highlighter">
          <div className="ExportDatepicker__calendar-tile-text">
            {view === "month" && date.getDate()}
            {view === "year" &&
              capitalizeString(
                new Intl.DateTimeFormat("da-DK", {
                  month: "long",
                }).format(date)
              )}
          </div>
        </div>
      );
    }}
    navigationLabel={({ label }: { label: string }) => {
      return capitalizeString(label);
    }}
    nextLabel={<CalendarNextLabel />}
    prevLabel={<CalendarPrevLabel />}
  />
);

const CalendarNextLabel = () => (
  <div className="CalendarNextLabel CalendarLabel">
    <Icon name="arrowDown" width={14} />
  </div>
);

const CalendarPrevLabel = () => (
  <div className="CalendarPrevLabel CalendarLabel">
    <Icon name="arrowDown" width={14} />
  </div>
);
