import { createContext, FC, PropsWithChildren, useState } from "react";
import { getSubscriptionClient } from "../Utils/setupApolloClient";

export const OfflineContext = createContext<{
  isOffline: boolean;
}>({
  isOffline: false,
});

let offlineTimer: any | null = null;
const subClient = getSubscriptionClient();

export const OfflineContextProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [isOffline, setIsOffline] = useState(false);

  subClient.on("closed", () => {
    // On a closed event we wait 30 seconds before showing the offline banner
    // This is to avoid the blinky nature of the sockets reconnecting
    if (offlineTimer === null) {
      console.log(
        "Going offline in 30 seconds, unless subscription client goes back online"
      );
      offlineTimer = setTimeout(() => {
        setIsOffline(true);
      }, 30_000);
    }
  });

  subClient.on("connected", () => {
    // If we have just been offline, clear the timeout and set the offlineTimerRef to null
    if (offlineTimer !== null) {
      console.log("Subclient re-connected");
      clearTimeout(offlineTimer);
      offlineTimer = null;
    }
    setIsOffline(false);
  });

  const contextValue = { isOffline, setIsOffline };

  return (
    <OfflineContext.Provider value={contextValue}>
      {children}
    </OfflineContext.Provider>
  );
};
