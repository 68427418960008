import React from 'react';
import { createPortal } from 'react-dom';

import './NotRecognizedProduct.css';
import { ExitButton } from '../ExitButton/ExitButton';
import { Icon } from '../Icon/Icon';

export const NotRecognizedProduct = ({ open, respond }) => {
  const modalRoot = document.getElementById('modal-root');
  return open
    ? createPortal(
        <div className="NotRecognizedProduct__backdrop">
          <div className="NotRecognizedProduct">
            <div className="NotRecognizedProduct__close-button">
              <div className="NotRecognizedProduct__close-text">Fortryd</div>
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="NotRecognizedProduct__title">
              <div className="NotRecognizedProduct__title-text">
                Kunne ikke genkende produktet
              </div>
            </div>

            <div className="NotRecognizedProduct__info">
              <div className="NotRecognizedProduct__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="NotRecognizedProduct__info-text">
                Er stregkoden intakt? Prøv at scanne produktet igen.
              </div>
            </div>

            <div className="NotRecognizedProduct__buttons">
              <div
                className="NotRecognizedProduct__exit"
                onClick={() => respond(false)}
              >
                Tilbage
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
