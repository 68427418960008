import { useHistory } from "react-router-dom";
import { useAccountsQuery } from "../../Generated/graphql";
import { AccountListItem } from "../AccountListItem/AccountListItem";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import { Button } from "../Button/Button";
import "./SuperAdminAccounts.css";

export const SuperAdminAccounts = () => {
  const history = useHistory();
  const { data, error, loading } = useAccountsQuery();
  return (
    <div className="SuperAdmin__accountslist">
      <div className="SuperAdmin__accountslist__title">
        <div className="SuperAdmin__accountslist__title-text">Alle konti</div>
      </div>
      <div className="Settings__dividerbar"></div>
      <div className="SuperAdmin__newaccount">
        <div className="SuperAdmin__newaccount-button">
          <Button
            buttonStyle="newItem"
            click={() => {
              history.push("/superadmin/accounts/new");
            }}
          >
            Tilføj konto
          </Button>
        </div>
      </div>

      <div className="SuperAdmin__accounts">
        {loading && <BoxLoader />}
        {error && <div>{error.message}</div>}
        {data?.accounts.map((account) => {
          return <AccountListItem key={account.id} account={account} />;
        })}
      </div>
    </div>
  );
};
