import { createPortal } from "react-dom";
import { animated, useSpring } from "react-spring";

import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import "./OrderComment.css";

export const OrderComment = ({
  displayComments,
  comments,
  orderId,
  completed = false
}) => {
  return (
    <div
      className={completed ? "OrderComment OrderComment--dark" : "OrderComment"}
    >
      <Icon name="commentIcon" width={17} id="ActiveOrder__commentclicker" />
      <AmountDisplay
        number={comments.length}
        id="ActiveOrder__commentclicker"
        completed={completed}
      />
      <div
        className={
          completed
            ? "OrderComment__static-text OrderComment__static-text-dark"
            : "OrderComment__static-text"
        }
        id="ActiveOrder__commentclicker"
      >
        Se kommentar
      </div>
      <ViewOrderComments
        comments={comments}
        displaying={displayComments}
        orderId={orderId}
      />
    </div>
  );
};

const AmountDisplay = ({ number, completed }) => (
  <div className="AmountDisplay">
    <div className="AmountDisplay__text">{number}</div>
  </div>
);

const fromCustomer = (comment) => {  
  return comment.includes('fromCustomer|') || comment.includes('Dig: ') //Dig: AND Baden-Jensen: is deprecated, and will eventually be removed from the check
}

const formatComment = (comment) => {
  return comment.split('|')[1] || comment.split('Dig: ')[1] || comment.split('Baden-Jensen: ')[1]; //Dig: AND Baden-Jensen: is deprecated, and will eventually be removed from the check
}

export const ViewOrderComments = ({ displaying, comments, orderId }) => {
  const modalRoot = document.querySelector("#modal-root");
  const props = useSpring({
    right: displaying ? 0 : -800
  });

  return createPortal(
    <>
      <animated.div style={props} className="ViewOrderComments">
        <div className="ViewOrderComments__comments">
          <div className="ViewOrderComments__exit">
            <ExitButton text="Luk" />
          </div>
          <div className="ViewOrderComments__title">
            <div className="ViewOrderComments__title-text">
              {comments.length === 1 ? "Kommentar" : "Kommentarer"} til ordre{" "}
              {orderId}
            </div>
          </div>
          <div className="ViewOrderComments__comment-boxes">
            {comments &&
              Array.isArray(comments) &&
              comments.map(comment => (
                <CommentTrack comment={comment} key={comment} />
              ))}
          </div>
        </div>
        <div className="ViewOrderComments__footer">
          <div className="ViewOrderComments__content">
            <div className="ViewOrderComments__icon">
              <Icon name="callIcon" width={36} />
            </div>
            <div className="ViewOrderComments__text-block">
              <div className="ViewOrderComments__text-title">
                Spørgsmål til din ordre?
              </div>
              <div className="ViewOrderComments__text-subtitle">
                Kontakt internt salg på telefon 4466 6800
              </div>
            </div>
          </div>
        </div>
      </animated.div>
      {displaying && <div className="ViewOrderComments__backdrop" />}
    </>,
    modalRoot
  );
};

const CommentTrack = ({ comment }) => (
  <div className="CommentTrack__comment" key={comment}>
    <div className="CommentTrack__title">{fromCustomer(comment) ? "Dig" : "Baden-Jensen"}</div>
    <div className="CommentTrack__icon">
      <Icon name="commentIcon" width={12} />
    </div>
    <div className="CommentTrack__comment-box">{formatComment(comment)}</div>
  </div>
);
