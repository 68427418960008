import React from "react";
import { useSpring, animated } from "react-spring";

import "./DashboardCountUpCurrency.css";
import { formatLargeNumber } from "../../../Utils/formatLargeNumber";

export const DashboardCountUpCurrency = ({ value = 0, isNegative = false }) => {
  const numberProps = useSpring({
    to: async (next, cancel) => {
      await next({ count: value });
    },
    from: { count: 0 }
  });
  if (value === 0) {
    return <div>0,00 DKK</div>;
  }

  return (
    <animated.div>
      {numberProps.count.interpolate(x => formatLargeNumber(x.toFixed(2)))}
    </animated.div>
  );
};
