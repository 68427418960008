/* eslint-disable import/no-webpack-loader-syntax */

// Import syntax
// import add from '-!svg-inline-loader!../../img/icon-add-modified.svg';
import arrow from "-!svg-inline-loader!../../../src/Assets/arrow.svg";
import cameraCenter from "-!svg-inline-loader!../../../src/Assets/camera-centerpoint.svg";
import cameraFlip from "-!svg-inline-loader!../../../src/Assets/camera-flip.svg";
import clearIcon from "-!svg-inline-loader!../../../src/Assets/clear.svg";
import cameraClose from "-!svg-inline-loader!../../../src/Assets/close-camera.svg";
import trashcanWithRingEnabled from "-!svg-inline-loader!../../../src/Assets/delete/enabled.svg";
import editBtnActivated from "-!svg-inline-loader!../../../src/Assets/edit-btn/activated.svg";
import editBtn from "-!svg-inline-loader!../../../src/Assets/edit-btn/normal.svg";
import PencilEdit from "-!svg-inline-loader!../../../src/Assets/edit.svg";
import enterProgrammingMode from "-!svg-inline-loader!../../../src/Assets/enter-exit-programming-mode.svg";
import cardAdd from "-!svg-inline-loader!../../../src/Assets/icon-add-lg.svg";
import addBlue from "-!svg-inline-loader!../../../src/Assets/icon-add/secondary-blue.svg";
import add from "-!svg-inline-loader!../../../src/Assets/icon-add/white.svg";
import cartListviewGreen from "-!svg-inline-loader!../../../src/Assets/icon-basket-listview/green.svg";
import cartListview from "-!svg-inline-loader!../../../src/Assets/icon-basket-listview/white.svg";
import callIcon from "-!svg-inline-loader!../../../src/Assets/icon-call.svg";
import cameraToolbarInactive from "-!svg-inline-loader!../../../src/Assets/icon-camera-toolbar/disabled.svg";
import cameraToolbarActive from "-!svg-inline-loader!../../../src/Assets/icon-camera-toolbar/enabled.svg";
import cameraToolbarHover from "-!svg-inline-loader!../../../src/Assets/icon-camera-toolbar/hover.svg";
import camera from "-!svg-inline-loader!../../../src/Assets/icon-camera/disabled.svg";
import cameraEnabled from "-!svg-inline-loader!../../../src/Assets/icon-camera/enabled.svg";
import iconChange from "-!svg-inline-loader!../../../src/Assets/icon-change-dark.svg";
import checkmarkGreen from "-!svg-inline-loader!../../../src/Assets/icon-checkmark/green.svg";
import checkmarkTBlue from "-!svg-inline-loader!../../../src/Assets/icon-checkmark/tertiary-blue.svg";
import checkmarkWhite from "-!svg-inline-loader!../../../src/Assets/icon-checkmark/white.svg";
import closeBlue from "-!svg-inline-loader!../../../src/Assets/icon-close-small/secondary-blue.svg";
import close from "-!svg-inline-loader!../../../src/Assets/icon-close-small/white.svg";
import commentIcon from "-!svg-inline-loader!../../../src/Assets/icon-comment.svg";
import addComplete from "-!svg-inline-loader!../../../src/Assets/icon-complete.svg";
import trashcanTertiaryBlue from "-!svg-inline-loader!../../../src/Assets/icon-delete-small/tertiary-blue.svg";
import trashcanWhite from "-!svg-inline-loader!../../../src/Assets/icon-delete-small/white.svg";
import arrowDown from "-!svg-inline-loader!../../../src/Assets/icon-dropdown/blue.svg";
import editSmall from "-!svg-inline-loader!../../../src/Assets/icon-edit-small/blue.svg";
import editInventory from "-!svg-inline-loader!../../../src/Assets/icon-edit/blue.svg";
import employee from "-!svg-inline-loader!../../../src/Assets/icon-employee.svg";
import exportItems from "-!svg-inline-loader!../../../src/Assets/icon-export/normal.svg";
import iconHelp from "-!svg-inline-loader!../../../src/Assets/icon-help.svg";
import subtractBlue from "-!svg-inline-loader!../../../src/Assets/icon-increase/secondary-blue.svg";
import subtractWhite from "-!svg-inline-loader!../../../src/Assets/icon-increase/white.svg";
import infoIcon from "-!svg-inline-loader!../../../src/Assets/icon-info.svg";
import iconLock from "-!svg-inline-loader!../../../src/Assets/icon-lock.svg";
import lostConnection from "-!svg-inline-loader!../../../src/Assets/icon-lost-connection.svg";
import noImageAvail from "-!svg-inline-loader!../../../src/Assets/icon-missing-image.svg";
import cartInactive from "-!svg-inline-loader!../../../src/Assets/icon-nav-cart/disabled.svg";
import cartActive from "-!svg-inline-loader!../../../src/Assets/icon-nav-cart/enabled.svg";
import navDashboardDisabled from "-!svg-inline-loader!../../../src/Assets/icon-nav-dashboard/disabled.svg";
import navDashboardEnabled from "-!svg-inline-loader!../../../src/Assets/icon-nav-dashboard/enabled.svg";
import incommingGoodsInactive from "-!svg-inline-loader!../../../src/Assets/icon-nav-incominggoods/disabled.svg";
import incommingGoodsActive from "-!svg-inline-loader!../../../src/Assets/icon-nav-incominggoods/enabled.svg";
import scanInactive from "-!svg-inline-loader!../../../src/Assets/icon-nav-scan/disabled.svg";
import scanActive from "-!svg-inline-loader!../../../src/Assets/icon-nav-scan/enabled.svg";
import warehouseInactive from "-!svg-inline-loader!../../../src/Assets/icon-nav-warehouse/disabled.svg";
import warehouseActive from "-!svg-inline-loader!../../../src/Assets/icon-nav-warehouse/enabled.svg";
import notification from "-!svg-inline-loader!../../../src/Assets/icon-notification.svg";
import notifications from "-!svg-inline-loader!../../../src/Assets/icon-notifications.svg";
import orderTruck from "-!svg-inline-loader!../../../src/Assets/icon-ordered/blue.svg";
import orderTruckGreen from "-!svg-inline-loader!../../../src/Assets/icon-ordered/green.svg";
import autoOrderActive from "-!svg-inline-loader!../../../src/Assets/icon-ordering/activated.svg";
import orderingDisabled from "-!svg-inline-loader!../../../src/Assets/icon-ordering/disabled-tertiary-blue.svg";
import autoOrderDisabled from "-!svg-inline-loader!../../../src/Assets/icon-ordering/disabled.svg";
import preview from "-!svg-inline-loader!../../../src/Assets/icon-preview/normal.svg";
import printItems from "-!svg-inline-loader!../../../src/Assets/icon-print/normal.svg";
import productWatchIconLight from "-!svg-inline-loader!../../../src/Assets/icon-product-watch/light.svg";
import productWatchIcon from "-!svg-inline-loader!../../../src/Assets/icon-product-watch/normal.svg";
import scanner from "-!svg-inline-loader!../../../src/Assets/icon-scanner/disabled.svg";
import scannerEnabled from "-!svg-inline-loader!../../../src/Assets/icon-scanner/enabled.svg";
import search from "-!svg-inline-loader!../../../src/Assets/icon-search.svg";
import iconSettings from "-!svg-inline-loader!../../../src/Assets/icon-settings.svg";
import iconSignout from "-!svg-inline-loader!../../../src/Assets/icon-signout.svg";
import buttonSpinner from "-!svg-inline-loader!../../../src/Assets/icon-spinner.svg";
import subtractRed from "-!svg-inline-loader!../../../src/Assets/icon-subtract-lg.svg";
import undoIcon from "-!svg-inline-loader!../../../src/Assets/icon-undo.svg";
import appUpdatedIcon from "-!svg-inline-loader!../../../src/Assets/icon-update.svg";
import logoBorder from "-!svg-inline-loader!../../../src/Assets/icon-warehouse-border.svg";
import {
  default as cardScan,
  default as illustrationScan,
} from "-!svg-inline-loader!../../../src/Assets/illustration-scan.svg";
import bdjLogoText from "-!svg-inline-loader!../../../src/Assets/logo-baden-jensen.svg";
import bdjLogo from "-!svg-inline-loader!../../../src/Assets/logo-bdj-small.svg";
import ovalLoader from "-!svg-inline-loader!../../../src/Assets/oval.svg";
import resetScannerBarcode from "-!svg-inline-loader!../../../src/Assets/reset-scanner-barcode.svg";
import slideRight from "-!svg-inline-loader!../../../src/Assets/slide-right.svg";
import tendencyNegative from "-!svg-inline-loader!../../../src/Assets/tendency-negative.svg";
import tendencyPositive from "-!svg-inline-loader!../../../src/Assets/tendency-positive.svg";

const icons = {
  add,
  addBlue,
  addComplete,
  bdjLogo,
  subtractBlue,
  subtractWhite,
  subtractRed,
  cardAdd,
  arrow,
  arrowDown,
  autoOrderActive,
  autoOrderDisabled,
  buttonSpinner,
  close,
  closeBlue,
  cameraCenter,
  cameraClose,
  cameraFlip,
  orderTruck,
  orderTruckGreen,
  callIcon,
  cartListview,
  cartListviewGreen,
  checkmarkWhite,
  checkmarkGreen,
  editInventory,
  editBtn,
  editBtnActivated,
  editSmall,
  exportItems,
  illustrationScan,
  scanActive,
  scanInactive,
  warehouseActive,
  warehouseInactive,
  cardScan,
  cartActive,
  cartInactive,
  incommingGoodsActive,
  incommingGoodsInactive,
  lostConnection,
  notification,
  employee,
  orderingDisabled,
  notifications,
  printItems,
  search,
  scanner,
  scannerEnabled,
  cameraToolbarInactive,
  cameraToolbarActive,
  cameraToolbarHover,
  camera,
  cameraEnabled,
  trashcanTertiaryBlue,
  trashcanWhite,
  slideRight,
  iconHelp,
  iconSettings,
  iconSignout,
  preview,
  ovalLoader,
  noImageAvail,
  infoIcon,
  iconLock,
  logoBorder,
  trashcanWithRingEnabled,
  bdjLogoText,
  clearIcon,
  appUpdatedIcon,
  undoIcon,
  checkmarkTBlue,
  tendencyPositive,
  tendencyNegative,
  iconChange,
  navDashboardEnabled,
  navDashboardDisabled,
  commentIcon,
  productWatchIcon,
  productWatchIconLight,
  enterProgrammingMode,
  resetScannerBarcode,
  PencilEdit,
};

export default icons;
