import { FC } from "react";
import { createPortal } from "react-dom";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import "./ScanNotEnoughStock.css";

type Props = {
  open: boolean;
  respond: (answer: boolean) => void;
};

export const ScanNotEnoughStock: FC<Props> = ({ open, respond }) => {
  const modalRoot = document.getElementById("modal-root");

  if (!modalRoot) {
    return null;
  }
  return open
    ? createPortal(
        <div className="ScanNotEnoughStock__backdrop">
          <div className="ScanNotEnoughStock">
            <div className="ScanNotEnoughStock__close-button">
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="ScanNotEnoughStock__title">
              <div className="ScanNotEnoughStock__title-text">
                Der er ikke flere på lager
              </div>
            </div>

            <div className="ScanNotEnoughStock__info">
              <div className="ScanNotEnoughStock__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="ScanNotEnoughStock__info-text">
                Varen kunne ikke scannes ud, da der ikke er registreret flere
                varer på lageret. Dette kan f.eks. skyldes at nye varer ikke er
                tilføjet til lageret.
              </div>
            </div>

            <div className="ScanNotEnoughStock__buttons">
              <div
                className="ScanNotEnoughStock__exit"
                onClick={() => respond(false)}
              >
                Tilbage
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
