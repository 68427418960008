import React from "react";
import { Icon } from "../Icon/Icon";
import "./ScanCardInactive.css";

export const ScanCardInactive = ({ animation = {} }) => {
  return (
    <div className="ScanCardInactive" style={animation}>
      <div className="ScanCardInactive__logo">
        <Icon name="cardScan" width={151} />
      </div>
      <div className="ScanCardInactive__title">
        <b>Scan vare</b> for at fjerne den fra lager
        <div className="ScanCardInactive__subtitle">
          Benyt håndscanneren eller enhedens kamera
        </div>
      </div>
    </div>
  );
};
