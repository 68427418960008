import { useReactiveVar } from "@apollo/client";
import classNames from "classnames";
import { FC, useContext } from "react";
import { Icon } from "../../Components/Icon/Icon";
import { OfflineIndicator } from "../../Components/OfflineIndicator/OfflineIndicator";
import { OfflineContext } from "../../Context/OfflineContext";
import { orderServiceErrorVar } from "../Subscribers/Subscribers";
import "./Layout.css";
import { Navigation } from "./Navigation/Navigation";
import { Toolbar } from "./Toolbar/Toolbar";

export const Layout: FC = ({ children }) => {
  const { isOffline } = useContext(OfflineContext);
  const orderServiceError = useReactiveVar(orderServiceErrorVar);
  return (
    <div className="Layout">
      {isOffline && <OfflineIndicator />}
      <div className="Layout__sidenav">
        <Navigation />
      </div>
      <div className="Layout__content-background">
        <div className="Layout__content">
          <div
            id="order_date_changed"
            className="Layout__order-date-changed"
          ></div>
          {orderServiceError && orderServiceError.currentlyActive && (
            <OrderServiceError
              firstErrorTime={orderServiceError.firstErrorTime}
            />
          )}
          <div className="Layout__toolbar">
            <Toolbar />
          </div>
          <div className={classNames("Layout__routes")}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const OrderServiceError = ({ firstErrorTime }: { firstErrorTime: string }) => {
  return (
    <div className="OrderServiceError">
      <div></div>
      <h1 className="OrderServiceError__title">
        Forsinkelser i afsendelse af ordrer siden kl.{" "}
        {new Date(firstErrorTime).getHours()}.
        {new Date(firstErrorTime).getMinutes()}. Vi arbejder på en løsning.
      </h1>
      <div className="OrderServiceError__action">
        <p>Forsøger at genskabe forbindelsen...</p>
        <Icon
          className="OrderServiceError__spinner"
          name="buttonSpinner"
          width={20}
          height={20}
        />
      </div>
    </div>
  );
};
