import { get } from "lodash";
import { ChangeEvent, FC } from "react";
import { unitMap } from "../../../../App";
import { OrderItem, useProductQuery } from "../../../../Generated/graphql";
import { Icon } from "../../../Icon/Icon";
import { NumberInput } from "../../../NumberInput/NumberInput";
import "./ScanItemInModalAmountErrorItem.css";

type Props = {
  item: OrderItem & { isError: boolean };
  change: (productNumber: string, taken: number) => void;
  removeItem: (productNumber: string) => void;
};

export const ScanItemInModalAmountErrorItem: FC<Props> = ({
  item,
  change,
  removeItem,
}) => {
  const { data, error, loading } = useProductQuery({
    variables: {
      productNumber: item.productNumber,
    },
  });

  const changeAmountLocalAndInParent = (e: ChangeEvent<HTMLInputElement>) => {
    change(item.productNumber, Number(e.target.value));
  };

  if (error) return <div>Error...</div>;
  if (loading) return <div />;

  const unitAsText = unitMap.find(
    (x) => x.key === data?.product.productData.unit
  );

  return (
    <div className="ScanItemInModalAmountErrorItem">
      <div className="ScanItemInModalAmountErrorItem__itemDetails">
        <div
          className="ScanItemInModalAmountErrorItem__name"
          style={{ color: item.isError ? "#AA2525" : "#0C2C52" }}
        >
          {get(data, "product.productData.name", " ")}
        </div>
        <div
          className="ScanItemInModalAmountErrorItem__id"
          style={{ color: item.isError ? "#AA2525" : "#6D7A85" }}
        >
          {item.productNumber}
          {" - " + get(data, "product.productData.description", " ")}
        </div>
      </div>
      <div className="ScanItemInModalAmountErrorItem__adjustments">
        <div className="ScanItemInModalAmountErrorItem__amountOrdered">
          <div className="ScanItemInModalAmountErrorItem__amountOrdered-amount">
            {item.amount}
          </div>
          <div className="ScanItemInModalAmountErrorItem__amountOrdered-unit">
            {unitAsText?.value}
          </div>
        </div>
        <div className="ScanItemInModalAmountErrorItem__amountReceived">
          <NumberInput
            withButtons={false}
            value={item.taken}
            change={changeAmountLocalAndInParent}
            canEdit={false}
          />
        </div>
        <div
          className="ScanItemInModalAmountErrorItem__deleteMe"
          onClick={() => removeItem(item.productNumber)}
        >
          <Icon name="trashcanTertiaryBlue" width={9} />
        </div>
      </div>
    </div>
  );
};
