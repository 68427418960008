import React, { useState } from 'react';

import './ImageTooltip.css';
import { getImageWithSize } from '../../Utils/getImageWithSize';
import { BoxLoader } from '../BoxLoader/BoxLoader';

export const ImageTooltip = ({ imageUrl }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <>
      {!imageLoaded && (
        <div className="ImageTooltip__no-img-loaded">
          <BoxLoader />
        </div>
      )}
      <img
        style={
          imageLoaded
            ? {
                opacity: 1,
                transition: 'opacity 300ms ease-in-out',
                maxHeight: '200px',
                maxWidth: '200px'
              }
            : { opacity: 0 }
        }
        src={getImageWithSize(imageUrl, '200x200')}
        alt="Fejl..."
        onLoad={() => setImageLoaded(true)}
      />
    </>
  );
};
