import { get } from "lodash";
import moment from "moment";
import { ChangeEventHandler, useEffect, useState } from "react";
import Tooltip from "react-tooltip-lite";
import {
  AccountLog,
  AccountLogsDocument,
  useAccountLogsQuery,
} from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { HistoryEntry } from "./HistoryEntry/HistoryEntry";
import "./SettingsHistory.css";

export const SettingsHistory = () => {
  const [search, setSearch] = useState("");
  const [shadow, setShadow] = useState(false);
  const [shouldShowMore, setShouldShowMore] = useState(true);
  const { data, error, loading, fetchMore } = useAccountLogsQuery({
    variables: { limit: 50, offset: 0 },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const element = document.querySelector(".UserSettings__content");
    if (element) element.addEventListener("scroll", handleScroll);

    return () => {
      if (element) element.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = (event: any) => {
    const scrollTop = get(event, "srcElement.scrollTop", null);

    // ! Why this works, but an if/else didn't i do not know, so don't touch it.. alright?
    switch (scrollTop) {
      case 0:
        if (shadow) {
          setShadow(false);
        }
        break;

      default:
        if (!shadow) {
          setShadow(true);
        }
        break;
    }
  };

  const handleFetchMore = () => {
    //TODO: fetMore is being DEPRECATED in next MAJOR Apollo version
    fetchMore({
      query: AccountLogsDocument,
      variables: {
        limit: 50,
        offset: data?.accountLogs.length ? data.accountLogs.length : 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        if (fetchMoreResult?.accountLogs?.length === 0) {
          setShouldShowMore(false);
        }

        return {
          accountLogs: [...prev.accountLogs, ...fetchMoreResult.accountLogs],
        };
      },
    });
  };

  const getFilteredLogs = () => {
    if (!data?.accountLogs) {
      return [];
    }

    if (!search) {
      return data.accountLogs;
    } else {
      return data.accountLogs.filter((x) => {
        const formattedCreatedAt = moment(x?.createdAt).format(
          "DD[/]MM[/]YYYY hh:mm:ss"
        );
        if (
          x?.message.toLowerCase().includes(search.toLowerCase()) ||
          formattedCreatedAt.includes(search) ||
          (!x?.extraData.extraText
            ? false
            : x.extraData.extraText
                .toLowerCase()
                .includes(search.toLowerCase()))
        ) {
          return true;
        }

        return false;
      });
    }
  };

  return (
    <div className="SettingsAccess">
      <div className="SettingsAccess__title">
        <div className="SettingsAccess__title-text">Historik</div>
      </div>
      <div
        className="Settings__dividerbar"
        style={{
          boxShadow: shadow ? "rgba(0, 0, 0, 0.25) 0px 5px 7px 0px" : undefined,
        }}
      />
      <div className="SettingsAccess__content">
        <SettingsHistorySearch
          searchInput={search}
          set={(e: any) => {
            setSearch(e.target.value);
          }}
        />
        <div className="SettingsHistoryContent__list">
          <SettingsHistoryHeader />
          {loading && !error && (
            <div>
              <BoxLoader />
            </div>
          )}
          {!loading && !error && data?.accountLogs && (
            <SettingsHistoryContent logs={getFilteredLogs()} />
          )}
          {error && <div>Error: {error.message}</div>}
        </div>
        {shouldShowMore && (
          <div className="SettingsAccess__fetch-more">
            <Button
              buttonStyle="showMore"
              labelStyle="Button__showMoreOrders"
              width="229px"
              click={handleFetchMore}
            >
              Vis mere
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const SettingsHistoryContent = ({ logs }: { logs: AccountLog[] }) => (
  <div className="SettingsHistoryContent">
    {logs.map((log) => (
      <HistoryEntry item={log} key={log.id} />
    ))}
  </div>
);

const SettingsHistoryHeader = () => (
  <div className="SettingsHistoryHeader">
    <div className="SettingsHistoryHeader__text">
      <div className="SettingsHistoryHeader__text-time">Tid</div>
    </div>
    <div className="SettingsHistoryHeader__text">
      <div className="SettingsHistoryHeader__text-action">Handling</div>
    </div>
  </div>
);

const SettingsHistorySearch = ({
  searchInput = "",
  set,
}: {
  searchInput: string;
  set: ChangeEventHandler<HTMLInputElement>;
}) => (
  <div className="SettingsHistorySearch">
    <div className="SettingsHistorySearch__input">
      <input
        type="text"
        className="SettingsHistorySearch__input-field"
        value={searchInput}
        onChange={set}
        placeholder="Søg..."
      />
    </div>
    <div
      className="SettingsHistorySearch__search-icon"
      style={{ pointerEvents: searchInput.length > 0 ? "all" : "none" }}
      onClick={() => {
        if (searchInput.length > 0) {
          // @ts-ignore
          set({ target: { value: "" } });
        }
      }}
    >
      <Icon name={searchInput.length > 0 ? "clearIcon" : "search"} width={30} />
    </div>
    <div className="SettingsHistorySearch_help">
      <Tooltip
        content={<HelpTooltipContent />}
        className="SettingsHistorySearch_help-tooltip"
        background="#fff"
        color="#0c2c52"
        distance={10}
        direction="bottom"
      >
        <Icon name="iconHelp" width={18} />
      </Tooltip>
    </div>
  </div>
);

const HelpTooltipContent = () => (
  <div className="HelpTooltipContent">
    <div className="HelpTooltipContent__header">
      <div className="HelpTooltipContent__header-text">Sådan søger du</div>
    </div>
    <ul className="HelpTooltipContent__list">
      <li className="HelpTooltipContent__list-point">
        Søg på dato: <i>f.eks. "31/01/2020"</i>
      </li>
      <li className="HelpTooltipContent__list-point">
        Søg på handling: <i>f.eks. "bruger oprettet"</i>
      </li>
      <li className="HelpTooltipContent__list-point">
        Søg på produkt nummer: <i>f.eks. "31500"</i>
      </li>
    </ul>
  </div>
);
