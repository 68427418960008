import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { OrderItemStatus } from "../../Generated/graphql";
import "./OrderItemContainer.css";

type Props = {
  status: OrderItemStatus;
};

export const OrderItemContainer: FC<PropsWithChildren<Props>> = ({
  status,
  children,
}) => (
  <div
    className={classNames("OrderItemContainer", {
      "OrderItemContainer--active": status === OrderItemStatus.Active,
      "OrderItemContainer--backorder": status === OrderItemStatus.Backorder,
      "OrderItemContainer--completed": status === OrderItemStatus.Completed,
    })}
  >
    {children}
  </div>
);
