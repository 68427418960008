import React, { useState } from "react";

import "./DashboardPeriodSelector.css";
import { Icon } from "../../Icon/Icon";
import { DashboardPopOutMenu } from "../DashboardPopOutMenu/DashboardPopOutMenu";

export const DashboardPeriodSelector = ({
  setSelectedMonth,
  selectedMonth,
  monthOptions,
  theme = "dark",
  hasAccountancyYear
}) => {
  const [displayState, setDisplayState] = useState(false);
  return (
    <>
      {" "}
      <div
        className="DashboardOrderCalendarMenu"
        onClick={() => setDisplayState(true)}
        style={{ pointerEvents: displayState ? "none" : "auto" }}
      >
        <div
          className="DashboardOrderCalendarMenu__month"
          style={{ color: theme === "light" ? "#fff" : null }}
        >
          {hasAccountancyYear
            ? `Regnskabsår ${selectedMonth.name}`
            : `${selectedMonth.name}`}
        </div>
        <div
          className={`DashboardOrderCalendarMenu__menu-point DashboardOrderCalendarMenu__menu-point--${theme}`}
        >
          <Icon name="iconChange" width={3} />
        </div>
      </div>
      {displayState && (
        <DashboardPopOutMenu
          options={monthOptions}
          hasAccountancyYear={hasAccountancyYear}
          setDisplayState={() => setDisplayState(false)}
          setSelected={e => {
            setDisplayState(false);
            setSelectedMonth(e);
          }}
        />
      )}
    </>
  );
};
