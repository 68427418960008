export const formatLargeNumber = number => {
  const formattedNumber = number
    ? number
        .toString()
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    : "";

  return formattedNumber + " DKK";
};

export const formatPercentile = number => {
  return number
    ? number
        .toString()
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    : "";
};
