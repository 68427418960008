import React from 'react';
import { createPortal } from 'react-dom';
import { ExitButton } from '../../ExitButton/ExitButton';
import { Icon } from '../../Icon/Icon';

import './UndoStockChangeModal.css';

export const UndoStockChangeModal = ({ respond, open }) => {
  const modalRoot = document.getElementById('modal-root');
  return open
    ? createPortal(
        <div className="UndoStockChangeModal__backdrop">
          <div className="UndoStockChangeModal">
            <div className="UndoStockChangeModal__close-button">
              <div className="UndoStockChangeModal__close-text" />
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="UndoStockChangeModal__title">
              <div className="UndoStockChangeModal__title-text">
                Er du sikker på at du vil fortryde?
              </div>
            </div>

            <div className="UndoStockChangeModal__info">
              <div className="UndoStockChangeModal__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="UndoStockChangeModal__info-text">
                Ved at ændre antallet, kan du påvirke værdien af lageret. Det
                kan have konsekvenser for dit regnskab.
              </div>
            </div>

            <div className="UndoStockChangeModal__buttons">
              <div
                className="UndoStockChangeModal__exit u-cursor-pointer"
                onClick={() => respond(false)}
              >
                Annullér
              </div>
              <div
                className="UndoStockChangeModal__confirm u-cursor-pointer"
                onClick={() => respond(true)}
              >
                Bekræft
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
