import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { type Client, createClient } from "graphql-ws";
import Cookies from "js-cookie";

const environment = process.env.NODE_ENV || "development";

let subClient: Client | null = null;

export const getSubscriptionClient = (): Client => {
  if (subClient !== null) {
    return subClient;
  }

  subClient = createClient({
    url:
      environment === "production"
        ? `wss://${window.location.host}${process.env.REACT_APP_SOCKET}`
        : process.env.REACT_APP_SOCKET ?? "",
    connectionParams: () => ({
      authorization: Cookies.get("account_id"),
    }),
    shouldRetry: () => true,
    retryAttempts: Infinity,
    isFatalConnectionProblem: () => false,
  });

  return subClient;
};

/**
 * Builds an Apollo Client with everything setup
 * @return {ApolloClient} ApolloClient
 */
export const setupApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API,
    credentials: "include",
  });

  const wsLink2 = new GraphQLWsLink(getSubscriptionClient());

  const link = split(
    // split based on operation type
    ({ query }) => {
      // @ts-ignore
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink2,
    httpLink
  );

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: environment === "development" ? true : false,
  });
};
