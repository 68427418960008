import { FC, useState } from "react";
import {
  AutomaticOrdering,
  InventoryItem,
  WatchedProduct as WatchedProductType,
  WatchedProductsDocument,
  useProductQuery,
  useUpdateInventoryItemMutation,
  useUpdateWatchedProductStatusMutation,
} from "../../../../Generated/graphql";
import { getImageWithSize } from "../../../../Utils/getImageWithSize";
import {
  getAmountPerUnit,
  getFirstImage,
  getName,
} from "../../../../Utils/getProductInformation";
import { noop } from "../../../../Utils/noop";
import { Icon } from "../../../Icon/Icon";
import { ShimmerBar } from "../../../ShimmerBar/ShimmerBar";
import { WatchedActionModal } from "../WatchedActionModal/WatchedActionModal";
import { WatchedProductConfirmIgnore } from "../WatchedProductConfirmIgnore/WatchedProductConfirmIgnore";
import "./WatchedProduct.css";

type Props = {
  item: WatchedProductType;
  onExitCall: (arg: "changed") => void;
  inventoryItem?: InventoryItem;
};

export const WatchedProduct: FC<Props> = ({
  item,
  onExitCall,
  inventoryItem,
}) => {
  const [actionModalShown, setActiveModalShown] = useState(false);
  const [ignoreModalShown, setIgnoreModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateStatus] = useUpdateWatchedProductStatusMutation({
    refetchQueries: [{ query: WatchedProductsDocument }],
  });
  const [updateProduct] = useUpdateInventoryItemMutation();
  const { data, error, loading } = useProductQuery({
    variables: { productNumber: item.productNumber },
  });

  if (error) {
    return <div>Der skete en fejl, prøv igen</div>;
  }

  if (loading) {
    // Form a shimmer loader with an image and 2 lines
    return <WatchedProductLoader />;
  }

  // If inventory item is unknown, just render nothing
  if (!inventoryItem) {
    return null;
  }

  const image = getFirstImage(data);
  const title = getName(data);
  const amountPerUnit = getAmountPerUnit(data);
  const unit = amountPerUnit > 1 ? "ks." : "stk.";

  const handleActionCall = (
    response:
      | "click-ignore"
      | "ignore"
      | "open"
      | "close"
      | "close-confirm"
      | "confirm-automatic"
      | "confirm-change"
  ) => {
    switch (response) {
      case "click-ignore":
        // Display confirmation modal
        setIgnoreModalShown(true);
        break;

      case "ignore":
        setIgnoreModalShown(false);
        return updateStatus({
          variables: {
            updateWatchedProductStatusId: item.id,
            response,
          },
        });

      case "open":
        setActiveModalShown(true);
        break;

      case "close":
        setActiveModalShown(false);
        break;

      case "close-confirm":
        // Handles both the confim, and ignore modals
        setIgnoreModalShown(false);
        break;

      case "confirm-automatic":
        // Change status to automatic ordering = active & change status on the watched product
        setIsLoading(true);
        updateProduct({
          variables: {
            productNumber: item.productNumber!,
            automaticOrdering: AutomaticOrdering.Active,
          },
        })
          .then(() => {
            return updateStatus({
              variables: {
                updateWatchedProductStatusId: item.id,
                response: "confirm",
              },
            });
          })
          .then(() => {
            setIsLoading(false);
            onExitCall("changed");
          });
        break;

      case "confirm-change":
        // Change min and max for the product, and change status on the watched product
        setIsLoading(true);
        updateProduct({
          variables: {
            productNumber: item.productNumber!,
            minimum: item.recommendedMin,
            maximum: item.recommendedMax,
          },
        })
          .then(() => {
            return updateStatus({
              variables: {
                updateWatchedProductStatusId: item.id,
                response: "confirm",
              },
            });
          })
          .then(() => {
            setIsLoading(false);
            onExitCall("changed");
          });
        break;

      default:
        noop();
        break;
    }
  };

  return item.actionType === "should-change" ? (
    <div className="WatchedProduct">
      <div
        className="WatchedProduct__ActionTarget"
        onClick={() => handleActionCall("open")}
      />
      {isLoading && <div />}
      <WatchedProductConfirmIgnore
        open={ignoreModalShown}
        respond={handleActionCall}
      />
      <WatchedActionModal
        open={actionModalShown}
        respond={handleActionCall}
        data={{
          name: title,
          prodNo: item.productNumber,
          image: image ? image : null,
          unit,
          current: {
            currentMin: inventoryItem.minimum,
            currentMax: inventoryItem.maximum,
          },
          recommended: {
            min: item.recommendedMin,
            max: item.recommendedMax,
          },
          timesOrdered: item.timesOrdered,
          actionType: item.actionType,
          isLoading,
        }}
      />
      <div className="WatchedProduct__image">
        {image ? (
          <img
            className="WatchedProduct__image-tag"
            alt="Fejl"
            src={getImageWithSize(image, "65x65")}
          />
        ) : (
          <Icon name="noImageAvail" width={65} />
        )}
      </div>
      <div className="WatchedProduct__texts">
        <div className="WatchedProduct__title">
          <div className="WatchedProduct__title-text">{title}</div>
        </div>
        <div className="WatchedProduct__text-content">
          Du mangler ofte denne vare og den hastebestilles.
        </div>
      </div>
      <div className="WatchedProduct__buttons">
        <div
          className="WatchedProduct__buttons--ignore"
          onClick={() => handleActionCall("click-ignore")}
        >
          <IgnoreButton />
        </div>
        <div
          className="WatchedProduct__buttons--action"
          onClick={() => handleActionCall("open")}
        >
          <ActionButton />
        </div>
      </div>
    </div>
  ) : item.actionType === "should-activate" ? (
    <div className="WatchedProduct">
      <div
        className="WatchedProduct__ActionTarget"
        onClick={() => handleActionCall("open")}
      />
      <WatchedActionModal
        open={actionModalShown}
        respond={handleActionCall}
        data={{
          name: title,
          prodNo: item.productNumber,
          image: image && image.url ? image.url : null,
          unit,
          current: {
            currentMin: inventoryItem.minimum,
            currentMax: inventoryItem.maximum,
          },
          recommended: {
            min: item.recommendedMin,
            max: item.recommendedMax,
          },
          timesOrdered: item.timesOrdered,
          actionType: item.actionType,
          isLoading,
        }}
      />
      <div className="WatchedProduct__image">
        <img
          className="WatchedProduct__image-tag"
          alt="Fejl"
          src={getImageWithSize(image, "65x65")}
        />
      </div>
      <div className="WatchedProduct__texts">
        <div className="WatchedProduct__title">
          <div className="WatchedProduct__title-text">{title}</div>
        </div>
        <div className="WatchedProduct__text-content">
          Varen er bestilt manuelt {item.timesOrdered} gange på de sidste 6
          måneder.
        </div>
      </div>
      <div className="WatchedProduct__buttons">
        <div
          className="WatchedProduct__buttons--ignore"
          onClick={() => handleActionCall("click-ignore")}
        >
          <IgnoreButton />
        </div>
        <div
          className="WatchedProduct__buttons--action"
          onClick={() => handleActionCall("open")}
        >
          <ActionButton />
        </div>
      </div>
    </div>
  ) : null;
};

const WatchedProductLoader = () => {
  return (
    <div className="WatchedProductLoader">
      <div className="WatchedProductLoader__image">
        <ShimmerBar theme="light" />
      </div>
      <div className="WatchedProductLoader__texts">
        <div className="WatchedProductLoader__text WatchedProductLoader__text-top">
          <ShimmerBar theme="light" />
        </div>
        <div className="WatchedProductLoader__text">
          <ShimmerBar theme="light" />
        </div>
      </div>
    </div>
  );
};

const IgnoreButton = () => {
  return (
    <div className="WatchedProduct__button WatchedProduct__buttons--ignore-button">
      <Icon name="closeBlue" width={20} />
    </div>
  );
};

const ActionButton = () => {
  return (
    <div className="WatchedProduct__button WatchedProduct__buttons--action-button">
      <Icon name={"arrowDown"} width={14} />
    </div>
  );
};
