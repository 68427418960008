import { get } from "lodash";
import { createPortal } from "react-dom";

import { ExitButton } from "../../ExitButton/ExitButton";
import { Icon } from "../../Icon/Icon";
import { PrintBarcode } from "../../PrintBarcode/PrintBarcode";
import "./NewitemPrintLabelModal.css";

export const NewitemPrintLabelModal = ({ respond, open, item }) => {
  const modalRoot = document.getElementById("modal-root");
  return open
    ? createPortal(
        <div className="NewitemPrintLabelModal__backdrop">
          <div className="NewitemPrintLabelModal">
            <div className="NewitemPrintLabelModal__close-button">
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="NewitemPrintLabelModal__title">
              <div className="NewitemPrintLabelModal__title-text">
                Varen er oprettet på lageret
              </div>
            </div>

            <div className="NewitemPrintLabelModal__info">
              <div className="ChangeInStockModal__info-icon">
                <Icon
                  name={
                    item.automaticOrderingState === "active"
                      ? "infoIcon"
                      : "notification"
                  }
                  width={25}
                />
              </div>
              {item.automaticOrderingState === "active" ? (
                <div className="NewitemPrintLabelModal__info-text">
                  Automatisk genbestilling er aktiveret. Lagerstyringen vil selv
                  genbestille denne vare for dig.
                </div>
              ) : (
                <div className="NewitemPrintLabelModal__info-warning">
                  Automatisk genbestilling er ikke aktiveret. Du skal selv sørge
                  for at bestille denne vare.
                </div>
              )}
            </div>

            <div className="NewitemPrintLabelModal__buttons">
              <div className="ChangeInStockModal__exit">
                <PrintBarcode
                  barcode={get(item, "barcode", " ")}
                  name={get(item, "name", " ")}
                  prodNo={item.prodNo}
                  description={get(item, "description", " ")}
                  optionalText="Print label"
                  iconPosition="left"
                />
              </div>
              <div
                className="ChangeInStockModal__confirm"
                onClick={() => respond(true)}
              >
                Færdig
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
