import { FC } from "react";

import "./SettingsMenuItem.css";

type Props = {
  label: string;
  click: () => void;
  active: boolean;
};

export const SettingsMenuItem: FC<Props> = ({ label, click, active }) => {
  return (
    <div className="SettingsMenuItem" onClick={click}>
      {active && <div className="SettingsMenuItem__active" />}
      <div className="SettingsMenuItem__title">
        <div
          className="SettingsMenuItem__title-text"
          style={active ? { color: "#4092D7" } : { color: "#0C2C52" }}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
