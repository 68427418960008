import classNames from "classnames";
import { clamp } from "lodash";
import { FC, PropsWithChildren, useState } from "react";
import { animated } from "react-spring/renderprops.cjs";
import {
  AutomaticOrdering,
  OrderNewItemInput,
  useProductsQuery,
} from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import { ExitButton } from "../ExitButton/ExitButton";
import "./AddNewItems.css";
import { AddNewItemsItem } from "./AddNewItemsItem/AddNewItemsItem";

interface IAddNewItemsProps {
  items: OrderNewItemInput[];
  orderId: string;
  close: (formattedNewItems: OrderNewItemInput[]) => void;
  style: any;
}

export const AddNewItems = ({ items, close, style }: IAddNewItemsProps) => {
  const [newStock, setNewStock] = useState<OrderNewItemInput[]>(items);
  const { data, error, loading, refetch } = useProductsQuery({
    variables: {
      productNumbers: items.map((item) => item.productNumber),
    },
  });

  const [isAddingItems, setIsAddingItems] = useState(false);

  const onChangeAmountHandler = (
    prodNo: string,
    value: number,
    name: "minimum" | "maximum"
  ) => {
    const mappedItems = newStock.map((item) => {
      if (item.productNumber !== prodNo) {
        return item;
      }

      // Have "Maximum" follow the minimum at least
      if (name === "minimum" && item.maximum < value) {
        return {
          ...item,
          minimum: clamp(value, 1, Infinity),
          maximum: clamp(value, 1, Infinity),
        };
      }

      return {
        ...item,
        [name]: clamp(value, 1, Infinity),
      };
    });

    setNewStock(mappedItems);
  };

  const changeOrderState = (prodNo: string) => {
    const mappedItems = newStock.map((item) => {
      if (item.productNumber === prodNo) {
        if (item.automaticOrder === AutomaticOrdering.Active) {
          return {
            ...item,
            automaticOrder: AutomaticOrdering.Deactivated,
          };
        } else {
          return {
            ...item,
            automaticOrder: AutomaticOrdering.Active,
          };
        }
      } else {
        return item;
      }
    });

    setNewStock(mappedItems);
  };

  const addItemToInventory = async () => {
    setIsAddingItems(true);
    close(newStock);
  };

  return (
    <animated.div className="AddNewItems" style={style}>
      <div className="AddNewItems__box">
        {isAddingItems && loading && (
          <div className="AddNewItems__loading">
            <BoxLoader text="Tilføjer varer..." />
          </div>
        )}
        <div className="AddNewItems__header">
          <div className="AddNewItems__exit">
            <ExitButton click={() => close([])} />
          </div>
          <div className="AddNewItems__title">Indstil dine nye varer</div>
        </div>
        {error && (
          <div className="AddNewItems__Item">
            <p>Der skete en fejl</p>
            <p
              onClick={() =>
                refetch({
                  productNumbers: items.map((item) => item.productNumber),
                })
              }
            >
              Prøv igen
            </p>
          </div>
        )}
        {!loading && data && (
          <div className="AddNewItems__content">
            <div className="AddNewItems__scannedItems">
              <div className="AddNewItems__static-area">
                <div></div>
                <div className="AddNewItems__minimum AddNewItems__static-text">
                  Minimumsbeholdning
                </div>
                <div className="AddNewItems__wanted AddNewItems__static-text">
                  Ønsket beholdning
                </div>
                <div className="AddNewItems__autoreorder AddNewItems__static-text">
                  Genbestilling
                </div>
                <div className="AddNewItems__label AddNewItems__static-text">
                  Label
                </div>
              </div>
              <div className="AddNewItems__items">
                {newStock.map((item) => (
                  <AddNewItemsItem
                    item={item}
                    product={data?.products?.find(
                      (product) => product.prodNo === item.productNumber
                    )}
                    key={item.productNumber}
                    change={onChangeAmountHandler}
                    changeOrderState={changeOrderState}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="AddNewItems__actions">
          <AddItemsActionButton onClick={() => close([])} theme="Cancel">
            Fortryd
          </AddItemsActionButton>
          <AddItemsActionButton onClick={addItemToInventory} theme="Action">
            Modtag varer
          </AddItemsActionButton>
        </div>
      </div>
    </animated.div>
  );
};

type AddItemsActionButtonProps = {
  onClick: () => void;
  theme: "Cancel" | "Action";
};

const AddItemsActionButton: FC<PropsWithChildren<
  AddItemsActionButtonProps
>> = ({ onClick, children, theme }) => (
  <button
    className={classNames({
      "AddItemsActionButton--Cancel": theme === "Cancel",
      "AddItemsActionButton--Action": theme === "Action",
    })}
    onClick={onClick}
  >
    {children}
  </button>
);
