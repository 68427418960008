import React from 'react';
import { createPortal } from 'react-dom';

import './ChangeInStockModal.css';
import { ExitButton } from '../ExitButton/ExitButton';
import { Icon } from '../Icon/Icon';

export const ChangeInStockModal = ({ respond, open }) => {
  const modalRoot = document.getElementById('modal-root');
  return open
    ? createPortal(
        <div className="ChangeInStockModal__backdrop">
          <div className="ChangeInStockModal">
            <div className="ChangeInStockModal__close-button">
              <div className="ChangeInStockModal__close-text">Fortryd</div>
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="ChangeInStockModal__title">
              <div className="ChangeInStockModal__title-text">
                Er du sikker på at du vil ændre antallet?
              </div>
            </div>

            <div className="ChangeInStockModal__info">
              <div className="ChangeInStockModal__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="ChangeInStockModal__info-text">
                Ved at ændre antallet, kan du påvirke værdien af lageret. Det
                kan have konsekvenser for dit regnskab.
              </div>
            </div>

            <div className="ChangeInStockModal__buttons">
              <div
                className="ChangeInStockModal__exit"
                onClick={() => respond(false)}
              >
                Fortryd
              </div>
              <div
                className="ChangeInStockModal__confirm"
                onClick={() => respond(true)}
              >
                Bekræft ændring
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
