import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { Subscribers } from "../../Containers/Subscribers/Subscribers";
import { OfflineContext } from "../../Context/OfflineContext";
import { useUserQuery } from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";

type Props = PropsWithChildren<{
  accessLevel?: string; // "SUPERADMIN" | "DISTRIBUTOR" | "ADMIN" | "USER";
}>;

export const Authorized: FC<Props> = ({ children, accessLevel }) => {
  const { isOffline } = useContext(OfflineContext);
  const { data, error, loading, startPolling, stopPolling } = useUserQuery({
    fetchPolicy: "network-only",
    pollInterval: 60 * 1000 * 2,
  });

  useEffect(() => {
    if (isOffline) {
      stopPolling();
    } else {
      startPolling(60 * 1000 * 2);
    }
  }, [isOffline, startPolling, stopPolling]);

  if (error) {
    const codes = error?.graphQLErrors?.map((error) => error?.extensions?.code);

    if (codes.includes("UNAUTHORIZED")) {
      window.location.href = process.env.REACT_APP_SSO_BASE_URL!;
      return null;
    }
  }

  if (loading) {
    return <BoxLoader />;
  }

  // We can have no data for multiple reasons - If we do, then render as normal, but without subscriptions

  if (!data) {
    return <>{children}</>;
  }

  if (data.user.role === accessLevel || !data.user.accountId) {
    return <>{children}</>;
  }

  return <Subscribers accountId={data.user.accountId}>{children}</Subscribers>;
};
