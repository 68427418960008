import { useAccountSettingsQuery } from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import "./SettingsCompany.css";
import { SettingsCompanyContent } from "./SettingsCompanyContent/SettingsCompanyContent";

export const SettingsCompany = () => {
  const { data, loading, error } = useAccountSettingsQuery();

  if (loading) return <BoxLoader />;
  if (error) return <div>{error.message}</div>;
  return (
    <div className="SettingsAccess">
      <div className="SettingsAccess__title">
        <div className="SettingsAccess__title-text">Ordre håndtering</div>
      </div>
      <div className="Settings__dividerbar" />
      <div className="SettingsAccess__content">
        <SettingsCompanyContent settings={data.accountSettings} />
      </div>
    </div>
  );
};
