import React from "react";
import { animated } from "react-spring/renderprops.cjs";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import "./RemoveManuaAddItem.css";

export const RemoveManuaAddItem = ({ response, item }) => {
  return (
    <animated.div className="RemoveOrderItem__shadow">
      <animated.div className="RemoveOrderItem">
        <div className="RemoveOrderItem__exit" onClick={() => response(false)}>
          <div className="RemoveOrderItem__text">Fortryd</div>
          <div className="RemoveOrderItem__icon">
            <Icon name="closeBlue" width={20} height={20} />
          </div>
        </div>
        <div className="RemoveOrderItem__title">
          Vil du fjerne varen fra ordren?
        </div>
        {/* <div className="RemoveOrderItem__sub-title">
          <div className="DeleteStockModal__info-icon">
            <Icon name="infoIcon" width={25} />
          </div>
          {!item.manualAdd && (
            <div>
              {' '}
              Udskudte varer vil blive tilføjet til din næste automatiske
              bestilling{' '}
            </div>
          )}
        </div> */}
        <div className="RemoveOrderItem__actions">
          <div className="RemoveOrderItem__cancel">
            <Button
              buttonStyle="cancel"
              labelStyle="Button__cancel-text"
              click={() => response(false)}
            >
              {" "}
              Fortryd{" "}
            </Button>
          </div>
          <div className="RemoveManuaAddItem__confirm">
            <Button
              buttonStyle="confirm"
              labelStyle="Button__confirm-text"
              click={() => response(true)}
            >
              {" "}
              Fjern vare{" "}
            </Button>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
};
