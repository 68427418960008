import moment, { Moment } from "moment";
import { useState } from "react";
// @ts-ignore
import { Calendar } from "react-calendar-component";
import {
  OrderHistoryItem,
  useOrderHistoryQuery,
} from "../../../Generated/graphql";

import { formatApolloErrorMessages } from "../../../GraphQL/formatApolloErrorMessages";
import { BoxLoader } from "../../BoxLoader/BoxLoader";
import { IndicatorBox } from "../IndicatorBox/IndicatorBox";
import "./DashboardOrderCalendar.css";
import { DashboardOrderCalendarMenu } from "./DashboardOrderCalendarMenu/DashboardOrderCalendarMenu";

export const DashboardOrderCalendar = () => {
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment());

  const { data, error, loading } = useOrderHistoryQuery({
    variables: {
      month: parseInt(moment(selectedMonth).format("MM")),
      year: parseInt(moment(selectedMonth).format("YYYY")),
    },
    fetchPolicy: "network-only",
  });

  const currentMonth = moment().format("MMMM");
  return (
    <div className="DashboardOrderCalendar">
      <div className="DashboardOrderCalendar__title">
        <div className="DashboardOrderCalendar__title-text">Ordreflow</div>
      </div>

      <DashboardOrderCalendarMenu
        selectedMonth={selectedMonth}
        currentMonth={currentMonth}
        setSelectedMonth={(e) => {
          setSelectedMonth(e);
        }}
      />

      {loading && <FetchingOrders />}

      <div className="DashboardOrderCalendar__calendar">
        {error && <CalendarError error={error} />}
        <div className="DashboardOrderCalendar__calendar-static">
          {DAYSCONSTANTS.map((day, index) => (
            <div className="DashboardOrderCalendar__day" key={day + index}>
              {day}
            </div>
          ))}
        </div>
        <Calendar
          date={selectedMonth}
          renderDay={(props: any) => {
            return (
              <DayCell
                key={props.day.format("YYYY-MM-DD")}
                {...props}
                orderDays={!loading && !error && data ? data.orderHistory : []}
              />
            );
          }}
        />
      </div>

      <div className="DashboardCalendar__information">
        <CalendarInfo />
      </div>
    </div>
  );
};

const DAYSCONSTANTS = ["M", "T", "O", "T", "F", "L", "S"];

type DayCellProps = {
  day: Moment;
  classNames: string;
  orderDays: OrderHistoryItem[];
};

const DayCell = ({ day, classNames, orderDays = [] }: DayCellProps) => {
  const cellStyles = ["DayCell"];

  const isOrderDay = orderDays.find(
    (x) => moment(x.orderDate).format("L") === day.format("L")
  );

  const autoOrManual = getDateBackgroundColor(isOrderDay);

  if (classNames && classNames !== undefined) {
    cellStyles.push(classNames);
  }

  return (
    <div className={cellStyles.join(" ")}>
      <div
        className="Daycell__content"
        style={{ backgroundColor: autoOrManual }}
      >
        <div
          className="Daycell__day"
          style={isOrderDay ? { fontWeight: 700, color: "#fff" } : {}}
        >
          {day.format("DD")}
        </div>
      </div>
    </div>
  );
};

const getDateBackgroundColor = (isOrderDay?: OrderHistoryItem) => {
  if (isOrderDay) {
    const formattedOrderDate = moment(isOrderDay.orderDate).format("L");
    const formattedAutomaticOrderDate = moment(
      isOrderDay.automaticOrderDate
    ).format("L");
    if (formattedAutomaticOrderDate === formattedOrderDate) {
      return "#68CEA2";
    } else {
      return "#EAC75A";
    }
  } else {
    return "transparent";
  }
};

const CalendarInfo = () => (
  <div className="CalendarInfo">
    <div className="CalendarInfo__info">
      <IndicatorBox color="#68CEA2" />
      <div className="CalendarInfo__info-text">Automatiske ordrer</div>
    </div>
    <div className="CalendarInfo__info">
      <IndicatorBox color="#EAC75A" />
      <div className="CalendarInfo__info-text">Manuelle ordrer</div>
    </div>
  </div>
);

const FetchingOrders = () => {
  return (
    <div className="FetchingOrders">
      <BoxLoader backgroundOpacity={0.85} loaderTheme="dark" />
    </div>
  );
};

const CalendarError = ({ error }: { error: Error }) => {
  const formattedError = formatApolloErrorMessages(error.message);

  return (
    <div className="CalendarError">
      <div className="CalendarError__title">{formattedError.type}</div>
      <div className="CalendarError__text">{formattedError.message}</div>
    </div>
  );
};
