import { compact, orderBy, uniq } from "lodash";
import { InventoryItem } from "../Generated/graphql";

export const groupItemsByCategory = (data: InventoryItem[]) => {
  // Groups items based on productData.productLevelB
  const categories = uniq(compact(data.map((item) => item.category || null)));

  return categories.map((cat) => {
    const itemsInCategory = data.filter((x) => x.category === cat);

    return {
      category: cat,
      items: orderBy(itemsInCategory, (x) => x.name),
    };
  });
};
