import { clamp } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { animated } from "react-spring/renderprops.cjs";
import {
  OrderItem,
  OrderItemInput,
  OrderItemStatus,
  useAccountSettingsQuery,
  useProductLazyQuery,
} from "../../Generated/graphql";
import { extractGTIN } from "../../Utils/extractGTIN";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { ScannerComponent } from "../Scanner/Scanner";
import "./ScanItemInModal.css";
import { ScanItemInModalAmountError } from "./ScanItemInModalAmountError/ScanItemInModalAmountError";
import { ScanItemInModalItem } from "./ScanItemInModalItem/ScanItemInModalItem";

interface IScanItemInModalProps {
  items: OrderItem[];
  orderNumber: string;
  toggleScan: () => void;
  addScannedItems: (items: OrderItemInput[], warningMessage?: string) => void;
  hideMe: () => void;
  open: boolean;
  style?: any;
}

export const ScanItemInModal = ({
  items,
  orderNumber,
  toggleScan,
  addScannedItems,
  hideMe,
  open,
  style = {},
}: IScanItemInModalProps) => {
  const [scannedItems, setScannedItems] = useState<
    (OrderItem & { isError: boolean })[]
  >([]);
  const [cameraActive, setCameraActive] = useState<boolean>(false);
  const [errors, setError] = useState<number>(0);
  const [
    showUnequalScannedErrorModal,
    setShowUnequalScannedErrorModal,
  ] = useState<boolean>(false);
  const { data: accountData } = useAccountSettingsQuery();
  const [getProduct] = useProductLazyQuery();

  const accountSettings = accountData?.accountSettings;

  useEffect(() => {
    const modal = document.querySelector(
      ".ScanItemInModal"
    ) as HTMLElement | null;
    if (modal) {
      modal.focus();
      modal.click();
    }
  });

  const modalRoot = document.getElementById("modal-root");

  const countTo = (): number => {
    let result = 0;
    items.forEach((item) => {
      return (result = result + (item?.amount ?? 0));
    });
    return result;
  };

  const countScanned = (): number => {
    let result = 0;
    scannedItems.forEach((item) => {
      return (result = result + item.taken);
    });
    return result;
  };

  const toggleCamera = () => {
    setCameraActive((oldState) => !oldState);
  };

  const addScannedItem = useCallback(
    async (barcode: string) => {
      // Is it an item we have on the order?
      const orderItem = items.find((item) => item.barcode === barcode);
      if (!orderItem) {
        const { data } = await getProduct({
          variables: {
            barcode,
          },
        });
        if (!data?.product.prodNo) {
          // Early escape - Thats not a product we know
          return;
        }
        setScannedItems((oldState) => [
          ...oldState,
          {
            id: oldState.length + 1 + "",
            productNumber: data.product.prodNo!,
            amount: 1,
            barcode: data?.product.productData.barcode,
            isError: true,
            name: data?.product.productData.name,
            price: 0,
            description: data?.product.productData.description,
            excluded: false,
            images: data?.product.customData.billeder,
            taken: 0,
            status: OrderItemStatus.Active,
            sorting: 0,
          },
        ]);

        setError((oldErrors) => oldErrors + 1);
        setCameraActive(false);
        return;
      }

      // Check if we have scanned this item before.
      const isScannedItem = scannedItems.find(
        (x) => x.productNumber === orderItem.productNumber
      );

      // If we have already scanned it, and count is active, count up
      if (isScannedItem) {
        if (accountSettings?.countOnScan) {
          const mapped = scannedItems.map((item) => {
            if (item.productNumber === isScannedItem.productNumber) {
              return {
                ...item,
                taken: item.taken + 1,
              };
            } else {
              return item;
            }
          });
          setScannedItems(mapped);
        } else {
          const mapped = scannedItems.map((item) => {
            if (item.productNumber === isScannedItem.productNumber) {
              return { ...item, taken: item.amount };
            } else {
              return item;
            }
          });
          setScannedItems(mapped);
        }
        // Otherwise we add it to the list of scannedItems (with corrected scan count)
      } else {
        const takenAmount = accountSettings?.countOnScan
          ? orderItem.taken > 0
            ? orderItem.taken ?? 0 + 1
            : 1
          : orderItem.amount;

        setScannedItems((oldState) => [
          {
            id: orderItem.id,
            amount: orderItem.amount,
            barcode: orderItem.barcode,
            taken: takenAmount,
            description: orderItem.description,
            excluded: orderItem.excluded,
            isError: false,
            name: orderItem.name,
            price: orderItem.price,
            productNumber: orderItem.productNumber,
            images: orderItem.images,
            status: OrderItemStatus.Active,
            sorting: orderItem.sorting,
          },
          ...oldState,
        ]);
        setCameraActive(false);
      }
    },
    [accountSettings?.countOnScan, getProduct, items, scannedItems]
  );

  const removeItem = (productNumber: string) => {
    const withoutItem = scannedItems.filter(
      (item) => item.productNumber !== productNumber
    );

    setScannedItems(withoutItem);
    setError((oldErrors) => clamp(oldErrors - 1, 0, Infinity));
  };

  const checkScannedAmount = () => {
    // Check if amount added is equal to amount ordered
    const itemsWithUnequalScannedAndOrdered = scannedItems.filter(
      (x) => x.amount !== x.taken
    );

    if (itemsWithUnequalScannedAndOrdered.length > 0) {
      setShowUnequalScannedErrorModal(true);
    } else {
      updateUserOrderItems();
      hideMe();
    }
  };

  // If response is true, they accept their own failures, and add the incorrect amount to their inventory
  const handleErrorAmountCorrected = async (response: boolean) => {
    if (response) {
      // Keep going, adding the items to inventory
      setShowUnequalScannedErrorModal(false);
      updateUserOrderItems("Advarsel accepteret på ordre:");
    } else {
      setShowUnequalScannedErrorModal(false);
    }
  };

  const onChangeAmountHandler = (
    productNumber: string,
    amount: number | string
  ) => {
    const amountAsInt = +amount;
    const mappedItems = scannedItems.map((item) => {
      if (item.productNumber === productNumber) {
        return {
          ...item,
          taken: isNaN(amountAsInt) ? 1 : clamp(amountAsInt, 1, 9999),
        };
      } else {
        return item;
      }
    });

    setScannedItems(mappedItems);
  };

  const updateUserOrderItems = (warningMessage?: string) => {
    addScannedItems(
      scannedItems.map((item) => ({
        id: item.id,
        productNumber: item.productNumber,
        excluded: item.excluded,
        price: item.price,
        taken: item.taken,
      })),
      warningMessage
    );
    hideMe();
  };

  useEffect(() => {
    let scannerInput = "";
    let regex = new RegExp("([A-Z0-9])");

    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event &&
        event.key !== "Shift" &&
        event.key !== "Enter" &&
        regex.test(event.key)
      ) {
        scannerInput = scannerInput.concat(event.key);
      } else if (event && event.key === "Enter") {

        const extractedGTIN = extractGTIN(scannerInput)
        // Do whatever you need to with the barcode
        addScannedItem(extractedGTIN || scannerInput);
        scannerInput = "";
      }
    };
    document.addEventListener("keydown", handleKeyPress);

    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [addScannedItem]);

  return open && modalRoot
    ? createPortal(
        <animated.div className="ScanItemInModal" style={style}>
          <animated.div className="ScanItemInModal__box">
            {showUnequalScannedErrorModal && (
              <ScanItemInModalAmountError
                allScannedItems={scannedItems}
                onChangeAmountHandler={onChangeAmountHandler}
                handleErrorAmountCorrected={handleErrorAmountCorrected}
                toggleClose={toggleScan}
                removeItem={removeItem}
              />
            )}
            {!showUnequalScannedErrorModal && (
              <ScanItemInModalContent
                addScannedItem={addScannedItem}
                cameraActive={cameraActive}
                checkScannedAmount={checkScannedAmount}
                countScanned={countScanned()}
                countTo={countTo()}
                errors={errors}
                hideMe={hideMe}
                items={items}
                onChangeAmountHandler={onChangeAmountHandler}
                orderNumber={orderNumber}
                removeItem={removeItem}
                toggle={toggleScan}
                scanOutCount={accountSettings?.countOnScan ?? false}
                scannedItems={scannedItems}
                toggleCamera={toggleCamera}
              />
            )}
          </animated.div>
        </animated.div>,
        modalRoot
      )
    : null;
};

interface IScanItemInModalContentProps {
  scanOutCount: boolean;
  errors: number;
  orderNumber: string;
  toggle: () => void;
  cameraActive: boolean;
  addScannedItem: (barcode: string) => void;
  toggleCamera: () => void;
  scannedItems: (OrderItem & { isError: boolean })[];
  countScanned: number;
  countTo: number;
  onChangeAmountHandler: (
    productNumber: string,
    amount: number | string
  ) => void;
  removeItem: (productNumber: string) => void;
  hideMe: () => void;
  checkScannedAmount: () => void;
  items: OrderItem[];
}

const ScanItemInModalContent = ({
  scanOutCount,
  errors,
  orderNumber,
  toggle,
  cameraActive,
  addScannedItem,
  toggleCamera,
  scannedItems,
  countScanned,
  countTo,
  onChangeAmountHandler,
  removeItem,
  hideMe,
  checkScannedAmount,
  items,
}: IScanItemInModalContentProps) => {
  return (
    <>
      <div className="ScanItemInModal__header">
        <div className="ScanItemInModal__exit">
          <ExitButton click={() => toggle()} />
        </div>
        <div className="ScanItemInModal__title">Scan ordre {orderNumber}</div>
      </div>
      <div className="ScanItemInModal__content">
        {cameraActive ? (
          <ScannerComponent
            returnItem={addScannedItem}
            toggleCamera={toggleCamera}
          />
        ) : null}
        <div className="ScanItemInModal__scannedItems">
          <div className="ScanItemInModal__static-area">
            <div className="ScanItemInModal__amount-ordered ScanItemInModal__static-text">
              Bestilt antal
            </div>
            <div className="ScanItemInModal__amount-received ScanItemInModal__static-text">
              Modtaget antal
            </div>
          </div>
          <div className="ScanItemInModal__items">
            {scannedItems.length > 0
              ? scannedItems.map((item) => (
                  <ScanItemInModalItem
                    item={item}
                    key={item.productNumber}
                    change={onChangeAmountHandler}
                    removeItem={removeItem}
                  />
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="ScanItemInModal__information-area">
        <div
          className="ScanItemInModal__camera u-cursor-pointer"
          onClick={() => toggleCamera()}
        >
          <div className="ScanItemInModal__camera-icon">
            <Icon name="scanActive" width={15} />
          </div>
          <div className="ScanItemInModal__camera-text">
            {cameraActive ? "Stop kamera" : "Start kamera"}
          </div>
        </div>

        <div className="ScanItemInModal__scannedAmount">
          {scanOutCount ? (
            <div className="ScanItemInModal__scannedAmount-text">
              {countScanned} af {countTo} varer scannet på ordre
            </div>
          ) : (
            <div className="ScanItemInModal__scannedAmount-text">
              {scannedItems.filter((el) => el.isError !== true).length} af{" "}
              {items.length} varer scannet på ordre
            </div>
          )}
          <div className="ScanItemInModal__scannedAmount-bar">
            <ProgressBar
              current={scannedItems.filter((el) => el.isError !== true).length}
              min={0}
              max={items.length}
            />
          </div>
        </div>
      </div>

      <div className="ScanItemInModal__actions u-cursor-pointer">
        <div className="ScanItemInModal__cancel-button" onClick={hideMe}>
          {" "}
          Fortryd{" "}
        </div>
        {errors === 0 ? (
          <div
            className="ScanItemInModal__receive-now"
            onClick={checkScannedAmount}
          >
            {`Modtag varer`}
          </div>
        ) : (
          <div className="ScanItemInModal__error-button"> {errors} fejl </div>
        )}
      </div>
    </>
  );
};
