import React from 'react';

import './IndicatorBox.css';

export const IndicatorBox = ({ color }) => (
  <span
    className="IndicatorBox__color-indicator"
    style={{ backgroundColor: color }}
  />
);
