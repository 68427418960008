import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./universal-styles.css";

// require("dotenv").config();
const rootEl = document.getElementById("root");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

ReactDOM.render(<App />, rootEl);

if (module.hot) {
  // Whenever a new version of App.js is available
  module.hot.accept("./App", function() {
    // Require the new version and render it instead
    const NextApp = require("./App").default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
