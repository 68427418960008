import { FC } from "react";
import { createPortal } from "react-dom";

import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import "./ScanItemNotFoundModal.css";

type Props = {
  respond: (answer: boolean) => void;
  open: boolean;
};

export const ScanItemNotFoundModal: FC<Props> = ({ respond, open }) => {
  const modalRoot = document.getElementById("modal-root");
  if (!modalRoot) {
    return null;
  }

  return open
    ? createPortal(
        <div className="ScanItemNotFoundModal__backdrop">
          <div className="ScanItemNotFoundModal">
            <div className="ScanItemNotFoundModal__close-button">
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="ScanItemNotFoundModal__title">
              <div className="ScanItemNotFoundModal__title-text">
                Varen kunne ikke findes.
              </div>
            </div>

            <div className="ScanItemNotFoundModal__info">
              <div className="ScanItemNotFoundModal__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="ScanItemNotFoundModal__info-text">
                Varen kunne ikke findes på dit lager, <br /> eller Baden-Jensens
                produktkatalog. <br /> Du kan eventuelt oprette varen manuelt på
                dit lager.
              </div>
            </div>

            <div className="ScanItemNotFoundModal__buttons">
              <div
                className="ScanItemNotFoundModal__confirm"
                onClick={() => respond(false)}
              >
                Ok
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
