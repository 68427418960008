import { FC, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  AccountsDocument,
  AccountSettings,
  AccountSettingsDocument,
  useAccountSettingsQuery,
  useUpdateAccountMutation,
} from "../../Generated/graphql";
import { noop } from "../../Utils/noop";
import { Button } from "../Button/Button";
import { Checkbox } from "../Checkbox/Checkbox";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../ListItem/ListItem";
import { ModalButton } from "../ModalButton/ModalButton";
import { TextInput } from "../TextInput/TextInput";
import "./SuperAdminEditAccount.css";

type Props = {
  accountData: AccountSettings;
  accountId: string;
};

const SuperAdminEditAccountContent: FC<Props> = ({
  accountData,
  accountId,
}) => {
  const history = useHistory();
  const [name, setName] = useState(accountData.accountName);
  const [cvr, setCvr] = useState("");
  const [hasLechler, setHasLecher] = useState(accountData.hasLechler);
  const [hasSpiesHecker, setHasSpiesHecker] = useState(
    accountData.hasSpiesHecker
  );
  const [hasStandox, setHasStandox] = useState(accountData.hasStandox);
  const [hasPercotop, setHasPercotop] = useState(accountData.hasPercotop);

  const [accountStatus, setAccountStatus] = useState(
    accountData.isActive ? "active" : "deactivated"
  );

  const [updateAccount] = useUpdateAccountMutation({
    update: (cache, { data }) => {
      const account = cache.readQuery<AccountSettings>({
        query: AccountSettingsDocument,
        variables: { userId: accountId },
      });

      if (!account) {
        return;
      }

      cache.writeQuery({
        query: AccountSettingsDocument,
        data: {
          accountSettings: { ...account, ...data?.updateAccount },
        },
        variables: { userId: accountId },
      });
    },
  });

  return (
    <div className="SuperAdminNewAccount">
      <div
        className="EditItem__close"
        onClick={() => history.push("/superadmin/accounts")}
      >
        <div className="EditItem__closeborder">
          <Icon name="closeBlue" width={20} height={20} />
        </div>
      </div>
      <div className="SuperAdminNewAccount__header">
        <div className="SuperAdminNewAccount__title">
          <div className="SuperAdminNewAccount__title-text">Rediger konto</div>
        </div>
      </div>

      <div className="SuperAdminNewAccount__content">
        <div
          className="SuperAdminNewAccount__item"
          style={{ borderTop: "1px solid #a1b4c4" }}
        >
          <ListItem title="Firma">
            <TextInput
              name="name"
              textAlign="right"
              value={name}
              change={(e: any) => setName(e.target.value)}
            />
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Visma Kunde nr.">
            <TextInput
              name="id"
              textAlign="right"
              value={accountData.id}
              change={noop}
            />
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="CVR">
            <TextInput
              name="cvr"
              textAlign="right"
              value={cvr}
              change={(e: any) => setCvr(e.target.value)}
            />
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Status">
            <div
              className="ScanItemInModalItem__autoReoder"
              style={{ justifySelf: "flex-end", marginLeft: "auto" }}
            >
              <ModalButton
                buttonStyle="toggleButton"
                iconName={
                  accountStatus === "active"
                    ? "checkmarkWhite"
                    : "orderingDisabled"
                }
                labelStyle="toggleLabel"
                autoOrderState={accountStatus}
                width={19}
                click={() =>
                  setAccountStatus(
                    accountStatus === "deactivated" ? "active" : "deactivated"
                  )
                }
              />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__seperator"></div>
        <div className="SuperAdminNewAccount__item">
          <div className="SuperAdminNewAccount__item--title">
            Produkt systemer
          </div>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Lechler">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasLecher} checked={hasLechler} />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Spies Hecker">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasSpiesHecker} checked={hasSpiesHecker} />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Standox">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasStandox} checked={hasStandox} />
            </div>
          </ListItem>
        </div>
        <div className="SuperAdminNewAccount__item">
          <ListItem title="Percotop">
            <div className="SuperAdminNewAccount__checkbox">
              <Checkbox change={setHasPercotop} checked={hasPercotop} />
            </div>
          </ListItem>
        </div>
      </div>

      <div className="SuperAdminNewAccount__footer">
        <div className="SuperAdminNewAccount__button">
          <Button
            buttonStyle="newItem"
            width="130px"
            click={() => {
              // We need a Superadmin type mutation here, to seperate things better
              updateAccount({
                variables: {
                  args: {
                    id: accountId,
                    accountName: name,
                    cvr: cvr,
                    hasLechler: hasLechler,
                    hasSpiesHecker: hasSpiesHecker,
                    hasStandox: hasStandox,
                    hasPercotop: hasPercotop,
                    status: accountStatus === "active" ? true : false,
                  },
                },
                refetchQueries: [
                  {
                    query: AccountSettingsDocument,
                    variables: {
                      accoundSettingsId: accountId,
                    },
                  },
                  {
                    query: AccountsDocument,
                  },
                ],
              }).then(() => {
                history.push("/superadmin/accounts");
              });
            }}
          >
            <div>Gem ændringer</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SuperAdminEditAccount = () => {
  const match = useRouteMatch<{ accountId: string }>();
  const { data, error, loading } = useAccountSettingsQuery({
    variables: {
      accountSettingsId: match.params.accountId,
    },
    skip: !match.params.accountId,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <div />;
  if (error) return <div>{error.message}</div>;

  return (
    <SuperAdminEditAccountContent
      accountData={data!.accountSettings}
      accountId={data!.accountSettings.id}
    />
  );
};
