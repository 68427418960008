import Lightbox from "lightbox-react";
import { clamp, debounce, get } from "lodash";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import Tooltip from "react-tooltip-lite";
import {
  useRemoveItemFromCartMutation,
  useUpdateCartItemMutation,
} from "../../../Generated/graphql";
import { getImageWithSize } from "../../../Utils/getImageWithSize";
import { AutomaticAdd } from "../../AutomaticAdd/AutomaticAdd";
import { Icon } from "../../Icon/Icon";
import { NumberInput } from "../../NumberInput/NumberInput";
import { RemoveManuaAddItem } from "../../RemoveManuaAddItem/RemoveManuaAddItem";
import { RemoveOrderItem } from "../../RemoveOrderItem/RemoveOrderItem";
import "./OrderListItem.css";

interface IOrderListItemProps {
  windowWidth: number;
  orderItem: {
    amount: number;
    productNumber: string;
    manuallyAdded: boolean;
    name: string;
    images: { url: string; name: string }[];
  };
  orderId: string;
}

export const OrderListItem = ({
  windowWidth,
  orderItem,
  orderId,
}: IOrderListItemProps) => {
  const [itemCopy, setItemCopy] = useState(orderItem);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [deletingItem, setDeletingItem] = useState(false);

  const [updateCartItem] = useUpdateCartItemMutation();
  const [removeItemFromCart] = useRemoveItemFromCartMutation();

  const debouncedUpdateCartItem = debounce(updateCartItem, 1000, {
    trailing: true,
    leading: false,
  });

  // Keeps us up to date with the orderitem
  useEffect(() => {
    setItemCopy({ ...orderItem });
  }, [orderItem]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "amount") {
      setItemCopy({
        ...itemCopy,
        [name]: clamp(+value, 1, 1000),
      });
    } else {
      setItemCopy((oldCopy) => ({
        ...oldCopy,
        [name]: value,
      }));
    }
  };

  const addOneFunction = () => {
    setItemCopy((oldCopy) => ({
      ...oldCopy,
      amount: clamp(itemCopy.amount + 1, 1, 700),
    }));
    debouncedUpdateCartItem({
      variables: {
        item: { ...itemCopy, amount: clamp(itemCopy.amount + 1, 1, 700) },
        cartId: orderId,
      },
    });
  };

  const updateActiveOrderItem = () => {
    debouncedUpdateCartItem({
      variables: {
        item: itemCopy,
        cartId: orderId,
      },
    });
  };

  const minusOneFunction = () => {
    setItemCopy((oldCopy) => ({
      ...oldCopy,
      amount: clamp(itemCopy.amount - 1, 1, 700),
    }));
    debouncedUpdateCartItem({
      variables: {
        item: { ...itemCopy, amount: clamp(itemCopy.amount - 1, 1, 700) },
        cartId: orderId,
      },
    });
  };

  const handleDeleteResponse = (res: "remove" | "delay" | boolean) => {
    if (!res) {
      setDeletingItem(false);
      return;
    }
    removeItemFromCart({
      variables: {
        item: itemCopy,
        moveToNextCart: res === "delay",
      },
    }).then(() => {
      setDeletingItem(false);
    });
  };

  const allImages = useMemo(() => {
    return (
      itemCopy?.images &&
      itemCopy.images.map((image) => {
        return getImageWithSize(image.url, "800x800");
      })
    );
  }, [itemCopy]);

  return (
    <div className="OrderListItem">
      {showImage && allImages && allImages.length > 0 && (
        <Lightbox
          mainSrc={allImages[photoIndex]}
          nextSrc={allImages[(photoIndex + 1) % allImages.length]}
          prevSrc={
            allImages[(photoIndex + allImages.length - 1) % allImages.length]
          }
          onCloseRequest={() => setShowImage(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + allImages.length - 1) % allImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % allImages.length)
          }
        />
      )}
      {deletingItem && itemCopy.manuallyAdded && (
        <RemoveManuaAddItem item={itemCopy} response={handleDeleteResponse} />
      )}
      {deletingItem && !itemCopy.manuallyAdded && (
        <RemoveOrderItem item={itemCopy} response={handleDeleteResponse} />
      )}

      <div className="OrderListItem__content">
        {windowWidth > 1023 && (
          <Tooltip
            hoverDelay={100}
            direction="right"
            content={
              itemCopy.images[0] ? (
                <img
                  style={{ maxHeight: "200px", maxWidth: "200px" }}
                  src={getImageWithSize(itemCopy.images[0].url, "200x200")}
                  alt="Fejl..."
                />
              ) : (
                <Icon name="noImageAvail" width={200} />
              )
            }
          >
            <div className="OrderListItem__image">
              <Icon name="preview" width={20} />
              <div
                className="IntentoryListItem__hover-trigger"
                onClick={(e) => {
                  e.preventDefault();
                  setShowImage(true);
                }}
              />
            </div>
          </Tooltip>
        )}
        <div className="OrderListItem__id u-no-text-select">
          {itemCopy && itemCopy.productNumber}
        </div>
        <div className="OrderListItem__name u-no-text-select">
          <div
            className="OrderListItem__name-formatted"
            title={get(itemCopy, "name", " ")}
          >
            {get(itemCopy, "name", " ")}
          </div>
        </div>
        {windowWidth > 1023 && (
          <div
            className="OrderListItem__description u-no-text-select"
            title={get(itemCopy, "description", " ")}
          >
            {get(itemCopy, "description", " ")}
          </div>
        )}
        <div className="OrderListItem__delivery-extra u-no-text-select">
          {/* {item.extraDeliveryTime ? (
                <ExtraDeliveryTime windowWidth={windowWidth} />
              ) : null} */}
        </div>
        <div className="OrderListItem__added-how u-no-text-select">
          {itemCopy.manuallyAdded ? null : (
            <AutomaticAdd windowWidth={windowWidth} />
          )}
        </div>
        <div
          className="OrderListItem__delete u-cursor-pointer"
          onClick={() => setDeletingItem(true)}
        >
          {" "}
          <Icon name="trashcanTertiaryBlue" width={9} height={16} />{" "}
        </div>
        <div className="OrderListItem__amount">
          <NumberInput
            value={itemCopy.amount}
            unit={"stk"}
            name="amount"
            change={onChangeHandler}
            addOneFunction={() => addOneFunction()}
            minusOneFunction={() => minusOneFunction()}
            changeFormat={updateActiveOrderItem}
          />
        </div>
      </div>
    </div>
  );
};
