import { orderBy } from "lodash";
import moment from "moment";
import { FC, useState } from "react";
import Calendar from "react-calendar";
import { useHistory } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import {
  Holiday,
  HolidaysDocument,
  useAddHolidayMutation,
} from "../../Generated/graphql";
import { capitalizeString } from "../../Utils/capitalizeString";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../ListItem/ListItem";
import { TextInput } from "../TextInput/TextInput";
import "./SuperAdminNewHoliday.css";

export const SuperAdminNewHoliday = () => {
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState("");

  const history = useHistory();

  const [showDatepicker, setShowDatepicker] = useState(false);

  const [addNewHoliday] = useAddHolidayMutation({
    update: (cache, { data }) => {
      const oldData = cache.readQuery<{ holidays: Holiday[] }>({
        query: HolidaysDocument,
      });

      if (!data || !oldData) {
        return;
      }

      const newList = [data.addHoliday, ...oldData.holidays];

      cache.writeQuery({
        query: HolidaysDocument,
        data: {
          holidays: orderBy(newList, (x) => x.day, ["asc"]),
        },
      });
    },
  });

  const handleSetDate = (input: Date | Date[]) => {
    setShowDatepicker(!showDatepicker);
    if (Array.isArray(input)) {
      setDate(input[0]);
      return;
    }
    setDate(input);
  };

  return (
    <div className="SuperAdminNewHoliday">
      <div className="EditItem__close" onClick={() => history.goBack()}>
        <div className="EditItem__closeborder">
          <Icon name="closeBlue" width={20} height={20} />
        </div>
      </div>
      <div className="SuperAdminNewAccount__header">
        <div className="SuperAdminNewAccount__title">
          <div className="SuperAdminNewAccount__title-text">
            Opret helligdag
          </div>
        </div>
      </div>
      <div
        className="SuperAdminNewAccount__item"
        style={{ borderTop: "1px solid #a1b4c4" }}
      >
        <ListItem title="Dato">
          <Tooltip
            className="SuperAdminNewHoliday__value"
            useHover={false}
            isOpen={showDatepicker}
            content={<DateCalendar date={date} setDate={handleSetDate} />}
            arrow={true}
          >
            <div className="SuperAdminNewHoliday__value">
              <TextInput
                name="value"
                textAlign="right"
                value={moment(date).format("DD/MM/YYYY")}
                onClick={() => setShowDatepicker(!showDatepicker)}
              />
            </div>
          </Tooltip>
        </ListItem>
      </div>
      <div
        className="SuperAdminNewAccount__item"
        style={{ borderTop: "1px solid #a1b4c4" }}
      >
        <ListItem title="Navn">
          <TextInput
            name="name"
            textAlign="right"
            value={name}
            change={(e) => setName(e.target.value)}
          />
        </ListItem>
      </div>
      <div className="SuperAdminNewAccount__footer">
        <div className="SuperAdminNewAccount__button">
          <Button
            buttonStyle="newItem"
            width="157px"
            click={() => {
              return addNewHoliday({
                variables: {
                  day: moment(date)
                    .hour(12)
                    .format(),
                  holidayName: name,
                },
              }).then(() => history.goBack());
            }}
          >
            <div>Opret</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

type DateCalendarProps = {
  date: Date;
  setDate: (date: Date | Date[]) => void;
};

export const DateCalendar: FC<DateCalendarProps> = ({ date, setDate }) => (
  <Calendar
    value={date}
    onChange={(newDate) => {
      setDate(newDate);
    }}
    minDetail="month"
    minDate={new Date()}
    formatShortWeekday={(locale, day) => {
      return day
        .toLocaleDateString(locale, { weekday: "long" })
        .toUpperCase()
        .substring(0, 1);
    }}
    locale="da"
    tileContent={({ view, date }) => {
      return (
        <div className="ExportDatepicker__calendar-tile-highlighter">
          <div className="ExportDatepicker__calendar-tile-text">
            {view === "month" && date.getDate()}
            {view === "year" &&
              capitalizeString(
                new Intl.DateTimeFormat("da-DK", {
                  month: "long",
                }).format(date)
              )}
          </div>
        </div>
      );
    }}
    navigationLabel={({ label }) => {
      return capitalizeString(label);
    }}
    nextLabel={<CalendarNextLabel />}
    prevLabel={<CalendarPrevLabel />}
  />
);

const CalendarNextLabel = () => (
  <div className="CalendarNextLabel CalendarLabel">
    <Icon name="arrowDown" width={14} />
  </div>
);

const CalendarPrevLabel = () => (
  <div className="CalendarPrevLabel CalendarLabel">
    <Icon name="arrowDown" width={14} />
  </div>
);
