import { get } from "lodash";
import { useState } from "react";
import Tooltip from "react-tooltip-lite";
import { useAccountSettingsQuery } from "../../Generated/graphql";
import { ExportDatepicker } from "../ExportDatepicker/ExportDatepicker";
import { Icon } from "../Icon/Icon";
import "./ExportComponentWithDates.css";

export const ExportComponentWithDates = ({ withBorders }) => {
  const { data, error, loading } = useAccountSettingsQuery();
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [showPrintDatepicker, setShowPrintDatepicker] = useState(false);

  const borders = ["ExportComponent__export"];

  if (withBorders) {
    borders.push("ExportComponent__withBorders");
  }

  return (
    <div className="ExportComponentWithDates">
      <div className={borders.join(" ")}>
        <Tooltip
          useHover={false}
          isOpen={showDatepicker}
          content={
            <ExportDatepicker
              type="xlsx"
              types={['xlsx','pdf']}
              buttonText="Hent lageroversigt"
              buttonTexts={{'xlsx': 'Hent lageroversigt', 'pdf': 'Hent lageroversigt'}}
              setShow={setShowDatepicker}
              shown={showDatepicker}
              user={
                !error &&
                !loading &&
                get(data, "accountSettings.accountName", null)
              }
            />
          }
          arrow={true}
        >
          <div
            title="Hent lageroversigt"
            className="ExportComponentWithDates__icon u-cursor-pointer"
            onClick={() => {
              if (showPrintDatepicker) {
                setShowPrintDatepicker(false);
              }
              setShowDatepicker(!showDatepicker);
            }}
          >
            <Icon name="exportItems" width={16} />
          </div>
        </Tooltip>
        <Tooltip
          useHover={false}
          isOpen={showPrintDatepicker}
          content={
            <ExportDatepicker
              type="print"
              types={['print']}
              buttonText="Print lageroversigt"
              buttonTexts={{'print': 'Print lageroversigt'}}
              setShow={setShowPrintDatepicker}
              shown={showPrintDatepicker}
              user={!error && !loading && data.accountSettings.accountName}
            />
          }
          arrow={true}
        >
          <div
            title="Print lageroversigt"
            className="ExportComponentWithDates__icon u-cursor-pointer"
            onClick={() => {
              if (showDatepicker) {
                setShowDatepicker(false);
              }
              setShowPrintDatepicker(!showPrintDatepicker);
            }}
          >
            <Icon name="printItems" width={16} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
