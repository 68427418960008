import React from "react";
import { Treemap, ResponsiveContainer } from "recharts";

import "./DashboardTotalsTreeMap.css";
import { shortNames } from "../../../../Utils/shortNames";
import { DASHBOARD_COLORS } from "../../../../Containers/Dashboard/Dashboard";

export const DashboardTotalsTreeMap = ({ items }) => {
  return (
    <ResponsiveContainer width="90%" height="90%">
      <Treemap
        width={353}
        height={256}
        data={items}
        dataKey="value"
        aspectRatio={9 / 16}
        stroke="#fff"
        fill="#fff"
        overflow="hidden"
        isAnimationActive={false}
        content={<CustomizedContent colors={DASHBOARD_COLORS} />}
      />
    </ResponsiveContainer>
  );
};

const CustomizedContent = ({
  root,
  depth,
  x,
  y,
  width,
  height,
  index,
  payload,
  colors,
  rank,
  name
}) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        rx="4"
        style={{
          fill: colors[index]
        }}
      />
      {depth === 1 ? (
        <text
          x={x + 10}
          y={y + 20}
          textAnchor="start"
          fill="#fff"
          fontSize={11}
          fontWeight={300}
        >
          {width > 150 ? name : width < 50 ? "" : shortenName(name)}
        </text>
      ) : null}
    </g>
  );
};

const shortenName = input => {
  const foundShortVersion = shortNames.find(x => x.name === input);

  if (foundShortVersion && foundShortVersion.short) {
    return foundShortVersion.short;
  } else {
    return input;
  }
};
