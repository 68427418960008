import Lightbox from "lightbox-react";
import { useMemo, useState } from "react";
import Tooltip from "react-tooltip-lite";
import {
  OrderItemInput,
  OrderItemStatus,
  OrderItem as OrderItemType,
} from "../../Generated/graphql";
import { getImageWithSize } from "../../Utils/getImageWithSize";
import { Checkbox } from "../Checkbox/Checkbox";
import { Icon } from "../Icon/Icon";
import { SplitSelect } from "../SplitSelect/SplitSelect";
import "./OrderItem.css";
import { OrderItemContainer } from "./OrderItemContainer";

interface IOrderItemProps {
  item: OrderItemType;
  windowWidth: number;
  addItem: (items: OrderItemInput) => void;
  isSelected: boolean;
  removeItem: (productNumber: string) => void;
  selectItem: (productNumber: string) => void;
  changeItemAmount: (productNumber: string, amount: number) => void;
  changeExcludedState: (productNumber: string, excluded: boolean) => void;
}

export const OrderItem = ({
  item,
  addItem,
  removeItem,
  windowWidth,
  changeItemAmount,
  isSelected,
  selectItem,
  changeExcludedState,
}: IOrderItemProps) => {
  const [editing, setEditing] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const addOrderItemToInventory = (included: boolean) => {
    addItem({
      id: item.id,
      productNumber: item.productNumber,
      excluded: !included,
      price: item.price,
      taken: item.amount,
    });
  };

  const checkboxChangeHandler = () =>
    isSelected
      ? removeItem(item.productNumber)
      : selectItem(item.productNumber);

  const imageUrls = useMemo((): string[] => {
    if (!item.images) {
      return [];
    }

    return item.images.map((image) => image.url);
  }, [item.images]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  return (
    <OrderItemContainer status={item.status}>
      {showImage && imageUrls && imageUrls.length > 0 && (
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={
            imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]
          }
          onCloseRequest={() => setShowImage(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imageUrls.length - 1) % imageUrls.length
            )
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageUrls.length)}
        />
      )}
      <Tooltip
        className="OrderItem__item-view-tooltip"
        hoverDelay={100}
        direction="right"
        content={
          item?.images?.[0] ? (
            <img
              style={{ maxHeight: "200px", maxWidth: "200px" }}
              src={getImageWithSize(item.images[0].url, "200x200")}
              alt="Fejl..."
            />
          ) : (
            <Icon name="noImageAvail" width={200} />
          )
          // <div className="Tooltip__content">
          //   {" "}
          //   {navigator.userAgent.match(/iPad/i) && (
          //     <div style={{ position: "absolute", top: "6px", right: "6px" }}>
          //       <ExitButton text="" solidBack={true} />
          //     </div>
          //   )}
          //   {getImageWithSize(item, "customData.billeder[0]", "200x200") ? (
          //     <ImageTooltip imageUrl={item?.images?.[0].url} />
          //   ) : (
          //     <Icon name="noImageAvail" width={200} />
          //   )}
          // </div>
        }
      >
        <div className="OrderItem__item-view">
          <Icon name="preview" width={20} />
          <div
            className="IntentoryListItem__hover-trigger"
            onClick={(e) => {
              e.preventDefault();
              setShowImage(true);
            }}
          />
        </div>
      </Tooltip>
      <div className="OrderItem__item-id u-no-text-select">
        {item.productNumber}
      </div>
      <div className="OrderItem__item-name u-no-text-select">{item.name}</div>
      <div className="OrderItem__item-description u-no-text-select">
        {item.description}
      </div>
      <div className="OrderItem__item-amount u-no-text-select">
        {editing ? (
          <div className="OrderItem__amount-editing">
            <div className="OrderItem__units-absolute">{""}</div>
            <input
              className="OrderItem__amount-input"
              value={item.amount}
              onChange={(e) =>
                changeItemAmount(item.productNumber, +e.target.value)
              }
            />
            <button className="OrderItem__amount-confirm" onClick={toggleEdit}>
              OK
            </button>
          </div>
        ) : (
          <div className="OrderItem__amount-readonly u-cursor-default">
            <div className="OrderItem__amount-text">
              {item.taken > 0 ? item.taken : item.amount}
            </div>
            <div className="OrderItem__amount-units">stk.</div>
            {item.status === OrderItemStatus.Active && (
              <div
                className="OrderItem__amount__icon u-cursor-pointer"
                onClick={toggleEdit}
              >
                <Icon name="PencilEdit" width={14} height={16} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="OrderItem__item-addToInv">
        {item.excluded && item.status === OrderItemStatus.Completed ? (
          <div className="OrderItem__item-isAdded">
            <Icon name="addComplete" width={21} />
            <div className="OrderItem__item-isAdded__text">
              Undladt fra lager
            </div>
          </div>
        ) : item.status === OrderItemStatus.Completed ? (
          <div className="OrderItem__item-isAdded">
            <Icon name="addComplete" width={21} />
            <div className="OrderItem__item-isAdded__text">
              Tilføjet til lager
            </div>
          </div>
        ) : item.status === OrderItemStatus.Backorder ? (
          <div className="OrderItem__item-isAdded">
            <Icon name="orderTruck" width={21} />
            <div className="OrderItem__item-isAdded__text">Restordre</div>
          </div>
        ) : (
          <SplitSelect
            defaultAddState={!item.excluded}
            click={addOrderItemToInventory}
            onChange={(addState) =>
              changeExcludedState(item.productNumber, !addState)
            }
          />
        )}
      </div>
      <div className="OrderItem__item-checkbox">
        {item.status !== OrderItemStatus.Active ? null : (
          <Checkbox checked={isSelected} change={checkboxChangeHandler} />
        )}
      </div>
    </OrderItemContainer>
  );
};
