import { FC } from "react";
import { createPortal } from "react-dom";

import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import "./ScanNotInInventoryModal.css";

type Props = {
  open: boolean;
  respond: (answer: boolean) => void;
};

export const ScanNotInInventoryModal: FC<Props> = ({ open, respond }) => {
  const modalRoot = document.querySelector("#modal-root");
  if (!modalRoot) {
    return null;
  }

  return open
    ? createPortal(
        <div className="ScanNotInInventoryModal__backdrop">
          <div className="ScanNotInInventoryModal">
            <div className="ScanNotInInventoryModal__close-button">
              <div className="ScanNotInInventoryModal__close-text">Fortryd</div>
              <ExitButton click={() => respond(false)} />
            </div>
            <div className="ScanNotInInventoryModal__title">
              <div className="ScanNotInInventoryModal__title-text">
                Varen er ikke oprettet på dit lager
              </div>
            </div>

            <div className="ChangeInStockModal__info">
              <div className="ChangeInStockModal__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="ChangeInStockModal__info-text">
                Du skal først oprette varen på dit lager for at kunne scanne den
                ud.
              </div>
            </div>

            <div className="ChangeInStockModal__buttons">
              <div
                className="ChangeInStockModal__exit"
                onClick={() => respond(false)}
              >
                Fortryd
              </div>
              <div
                className="ChangeInStockModal__confirm"
                onClick={() => respond(true)}
              >
                Opret vare
              </div>
            </div>
          </div>
        </div>,
        modalRoot
      )
    : null;
};
