import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { clamp, debounce } from "lodash";
import { ChangeEvent, Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Transition } from "react-spring/renderprops.cjs";
import { toast } from "react-toastify";
import { Zoom } from "../../App";
import {
  AutomaticOrdering,
  InventoryItem,
  MutationAddInventoryItemArgs,
  Product,
  ProductDocument,
  useAddInventoryItemMutation,
  useInventoryQuery,
} from "../../Generated/graphql";
import { AddItemDetails } from "../AddItemDetails/AddItemDetails";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import { Button } from "../Button/Button";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import { ItemAddManually } from "../ItemAddManually/ItemAddManually";
import { ItemSearchBar } from "../ItemSearchBar/ItemSearchBar";
import "./NewItem.css";
import { NewItemHeader } from "./NewItemHeader/NewItemHeader";
import { NewitemPrintLabelModal } from "./NewitemPrintLabelModal/NewitemPrintLabelModal";

interface INewItemProps {
  history: RouteComponentProps["history"];
  match: RouteComponentProps<{ barcode: string }>["match"];
  location: RouteComponentProps["location"];
  client: ApolloClient<any>;
  inventory: InventoryItem[];
  addStock: (args: MutationAddInventoryItemArgs) => Promise<any>;
}

interface INewItemState {
  item: Partial<InventoryItem> | null;
  addManualTriggered: boolean;
  creating: boolean;
  showPrintLabelModal: boolean;
}

class NewItem extends Component<INewItemProps, INewItemState> {
  constructor(props: INewItemProps) {
    super(props);

    this.state = {
      addManualTriggered: false,
      creating: false,
      showPrintLabelModal: false,
      item: null,
    };

    this.debouncedGoBack = debounce(this.debouncedGoBack, 1000, {
      leading: true,
      trailing: false,
    });
  }

  async componentDidMount() {
    if (
      this.props.match.params &&
      this.props.match.params.barcode &&
      this.state &&
      !this.state.item
    ) {
      // Fetch product by barcode, and dump it into state if state is empty
      this.props.client
        .query({
          query: ProductDocument,
          variables: { barcode: this.props.match.params.barcode },
        })
        .then((queryResult) => {
          if (!queryResult.loading && !queryResult.error) {
            this.addItem(queryResult.data.product);
          }
        });
    }
  }

  newItemCreationHandler = () => {
    this.setState({
      addManualTriggered: !this.state.addManualTriggered,
    });
  };

  addItem = (product: Product) => {
    this.setState({
      item: {
        amount: 1,
        minimum: 1,
        maximum: 1,
        automaticOrderingState: AutomaticOrdering.Active,
        productNumber: product.prodNo!,
        name: product.productData.name,
        description: product.productData.description,
        unit: product.productData.unit,
        status: Number(product.productData.status.statusCode),
        barcode: product.productData.barcode,
        category: product.productData.productLevelB,
        amountPerUnit: product.productData.amountPerUnit,
      },
    });
  };

  backToSearch = () => {
    this.setState({ item: null, addManualTriggered: false });
  };

  toggleAutoOrdering = () => {
    if (this.state.item?.automaticOrderingState === AutomaticOrdering.Active) {
      this.setState((oldState) => ({
        ...oldState,
        item: {
          ...oldState.item,
          automaticOrderingState: AutomaticOrdering.Deactivated,
        },
      }));
    } else if (
      this.state.item?.automaticOrderingState === AutomaticOrdering.Deactivated
    ) {
      this.setState((oldState) => ({
        ...oldState,
        item: {
          ...oldState.item,
          automaticOrderingState: AutomaticOrdering.Active,
        },
      }));
    }
  };

  setValues = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    this.setState((oldState) => ({
      ...oldState,
      item: {
        ...oldState.item,
        [name]: e.target.valueAsNumber,
      },
    }));
  };

  onTextValueChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    if (["amount", "minimum", "maximum"].includes(name)) {
      const cutoff = name === "amount" ? 0 : 1;

      const value = clamp(parseInt(e.target.value), cutoff, 1000);

      this.setState(
        (oldState) => ({
          ...oldState,
          item: {
            ...oldState.item,
            [name]: value,
          },
        }),
        () => this.checkMaximumValueIfAboveMinimum()
      );
    } else {
      const value = e.target.value;
      this.setState(
        (oldState) => ({
          ...oldState,
          item: {
            ...oldState.item,
            [name]: value,
          },
        }),
        () => this.checkMaximumValueIfAboveMinimum()
      );
    }
  };

  addOneFunction = (name: string, value: number) => {
    this.setState(
      (oldState) => ({
        ...oldState,
        item: {
          ...oldState.item,
          [name]: value + 1,
        },
      }),
      () => this.checkMaximumValueIfAboveMinimum()
    );
  };

  minusOneFunction = (name: string, value: number) => {
    const cutoff = name === "amount" ? 0 : 1;
    this.setState(
      (oldState) => ({
        ...oldState,
        item: {
          ...oldState.item,
          [name]: clamp(value - 1, cutoff, 1000),
        },
      }),
      () => this.checkMaximumValueIfAboveMinimum()
    );
  };

  checkMaximumValueIfAboveMinimum = () => {
    const { item } = this.state;

    if (!item || !item.minimum || !item.maximum) {
      return;
    }

    if (item.minimum > item.maximum) {
      this.setState((oldState) => ({
        ...oldState,
        item: {
          ...oldState.item,
          maximum: item.minimum,
        },
      }));
    }
  };

  debouncedGoBack = () => {
    const backdrop = document.querySelector(".routing__overlay");
    if (!backdrop) {
      return;
    }

    backdrop.classList.add("u-no-pointer");
    this.props.history.goBack();
  };

  render() {
    const { client } = this.props;
    return (
      <div className="NewItem">
        <NewitemPrintLabelModal
          respond={() => {
            this.setState(
              {
                showPrintLabelModal: false,
              },
              () => {
                this.setState({
                  creating: false,
                });
                this.props.history.push("/inventory");
              }
            );
          }}
          item={{ ...this.state.item }}
          open={this.state.showPrintLabelModal}
        />
        <div className="NewItem__exit">
          <ExitButton click={() => this.debouncedGoBack()} />
        </div>
        <div className="NewItem__content">
          <NewItemHeader
            title={
              this.state.item?.productNumber
                ? this.state.item.name
                : "Opret vare"
            }
          />

          {!this.state.item && !this.state.addManualTriggered && (
            <ItemSearchBar
              addItem={(product) => this.addItem(product)}
              client={client}
              stockItems={this.props.inventory}
              makingNewItem={true}
            />
          )}

          {!this.state.item?.productNumber && !this.state.addManualTriggered && (
            <div
              className="NewItem__addManual u-cursor-pointer"
              onClick={() => this.newItemCreationHandler()}
            >
              <Icon name="addBlue" width={12} height={12} />
              <div className="NewItem__manual-text">Opret manuelt</div>
            </div>
          )}

          <Transition
            items={this.state.addManualTriggered}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
          >
            {(show) =>
              show &&
              ((props) => (
                <div style={props}>
                  <ItemAddManually resetToSearch={() => this.backToSearch()} />
                </div>
              ))
            }
          </Transition>
          {this.state.item?.productNumber && !this.state.addManualTriggered ? (
            <AddItemDetails
              product={this.state.item}
              resetToSearch={() => this.backToSearch()}
              setValues={(e: ChangeEvent<HTMLInputElement>) =>
                this.setValues(e)
              }
              onTextValueChangeHandler={this.onTextValueChangeHandler}
              addOneFunction={this.addOneFunction}
              minusOneFunction={this.minusOneFunction}
              toggleAutoOrdering={this.toggleAutoOrdering}
              isAdded={this.state.creating}
            />
          ) : null}
        </div>
        {this.state.item?.productNumber ? (
          <div className="NewItem__footer">
            <div className="NewItem__footer-changesSaved">
              <Button
                buttonStyle="newItem"
                width="107px"
                click={() => {
                  this.setState(
                    {
                      creating: true,
                    },
                    () => {
                      if (!this.state.item) {
                        return;
                      }

                      this.props
                        .addStock({
                          productNumber: this.state.item.productNumber!,
                          inStock: this.state.item.amount!,
                          automaticOrdering: this.state.item
                            .automaticOrderingState!,
                          maximum: this.state.item.maximum!,
                          minimum: this.state.item.minimum!,
                          action: "manual",
                          isCustom: false,
                        })
                        .then(() => {
                          toast("Produkt tilføjet", {
                            className: "u-toast-success",
                            progressClassName: "u-toast-success-bar",
                            transition: Zoom,
                          });
                          this.setState({
                            showPrintLabelModal: true,
                          });
                        })
                        .catch((err) => {
                          toast("Fejl ved tilføjelse", {
                            className: "u-toast-error",
                            progressClassName: "u-toast-success-bar",
                            transition: Zoom,
                          });
                          this.setState({
                            creating: false,
                          });
                        });
                    }
                  );
                }}
              >
                {this.state.creating ? (
                  <div className="NewItem__creating-button-spin">
                    <Icon name="buttonSpinner" width={12} />
                  </div>
                ) : (
                  <Icon name="add" width={12} />
                )}
                <div>{this.state.creating ? "Opretter" : "Opret vare"}</div>
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

interface INewItemWrapperProps {
  history: RouteComponentProps["history"];
  match: RouteComponentProps<{ barcode: string }>["match"];
  location: RouteComponentProps["location"];
}

const NewItemWrapper = ({ history, match, location }: INewItemWrapperProps) => {
  const { data, loading } = useInventoryQuery();
  const [addStock] = useAddInventoryItemMutation();

  if (loading) {
    return <BoxLoader />;
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <NewItem
          client={client}
          history={history}
          match={match}
          location={location}
          inventory={data?.inventory ?? []}
          addStock={(variables) =>
            addStock({
              variables,
            })
          }
        />
      )}
    </ApolloConsumer>
  );
};

const routed = withRouter(NewItemWrapper);

export { routed as NewItem };
