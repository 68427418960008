import React from 'react';

import './AutomaticOrderToggleButton.css';

export const AutomaticOrderToggleButton = ({ click, value }) => {
  return (
    <div className="AutomaticOrderToggleButton" onClick={click}>
      <div
        className="AutomaticOrderToggleButton__button"
        style={{
          backgroundColor: value ? '#68CEA2' : '#AA2525',
          opacity: value ? 1 : 0.8
        }}
      >
        <div
          className="AutomaticOrderToggleButton__icon-housing"
          style={{
            right: value ? '3px' : '24px'
          }}
        ></div>
      </div>
    </div>
  );
};
