import { get } from "lodash";
import Tooltip from "react-tooltip-lite";
import {
  Cart,
  CartItem,
  useMoveToActiveCartMutation,
  useProductQuery,
} from "../../Generated/graphql";
import { getImageWithSize } from "../../Utils/getImageWithSize";
import {
  getAmountPerUnit,
  getFirstImage,
} from "../../Utils/getProductInformation";
import { AutomaticAdd } from "../AutomaticAdd/AutomaticAdd";
import { ExitButton } from "../ExitButton/ExitButton";
import { Icon } from "../Icon/Icon";
import "./DelayedOrders.css";

interface IDelayedOrdersProps {
  order: Cart;
  windowWidth: number;
}

export const DelayedOrders = ({ order, windowWidth }: IDelayedOrdersProps) => {
  return (
    <div className="DelayedOrders">
      <div className="DelayedOrders__header">
        <div className="DelayedOrders__title">Udskudte bestillinger</div>
      </div>
      <div className="DelayedOrders__content">
        <DelayedOrder order={order} key={order.id} windowWidth={windowWidth} />
      </div>
    </div>
  );
};

interface IDelayedOrderProps {
  order: Cart;
  windowWidth: number;
}

const DelayedOrder = ({ order, windowWidth }: IDelayedOrderProps) => {
  return (
    <>
      {order.orderLines &&
        order.orderLines.map((orderLine) => (
          <DelayedOrderItem
            item={orderLine}
            key={orderLine.productNumber}
            orderId={order.id}
            windowWidth={windowWidth}
          />
        ))}
    </>
  );
};

interface IDelayedOrderItemProps {
  item: CartItem;
  orderId: string;
  windowWidth: number;
}

const DelayedOrderItem = ({
  item,
  orderId,
  windowWidth,
}: IDelayedOrderItemProps) => {
  const { error, loading, data } = useProductQuery({
    variables: { productNumber: item.productNumber },
  });

  const [moveToActiveCart] = useMoveToActiveCartMutation();

  if (error || loading) return null;

  const amountPerUnit = getAmountPerUnit(data);
  const unit = amountPerUnit > 1 ? "ks." : "stk";

  const image = getFirstImage(data);

  return (
    <div className="DelayedOrderItem">
      <div className="DelayedOrderItem__image">
        {windowWidth > 1023 && (
          <Tooltip
            className="OrderItem__item-view-tooltip"
            hoverDelay={100}
            direction="right"
            content={
              <div className="Tooltip__content">
                {" "}
                {navigator.userAgent.match(/iPad/i) && (
                  <div
                    style={{ position: "absolute", top: "6px", right: "6px" }}
                  >
                    <ExitButton text="" solidBack={true} />
                  </div>
                )}
                {image ? (
                  <img
                    style={{ maxHeight: "200px", maxWidth: "200px" }}
                    src={getImageWithSize(image, "200x200")}
                    alt="Fejl..."
                  />
                ) : (
                  <Icon name="noImageAvail" width={200} />
                )}
              </div>
            }
          >
            <div className="OrderItem__item-view">
              <Icon name="preview" width={20} />
              <div
                className="IntentoryListItem__hover-trigger"
                onClick={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="DelayedOrderItem__productnumber">
        {item.productNumber}
      </div>
      <div className="DelayedOrderItem__name">
        {get(data, "product.productData.name", " ")}
      </div>
      <div className="DelayedOrderItem__description">
        {get(data, "product.productData.description", " ")}
      </div>
      <div></div>
      <div className="DelayedOrderItem__addedBy">
        {item.manuallyAdded ? null : <AutomaticAdd windowWidth={windowWidth} />}
      </div>
      <div className="DelayedORderItem__amount">
        {item.amount} {unit}
      </div>
      <div
        className="DelayedOrderItem__readd"
        onClick={() =>
          moveToActiveCart({
            variables: {
              cartId: orderId,
              productNumber: item.productNumber,
            },
          })
        }
      >
        <Icon name="cartListview" width={20} />
        <div className="DelayedOrderItem__readd-text">
          Tilføj til bestilling
        </div>
      </div>
    </div>
  );
};
