import { useHistory } from "react-router-dom";
import { useHolidaysQuery } from "../../Generated/graphql";
import { Button } from "../Button/Button";
import "./SuperAdminHolidays.css";
import { SuperAdminHolidaysList } from "./SuperAdminHolidaysList/SuperAdminHolidaysList";

export const SuperAdminHolidays = () => {
  const { data, error, loading } = useHolidaysQuery();
  const history = useHistory();
  return (
    <div className="SuperAdminHolidays">
      <div className="SuperAdminHolidays__header">
        <div className="SuperAdminHolidays__title">
          <div className="SuperAdminHolidays__title-text">Helligdage</div>
        </div>
        <div className="Settings__dividerbar"></div>
        <div className="SuperAdmin__newholiday">
          <Button
            buttonStyle="newItem"
            click={() => {
              history.push("/superadmin/holidays/new");
            }}
          >
            Tilføj helligdag
          </Button>
        </div>
      </div>

      <div className="SuperAdminHolidays__content">
        {loading && <h1>Loading...</h1>}
        {error && <h1>Error... {error.message}</h1>}
        {data && (
          <div>
            <SuperAdminHolidaysList holidays={data?.holidays ?? []} />
          </div>
        )}
      </div>
    </div>
  );
};
