import { ChangeEvent, Component } from "react";
import Tooltip from "react-tooltip-lite";
import { AutomaticOrdering, InventoryItem } from "../../Generated/graphql";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../ListItem/ListItem";
import { ModalButton } from "../ModalButton/ModalButton";
import { NumberInput } from "../NumberInput/NumberInput";
import { PrintBarcode } from "../PrintBarcode/PrintBarcode";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { TextInput } from "../TextInput/TextInput";
import "./AddItemDetails.css";

interface IAddItemDetailsProps {
  product: Partial<InventoryItem>;
  resetToSearch: () => void;
  setValues: (e: ChangeEvent<HTMLInputElement>) => void;
  onTextValueChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  addOneFunction: (name: string, value: number) => void;
  minusOneFunction: (name: string, value: number) => void;
  toggleAutoOrdering: () => void;
  isAdded: boolean;
}

interface IAddItemDetailsState {
  isAdded: boolean;
}

export class AddItemDetails extends Component<
  IAddItemDetailsProps,
  IAddItemDetailsState
> {
  constructor(props: IAddItemDetailsProps) {
    super(props);

    this.state = {
      isAdded: false,
    };
  }
  componentDidUpdate = (props: IAddItemDetailsProps) => {
    if (this.state.isAdded !== props.isAdded && this.state.isAdded === false) {
      this.setState({ isAdded: true });
    }
  };

  render() {
    const {
      product,
      addOneFunction,
      minusOneFunction,
      onTextValueChangeHandler,
      resetToSearch,
      toggleAutoOrdering,
    } = this.props;
    const productStatus = product.status;
    const unit =
      product.amountPerUnit && product.amountPerUnit > 1
        ? "ks."
        : "stk." ?? "stk.";

    return (
      <div className="AddItemDetails">
        <div className="AddItemDetails__discontinued">
          {productStatus === 90 && (
            <div className="EditItem__discontinued">
              <div className="EditItem__discontinued__tag">
                <div className="EditItem__discontinued__tag-text">Udgået</div>
              </div>
              <Tooltip
                className="EditItem__discontinued--tooltip"
                hoverDelay={100}
                direction="below"
                content={
                  <div className="EditItem__discontinued--tooltip-content">
                    Varen er udgået hos leverandøren og kan ikke genbestilles.
                  </div>
                }
              >
                <Icon name="iconHelp" width={18} height={18} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="AddItemDetails__edit" onClick={() => resetToSearch()}>
          <Icon name="editBtn" width={25} />
          <div className="AddItemDetails__edit-text">Fortryd</div>
        </div>
        <div className="AddItemDetails__atrribute AddItemDetails__name">
          <ListItem title="Navn">
            <TextInput
              value={product.name}
              name="name"
              change={(e: ChangeEvent<HTMLInputElement>) =>
                onTextValueChangeHandler(e)
              }
              textAlign="right"
              readOnly
            />
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Varenummer">
            <TextInput
              value={product.productNumber}
              name="id"
              change={(e: ChangeEvent<HTMLInputElement>) =>
                onTextValueChangeHandler(e)
              }
              textAlign="right"
              readOnly
            />
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Beskrivelse">
            <TextInput
              value={product.description}
              name="description"
              change={(e: ChangeEvent<HTMLInputElement>) =>
                onTextValueChangeHandler(e)
              }
              textAlign="right"
              readOnly
            />
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Stregkode">
            <>
              <TextInput
                value={product.barcode ?? ""}
                name="barcode"
                change={(e: ChangeEvent<HTMLInputElement>) =>
                  onTextValueChangeHandler(e)
                }
                textAlign="right"
                readOnly
              />
              {product.barcode && (
                <div className="EditItem__print-barcode">
                  <PrintBarcode
                    barcode={product.barcode}
                    name={product.name!}
                    prodNo={product.productNumber!}
                    description={product.description!}
                  />
                </div>
              )}
            </>
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Kategori">
            <TextInput
              value={product.category}
              name="category"
              change={(e: ChangeEvent<HTMLInputElement>) =>
                onTextValueChangeHandler(e)
              }
              textAlign="right"
              readOnly
            />
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Aktuel Beholdning">
            <div className="AddItemDetails__currentStock">
              <div className="AddItemDetails__stock-bar">
                <ProgressBar
                  current={product.amount}
                  min={product.minimum}
                  max={product.maximum}
                />
              </div>
              <div className="AddItemDetails__numberInput">
                <NumberInput
                  value={product.amount!}
                  unit={unit}
                  name="inStock"
                  change={(e) => onTextValueChangeHandler(e)}
                  addOneFunction={() =>
                    addOneFunction("amount", product.amount!)
                  }
                  minusOneFunction={() =>
                    minusOneFunction("amount", product.amount!)
                  }
                />
              </div>
            </div>
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Automatisk genbestilling">
            <div className="AddItemDetails__auto-reorder u-cursor-pointer">
              <ModalButton
                buttonStyle="toggleButton"
                iconName={
                  product.automaticOrderingState === AutomaticOrdering.Active
                    ? "autoOrderActive"
                    : "orderingDisabled"
                }
                labelStyle="toggleLabel"
                autoOrderState={product.automaticOrderingState}
                width={19}
                click={() => toggleAutoOrdering()}
              />
            </div>
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Minimumsbeholdning">
            <div className="AddItemDetails__numberOnlyInput">
              <NumberInput
                value={product.minimum!}
                unit={unit}
                name="minimum"
                change={(e) => onTextValueChangeHandler(e)}
                addOneFunction={() =>
                  addOneFunction("minimum", product.minimum!)
                }
                minusOneFunction={() =>
                  minusOneFunction("minimum", product.minimum!)
                }
              />
            </div>
          </ListItem>
        </div>
        <div className="AddItemDetails__atrribute">
          <ListItem title="Ønsket beholdning">
            <div className="AddItemDetails__numberOnlyInput">
              <NumberInput
                value={product.maximum!}
                unit={unit}
                name="maximum"
                change={(e) => onTextValueChangeHandler(e)}
                addOneFunction={() =>
                  addOneFunction("maximum", product.maximum!)
                }
                minusOneFunction={() =>
                  minusOneFunction("maximum", product.maximum!)
                }
              />
            </div>
          </ListItem>
        </div>
      </div>
    );
  }
}
