import { noop } from "lodash";
import { ChangeEvent, FC } from "react";
import "./TextInput.css";

type Props = {
  value?: string;
  change?: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  disabled?: boolean;
  textAlign?: "left" | "right";
  readOnly?: boolean;
  placeholder?: string;
  onClick?: () => void;
};

export const TextInput: FC<Props> = ({
  value = "",
  change = noop,
  name,
  disabled = false,
  textAlign = "left",
  readOnly = false,
  placeholder = undefined,
  onClick = noop,
}) => {
  return (
    <div className="TextInput">
      {readOnly ? (
        <div className="TextInput__readOnly"> {value} </div>
      ) : (
        <input
          className="TextInput-input"
          style={{ textAlign: textAlign }}
          type="text"
          value={value}
          onChange={change}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onClick={onClick}
        />
      )}
    </div>
  );
};
