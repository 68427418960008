import React from 'react';

import './NotificationBar.css'
import { Icon } from '../Icon/Icon';

export const NotificationBar = ({ success = false, error = false, hideNotification }) => {

  const notificationStyles = ['NotificationBar'];

  if (success) {
    notificationStyles.push('NotificationBar__success')
  }

  if (error) {
    notificationStyles.push('NotificationBar__error')
  }

  return (
    <div className={notificationStyles.join(' ')}>
      <div className="NotificationBar__text">
        {success ? "Vare oprettet" : error ? "Fejl ved oprettelse" : null}
      </div>
      <div className="NotificationBar__icon" onClick={hideNotification}>
        <Icon name="close" width={14} />
      </div>
    </div>
  )
}