import { get } from "lodash";
import { useRef } from "react";
import { animated, useTransition } from "react-spring";
import { useProductQuery } from "../../../Generated/graphql";
import { getImageWithSize } from "../../../Utils/getImageWithSize";
import { ExitButton } from "../../ExitButton/ExitButton";
import { Icon } from "../../Icon/Icon";
import { OrderItemLoading } from "../../OrderItem/OrderItemLoading/OrderItemLoading";
import "./OrderDisplay.css";

type EventOrderLine = {
  amount: number;
  exluded: boolean;
  name: string;
  price: number;
  prodNo: string;
  taken: number;
};

type EventOrder = {
  id: string;
  order_lines: EventOrderLine[];
};

type Props = {
  order: EventOrder;
  show: boolean;
  set: (arg: boolean) => void;
};

export const OrderDisplay = ({ order, show, set }: Props) => {
  const ref = useRef(null);
  const transitions = useTransition(show, null, {
    from: { opacity: 0, transform: "translateX(700px)" },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: "translateX(700px)" },
  });

  const handleExitClick = (event: any) => {
    if (event.target && event.target.contains(ref.current)) {
      set(false);
    }
  };

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          className="OrderDisplayBackdrop"
          key={key}
          style={{ opacity: props.opacity }}
          onClick={handleExitClick}
          ref={ref}
        >
          <animated.div
            className="OrderDisplay"
            style={{ transform: props.transform }}
            onClick={() => {}}
          >
            <OrderDisplayOrder
              order={order}
              orderLines={
                typeof order.order_lines === "string"
                  ? JSON.parse(order.order_lines)
                  : order.order_lines
              }
              close={() => set(false)}
            />
          </animated.div>
        </animated.div>
      )
  );
};

const OrderDisplayOrder = ({
  order,
  orderLines,
  close,
}: {
  order: EventOrder;
  orderLines: EventOrderLine[];
  close: () => void;
}) => (
  <div className="OrderDisplayOrder">
    <div className="OrderDisplayOrder__exit">
      <ExitButton click={close} text="Luk" />
    </div>
    <div className="OrderDisplayOrder__header">
      <div className="OrderDisplayOrder__header--text">
        {`Ordrenummer ${order.id}`}
      </div>
    </div>
    <div className="OrderDisplayOrder__body">
      {orderLines.map((o) => (
        <OrderDisplayOrderLine item={o} key={o.prodNo} />
      ))}
    </div>
  </div>
);

const OrderDisplayOrderLine = ({ item }: { item: EventOrderLine }) => {
  // Fetch product info here
  const { data, error, loading } = useProductQuery({
    variables: { productNumber: item.prodNo },
  });
  if (loading) return <OrderItemLoading />;
  if (!loading && !error) {
    const unitDecider = get(data, "product.productData.amountPerUnit", 1);
    const unitAsText = unitDecider > 1 ? "ks." : "stk.";

    const image = get(data, "product.customData.billeder[0].url", null);

    const itemColor =
      item.taken &&
      item.taken > 0 &&
      (item.taken < item.amount || item.taken > item.amount)
        ? "#AA2525"
        : "#0C2C52";

    return (
      <div className="OrderDisplayOrderLine">
        <div className="OrderDisplayOrderLine__image">
          {image ? (
            <img
              className="OrderNewItem__image-tag"
              src={getImageWithSize(image, "65x65")}
              alt="Fejl"
            />
          ) : (
            <Icon name="noImageAvail" width={65} />
          )}
        </div>
        <div className="OrderDisplayOrderLine__information">
          <div
            className="OrderDisplayOrderLine__information--name"
            style={{
              color: itemColor,
            }}
          >
            {get(data, "product.productData.name", " ")}
          </div>
          <div
            className="OrderDisplayOrderLine__information--sub"
            style={{
              color: itemColor,
            }}
          >
            <div className="OrderDisplayOrderLine__information--sub--prodno">
              {get(data, "product.prodNo", " ")}
            </div>
            <div className="OrderDisplayOrderLine__information--sub--description">
              {get(data, "product.productData.description", " ")}
            </div>
          </div>
        </div>
        {item.taken || item.taken === 0 ? (
          <div
            className="OrderDisplayOrderLine__amount"
            style={{
              color: itemColor,
            }}
          >
            {`Modtaget ${item.taken} af ${item.amount} ${unitAsText}`}
          </div>
        ) : (
          <div className="OrderDisplayOrderLine__amount">
            {item.amount} {unitAsText}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
