import React from "react";

import "./DashboardNotEnoughDataToCalculate.css";

export const DashboardNotEnoughDataToCalculate = ({ type = "normal" }) => {
  return (
    <div className="DashboardNotEnoughDataToCalculate">
      <div className="DashboardNotEnoughDataToCalculate__title">
        {type === "normal" && (
          <div className="DashboardNotEnoughDataToCalculate__title-text">
            Ikke tilstrækkeligt data for perioden
          </div>
        )}
        {type === "error" && (
          <div className="DashboardNotEnoughDataToCalculate__title-text">
            Der skete en fejl
          </div>
        )}
      </div>
      <div className="DashboardNotEnoughDataToCalculate__sub">
        {type === "normal" && (
          <div className="DashboardNotEnoughDataToCalculate__sub-text">
            Vælg en anden periode
          </div>
        )}
        {type === "error" && (
          <div className="DashboardNotEnoughDataToCalculate__sub-text">
            Prøv igen
          </div>
        )}
      </div>
    </div>
  );
};
