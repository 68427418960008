import { useState } from "react";
import { createPortal } from "react-dom";
import { animated } from "react-spring/renderprops.cjs";

import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import "./OrderNowModal.css";

export const OrderNowModal = ({ normalSendDate, response, open, ordering }) => {
  const [clicked, setClicked] = useState(false);
  const modalRoot = document.getElementById("modal-root");
  return open
    ? createPortal(
        <animated.div className="OrderNowModal__shadow">
          <animated.div className="OrderNowModal">
            <div
              className="OrderNowModal__exit"
              onClick={() => response(false)}
            >
              <div className="OrderNowModal__text">Fortryd</div>
              <div className="OrderNowModal__icon">
                <Icon name="closeBlue" width={20} height={20} />
              </div>
            </div>
            <div className="OrderNowModal__title">
              Er du sikker på at du vil afgive denne ordre nu?
            </div>
            <div className="OrderNowModal__sub-title">
              Ordren ville normalt blive afsendt {normalSendDate}
            </div>
            <div className="OrderNowModal__actions">
              <div className="OrderNowModal__cancel">
                <Button
                  buttonStyle="cancel"
                  labelStyle="Button__cancel-text"
                  click={() => response(false)}
                >
                  {" "}
                  Fortryd{" "}
                </Button>
              </div>
              <div
                className="OrderNowModal__confirm"
                style={clicked ? { cursor: "disabled" } : {}}
              >
                <Button
                  buttonStyle="confirm"
                  labelStyle="Button__confirm-text"
                  click={() => {
                    if (!ordering) {
                      setClicked(true);
                      response(true);
                    }
                  }}
                >
                  {!ordering ? "Bestil nu" : "Bestiller"}
                </Button>
              </div>
            </div>
          </animated.div>
        </animated.div>,
        modalRoot
      )
    : null;
};
