import React from 'react';

import './NoFoundItems.css'

export const NoFoundItems = () => {
  return (
    <div className="NoFoundItems">
      <div className="NoFoundItems__error-msg">
        Vi fandt ingen produkter der matcher din søgning
      </div>
    </div>
  )
}