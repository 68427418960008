import React from 'react';

import './OrderItemLoading.css';
import { ShimmerBar } from '../../ShimmerBar/ShimmerBar';

export const OrderItemLoading = ({ theme = 'dark' }) => {
  return (
    <div className="OrderItemLoading">
      <div className="OrderItemLoading__bar">
        <ShimmerBar theme={theme} />
      </div>
    </div>
  );
};
