import React from "react";

import "./DashboardFiveItemList.css";
import { Icon } from "../../Icon/Icon";
import { DASHBOARD_COLORS } from "../../../Containers/Dashboard/Dashboard";
import {
  formatLargeNumber,
  formatPercentile
} from "../../../Utils/formatLargeNumber";
import { IndicatorBox } from "../IndicatorBox/IndicatorBox";

export const DashboardFiveItemList = ({ items }) => (
  <div className="DashboardFiveItemList">
    {items.map((item, index) => (
      <DashboardFiveItemListItem item={item} key={item.id} index={index} />
    ))}
  </div>
);

const DashboardFiveItemListItem = ({ item, index }) => {
  const changeEffect = item.percentChange > 0 ? "negative" : "positive";
  const icon =
    changeEffect === "positive" ? "tendencyPositive" : "tendencyNegative";
  return (
    <div className="DashboardFiveItemListItem">
      <IndicatorBox color={DASHBOARD_COLORS[index]} />
      <div className="DashboardFiveItemListItem__content">
        <div className="DashboardFiveItemListItem__title">
          {index + 1}. {item.category}
        </div>
        <div className="DashboardFiveItemListItem__values">
          <div className="DashboardFiveItemListItem__values-value">
            {item.value ? formatLargeNumber(item.value) : "0,00 DKK"}
          </div>
          <div
            className={`DashboardFiveItemListItem__values-change DashboardFiveItemListItem__values-change-${changeEffect}`}
          >
            {item.percentChange !== 0 &&
              item.percentChange !== "NaN" &&
              item.percentChange !== "Infinity" && (
                <>
                  <div className="DashboardFiveItemListItem__values-icon">
                    <Icon name={icon} width={10} />
                  </div>

                  <div className="DashboardFiveItemListItem__values-change-value">
                    {item.percentChange > 0 ? "+" : ""}
                    {formatPercentile(item.percentChange || 0)}%
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
