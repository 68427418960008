import React from "react";
import { createPortal } from "react-dom";

import "./WatchedProductConfirmIgnore.css";
import { ExitButton } from "../../../ExitButton/ExitButton";
import { Icon } from "../../../Icon/Icon";

export const WatchedProductConfirmIgnore = ({ open, respond }) => {
  const secondaryModal = document.querySelector("#secondary-modal");

  return open
    ? createPortal(
        <div className="WatchedProductConfirmIgnore__backdrop">
          <div className="WatchedProductConfirmIgnore">
            <div className="WatchedProductConfirmIgnore__close-button">
              <div className="WatchedProductConfirmIgnore__close-text">Luk</div>
              <ExitButton click={() => respond("close-confirm")} />
            </div>
            <div className="WatchedProductConfirmIgnore__title">
              <div className="WatchedProductConfirmIgnore__title-text">
                Er du sikker på at du vil ignorére anbefalingen?
              </div>
            </div>

            <div className="WatchedProductConfirmIgnore__info">
              <div className="WatchedProductConfirmIgnore__info-icon">
                <Icon name="infoIcon" width={25} />
              </div>
              <div className="WatchedProductConfirmIgnore__info-text">
                Du vil ikke se denne anbefaling igen
              </div>
            </div>

            <div className="WatchedProductConfirmIgnore__buttons">
              <div
                className="WatchedProductConfirmIgnore__exit"
                onClick={() => respond("close-confirm")}
              >
                <div className="WatchedProductConfirmIgnore__confirm-text">
                  Fortryd
                </div>
              </div>
              <div
                className="WatchedProductConfirmIgnore__confirm"
                onClick={() => respond("ignore")}
              >
                <div className="WatchedProductConfirmIgnore__confirm-text">
                  Ignorér
                </div>
              </div>
            </div>
          </div>
        </div>,
        secondaryModal
      )
    : null;
};
