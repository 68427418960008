import React from 'react';

import './DeleteItem.css';
import { Icon } from '../../Icon/Icon';

export const DeleteItem = ({ name, description, id, remove, regret }) => {
  return (
    <div className="DeleteItem">
      <div className="DeleteItem__delete-product" onClick={regret}>
        <div className="DeleteItem__delete-ring">
          <Icon name="trashcanTertiaryBlue" width={14} />
        </div>
      </div>
      <div className="DeleteItem__title">
        Er du sikker på at du vil annullere scanningen?
      </div>
      <div className="DeleteItem__item">
        <div className="DeleteItem__name"> {name} </div>
        <div className="DeleteItem__description"> {description} </div>
        <div className="DeleteItem__id"> Varenr. {id} </div>
      </div>
      <div className="DeleteItem__actions">
        <div className="DeleteItem__revert u-cursor-pointer" onClick={regret}>
          <div className="DeleteItem__revert-text">Fortryd</div>
        </div>
        <div
          className="DeleteItem__delete-now u-cursor-pointer"
          onClick={remove}
        >
          <Icon name="trashcanWhite" width={14} />
          <div className="DeleteItem__delete-now-text">Ja, slet scanningen</div>
        </div>
      </div>
    </div>
  );
};
