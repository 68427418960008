import React from 'react';

import './ToastCloseButton.css';
import { Icon } from '../Icon/Icon';

export const ToastCloseButton = () => {
  return (
    <div className="ToastCloseButton">
      <Icon name="close" width={20} />
    </div>
  );
};
