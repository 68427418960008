import React from "react";
import { noop } from "../../Utils/noop";
import "./Button.css";

export const Button = ({
  children,
  buttonStyle = "",
  labelStyle = "",
  click = noop,
  width = "100%",
  inactive = false,
}) => {
  const buttonStyles = ["Button", "u-cursor-pointer"];
  const buttonLabelStyle = ["Button__text", "u-no-text-select"];

  if (buttonStyles) {
    buttonStyles.push("Button__" + buttonStyle);
  }

  if (inactive) {
    buttonStyles.push("Button__inactive-hover");
  }

  if (labelStyle) {
    buttonLabelStyle.push(labelStyle);
  }

  return (
    <div
      className={buttonStyles.join(" ")}
      onClick={inactive ? () => noop() : () => click()}
      style={{ width: width }}
    >
      <div className={buttonLabelStyle.join(" ")}>{children}</div>
    </div>
  );
};
