import { FC } from "react";
import {
  InventoryValue,
  useInventoryIntakeQuery,
  useInventoryOutgoingQuery,
  useInventoryValueQuery,
} from "../../../Generated/graphql";
import { Icon } from "../../Icon/Icon";
import { DashboardCountUpCurrency } from "../DashboardCountUpCurrency/DashboardCountUpCurrency";
import "./DashboardInventoryValueNumbers.css";

type Props = {
  start?: string;
  end?: string;
};

export const DashboardInventoryValueNumbers: FC<Props> = ({ start, end }) => {
  const { data } = useInventoryValueQuery({
    variables: { start, end },
  });

  return (
    <div className="DashboardInventoryValueNumbers">
      <div className="DashboardInventoryValueNumbers__top">
        <ValueBlock inventoryValue={data?.inventoryValue} color="#8DBFE9" />
      </div>
      <DividerBar />
      <div className="DashboardInventoryValueNumbers__bottom">
        <div className="DashboardInventoryValueNumbers__addition">
          <IncommingTotal color="#68CEA2" />
        </div>
        <div className="DashboardInventoryValueNumbers__usage">
          <OutgoingTotal color="#EAC75A" />
        </div>
      </div>
    </div>
  );
};

const DividerBar = () => <div className="DividerBar"></div>;

const IdentifierBox = ({ color }: { color: string }) => (
  <div className="IdentifierBox" style={{ backgroundColor: color }} />
);

const IncommingTotal = ({
  month,
  color,
}: {
  month?: number;
  color: string;
}) => {
  const { data, error, loading } = useInventoryIntakeQuery({
    variables: { month },
  });

  const hasValue =
    data?.inventoryIntake.changePercent &&
    data?.inventoryIntake.changePercent > 0
      ? true
      : false;

  return (
    <div className="DashboardInventoryValueNumbers__value">
      <div className="DashboardInventoryValueNumbers__value-container DashboardInventoryValueNumbers__value-container-offset">
        <div className="DashboardInventoryValueNumbers__values">
          <div
            className="DashboardInventoryValueNumbers__value-number--small"
            style={{ color }}
          >
            <DashboardCountUpCurrency
              value={data?.inventoryIntake.currentValue ?? 0}
            />
          </div>
          <div className="DashboardInventoryValueNumbers__value-block">
            <IdentifierBox color={color} />
          </div>
        </div>

        <div className="DashboardInventoryValueNumbers__change">
          {hasValue && (
            <>
              <div className="DashboardInventoryValueNumbers__change-icon DashboardInventoryIntakeNumbers__change-icon">
                <Icon
                  name={
                    !loading && !error
                      ? getInverseArrowIcon(
                          data?.inventoryIntake.changeDirection
                        )
                      : ""
                  }
                  width={10}
                />
              </div>
              <div
                className={`DashboardInventoryValueNumbers__change-value DashboardInventoryIntakeNumbers__change-value DashboardInventoryIntakeNumbers__change-value--${data
                  ?.inventoryIntake.changeDirection ?? ""}`}
              >
                {!loading && !error
                  ? data?.inventoryIntake.changeDirection === "positive"
                    ? "+"
                    : ""
                  : ""}
                {data?.inventoryIntake.changePercent
                  ? data.inventoryIntake.changePercent.toFixed(2) + "%"
                  : ""}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="DashboardInventoryValueNumbers__value-label DashboardInventoryValueNumbers__value-label--small">
        Tilgang, aktuel
      </div>
    </div>
  );
};

const OutgoingTotal = ({ month = null, color }: any) => {
  const { data, error, loading } = useInventoryOutgoingQuery({
    variables: { month },
  });

  const hasValue =
    data?.inventoryOutgoing.changePercent &&
    data.inventoryOutgoing.changePercent > 0
      ? true
      : false;

  return (
    <div className="DashboardInventoryValueNumbers__value">
      <div className="DashboardInventoryValueNumbers__value-container">
        <div className="DashboardInventoryValueNumbers__values">
          <div
            className="DashboardInventoryValueNumbers__value-number--small"
            style={{ color }}
          >
            {data?.inventoryOutgoing.currentValue &&
              data?.inventoryOutgoing.currentValue > 0 && (
                <div className="DashboardInventoryValueNumbers__negative">
                  -
                </div>
              )}
            <DashboardCountUpCurrency
              value={data?.inventoryOutgoing.currentValue ?? 0}
              isNegative={true}
            />
          </div>
          <div className="DashboardInventoryValueNumbers__value-block">
            <IdentifierBox color={color} />
          </div>
        </div>
        <div className="DashboardInventoryValueNumbers__change">
          {hasValue && (
            <>
              <div
                className={`DashboardInventoryValueNumbers__change-icon DashboardInventoryValueNumbers__change-icon--${
                  !loading && !error
                    ? data?.inventoryOutgoing.changeDirection
                    : ""
                }`}
              >
                <Icon
                  name={
                    !loading && !error
                      ? getInverseArrowIcon(
                          data?.inventoryOutgoing.changeDirection
                        )
                      : ""
                  }
                  width={10}
                />
              </div>
              <div
                className={`DashboardInventoryValueNumbers__change-value DashboardInventoryOutgoingNumbers__change-value--${
                  !loading && !error
                    ? data?.inventoryOutgoing.changeDirection
                    : ""
                }`}
              >
                {!loading && !error
                  ? data?.inventoryOutgoing.changeDirection === "positive"
                    ? "+"
                    : ""
                  : ""}
                {data?.inventoryOutgoing.changePercent
                  ? data.inventoryOutgoing.changePercent.toFixed(2) + "%"
                  : " "}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="DashboardInventoryValueNumbers__value-label DashboardInventoryValueNumbers__value-label--small">
        Afgang, aktuel
      </div>
    </div>
  );
};

const getArrowIcon = (condition: any) => {
  switch (condition) {
    case "negative":
      return "tendencyNegative";
    case "positive":
      return "tendencyPositive";
    default:
      return "";
  }
};
const getInverseArrowIcon = (condition: any) => {
  switch (condition) {
    case "negative":
      return "tendencyPositive";
    case "positive":
      return "tendencyNegative";
    default:
      return "";
  }
};

const ValueBlock = ({
  inventoryValue,
  color,
}: {
  inventoryValue?: InventoryValue;
  color: string;
}) => {
  const hasValue =
    inventoryValue?.changePercent && inventoryValue?.changePercent > 0
      ? true
      : false;

  return (
    <div className="DashboardInventoryValueNumbers__value">
      <div className="DashboardInventoryValueNumbers__value-container">
        <div className="DashboardInventoryValueNumbers__values">
          <div className="DashboardInventoryValueNumbers__value-number">
            <DashboardCountUpCurrency
              value={inventoryValue?.currentValue ?? 0}
            />
          </div>

          <div className="DashboardInventoryValueNumbers__value-block">
            <IdentifierBox color={color} />
          </div>
        </div>
        <div className="DashboardInventoryValueNumbers__change">
          {hasValue && (
            <>
              <div
                className={`DashboardInventoryValueNumbers__change-icon DashboardInventoryValueNumbers__change-value--${inventoryValue?.changeDirection ??
                  ""}`}
              >
                <Icon
                  name={getArrowIcon(inventoryValue?.changeDirection) ?? ""}
                  width={10}
                />
              </div>
              <div
                className={`DashboardInventoryValueNumbers__change-value DashboardInventoryValueNumbers__change-value--${inventoryValue?.changeDirection ??
                  ""}`}
              >
                {inventoryValue?.changeDirection === "positive"
                  ? "-"
                  : "+" ?? ""}
                {inventoryValue?.changePercent
                  ? inventoryValue?.changePercent.toFixed(2) + "%"
                  : ""}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="DashboardInventoryValueNumbers__value-label">
        Lagerværdi, aktuel
      </div>
    </div>
  );
};
