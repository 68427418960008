import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Profile } from "../../../Components/Profile/Profile";
import { ProfileDropdown } from "../../../Components/ProfileDowndrop/ProfileDropdown";
import {
  useAccountSettingsQuery,
  useUserQuery,
} from "../../../Generated/graphql";
import "./Toolbar.css";

export const Toolbar = () => {
  const { data } = useUserQuery();
  const { data: accountData } = useAccountSettingsQuery();
  const [isOpen, setIsOpen] = useState(false);
  const profileRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(profileRef, () => {
    setIsOpen(false);
  });

  return (
    <div className="Toolbar">
      <div className="Toolbar__content">
        <div
          className="Toolbar__profile"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <Profile
            username={
              (accountData?.accountSettings?.accountName ?? data?.user.name) ||
              ""
            }
            droppedDown={isOpen}
          />
          <div ref={profileRef}>
            {isOpen && <ProfileDropdown role={data?.user?.role ?? "User"} />}
          </div>
        </div>
      </div>
    </div>
  );
};
