import React from 'react';

import './NewItemHeader.css'

export const NewItemHeader = ({ title = 'Opret vare' }) => {
  return (
    <div className="NewItemHeader">
      <div className="NewItemHeader__title">
        {title}
      </div>
    </div>
  )
}