import { useState } from "react";
import { animated } from "react-spring/renderprops.cjs";

export const SlideInComponent = ({ style, component, closeModal }) => (
  <SlideInComponentContent
    style={style}
    component={component}
    closeModal={closeModal}
  />
);

const SlideInComponentContent = ({ style, component, closeModal }) => {
  const [leaving, setLeaving] = useState(false);
  const [overlayStyle, setOverlayStyle] = useState([
    "mainRoute",
    "routing__overlay",
  ]);

  return (
    <>
      <animated.div
        className={overlayStyle.join(" ")}
        onClick={() => {
          if (!leaving) {
            setLeaving(true);
            setOverlayStyle(["mainRoute", "routing__overlay", "u-no-pointer"]);
            closeModal.goBack();
          }
        }}
        style={{ opacity: style.opacity }}
      />

      <animated.div
        className="routing__modal"
        style={{
          transform: style.transform,
          height: "100vh",
        }}
      >
        {component}
      </animated.div>
    </>
  );
};
