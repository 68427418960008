import Tooltip from "react-tooltip-lite";
import { ExitButton } from "../../ExitButton/ExitButton";
import { Icon } from "../../Icon/Icon";
import "./InactiveOrderDetails.css";

export const InactiveOrderDetails = ({
  items,
  windowWidth,
  orderStatus,
  orderIcon,
}) => {
  return (
    <>
      {items.map((item) => (
        <InactiveOrderDetailsItem
          item={item}
          windowWidth={windowWidth}
          key={item.productNumber}
          orderStatus={orderStatus}
        />
      ))}
    </>
  );
};

/**
 *  OrderStatus = isCompelted or not
 */
const InactiveOrderDetailsItem = ({ item, windowWidth, orderStatus }) => {
  // const itemName = item.name ? item.name.split("|") : " ";
  // const amountPerUnit = 1;  

  const unit = item.amountPerUnit > 1 ? "ks." : "stk.";
  const image = "";

  const displayText = () => {
    if (item.excluded) {
      return `Undladt ${item.taken} ${unit}`;
    }

    if (item.taken !== item.amount && orderStatus === "completed") {
      return `Modtaget ${item.taken} af ${item.amount} ${unit}`;
    }

    if (item.taken !== item.amount && orderStatus !== "completed") {
      return `Bestilt ${item.amount} ${unit}`;
    }

    return `Modtaget ${item.amount} ${unit}`;
  };

  return (
    <div className="InactiveOrderDetails__item">
      {windowWidth > 1023 && (
        <Tooltip
          className="OrderItem__item-view-tooltip"
          hoverDelay={100}
          direction="right"
          content={
            <div className="Tooltip__content">
              {" "}
              {navigator.userAgent.match(/iPad/i) && (
                <div style={{ position: "absolute", top: "6px", right: "6px" }}>
                  <ExitButton text="" solidBack={true} />
                </div>
              )}
              {image ? (
                <img
                  style={{ maxHeight: "200px", maxWidth: "200px" }}
                  src={image}
                  alt="Fejl..."
                />
              ) : (
                <Icon name="noImageAvail" width={200} />
              )}
            </div>
          }
        >
          <div className="OrderItem__item-view">
            <Icon name="preview" width={20} />
            <div
              className="IntentoryListItem__hover-trigger"
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </Tooltip>
      )}
      <div className="OrderItem__item-id u-no-text-select">
        {item.productNumber}
      </div>
      <div className="OrderItem__item-name u-no-text-select">{item.name}</div>
      <div className="OrderItem__item-description u-no-text-select">
        {item.description}
      </div>
      <div className="OrderItem__item-description orderItem__item-amount u-no-text-select">
        {displayText()}
      </div>
    </div>
  );
};
