import React from "react";
import "./ListItem.css";

export const ListItem = ({ title, children, style = {} }) => {
  return (
    <div className="ListItem" style={style}>
      <div className="ListItem__title">{title}</div>
      {children}
    </div>
  );
};
