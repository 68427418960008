import * as moment from "moment";
import React, { useState } from "react";
import { DashboardInventoryValueChart } from "../DashboardInventoryValueChart/DashboardInventoryValueChart";
import { DashboardInventoryValueNumbers } from "../DashboardInventoryValueNumbers/DashboardInventoryValueNumbers";
import { DashboardPeriodSelector } from "../DashboardPeriodSelector/DashboardPeriodSelector";
import "./DashboardInventoryValue.css";

export const DashboardInventoryValue = ({
  title = "Værdi af lager",
  accountancyYear = null
}) => {
  const [accountancy, setAccountancy] = useState(null);
  const [selectedYear, setSelectedYear] = useState({
    name: moment().format("YYYY")
  });

  const handleSetSelectedYear = input => {
    if (!input.name.includes("Regnskabsår")) {
      setSelectedYear(input);
      setAccountancy(null);
    } else {
      setSelectedYear(input);
      setAccountancy(accountancyYear);
    }
  };

  const yearOptions = [
    { name: moment().format("YYYY") },
    {
      name: moment()
        .subtract(1, "year")
        .format("YYYY")
    },
    {
      name: moment()
        .subtract(2, "year")
        .format("YYYY")
    },
    {
      name: "Regnskabsår " + moment().format("YYYY")
    },
    {
      name:
        "Regnskabsår " +
        moment()
          .subtract(1, "year")
          .format("YYYY")
    },
    {
      name:
        "Regnskabsår " +
        moment()
          .subtract(2, "year")
          .format("YYYY")
    }
  ];

  return (
    <div className="DashboardInventoryValue">
      <div className="DashboardInventoryValue__selector">
        <DashboardPeriodSelector
          setSelectedMonth={handleSetSelectedYear}
          selectedMonth={selectedYear}
          monthOptions={yearOptions}
          theme="light"
        />
      </div>
      <div className="DashboardInventoryValue__chart">
        <div className="DashboardInventoryValue__title">{title}</div>
        <DashboardInventoryValueChart
          selectedYear={selectedYear}
          accountancyYear={accountancy}
        />
      </div>
      <div className="DashboardInventoryValue__information">
        <DashboardInventoryValueNumbers />
      </div>
    </div>
  );
};
