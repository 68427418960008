import { Icon } from "../../Icon/Icon";
import { SettingsContainer } from "../Core/SettingsContainer";
import "./SettingsScanner.css";

// This component just serves static data
// compared to most other of our endpoints
export const SettingsScanner = () => {
  const downloadManual = (manualName: string) => {
    window.open(
      `https://${window.location.host}/Datablad_Datalogic-QuickScan-${manualName}.pdf`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="SettingsScanner">
      <div className="SettingsScanner__Title">
        <h1 className="SettingsScanner__Title-Text">Håndscanner</h1>
      </div>
      <div className="Settings__dividerbar" />
      <div className="SettingsScanner__Content">
        <SettingsContainer
          headerTexts={["Handling", "Scan stregkode"]}
        ></SettingsContainer>
        <div className="SettingsScanner__Elements">
          <div className="SettingsScanner__Element">
            <p>Nulstil indstillinger</p>
            <label>Indstiller til frabriksstandard</label>
          </div>
          <div className="SettingsScanner__Element SettingsScanner__Element__Icon">
            <Icon name="resetScannerBarcode" width={280} height={80} />
          </div>
        </div>
      </div>

      <div className="SettingsScanner__Content SettingsScanner__Downloads">
        <div className="SettingsScanner__Elements">
          <div className="SettingsScanner__Element">
            <p>Download manualer</p>
          </div>
          <div className="SettingsScanner__Downloadlinks">
            <div className="SettingsScanner__Element">
              <button
                onClick={() => downloadManual("QBT2131")}
                className="SettingsScanner__Download"
              >
                <img src="/QuickScanQM2131.png" alt="Håndholdt scanner" />
                <p>QuickScan QBT2131</p>
              </button>
            </div>
            <div className="SettingsScanner__Element">
              <button
                onClick={() => downloadManual("QM2131")}
                className="SettingsScanner__Download"
              >
                <img src="/QuickScanQM2131.png" alt="Håndholdt scanner" />
                <p>QuickScan QM2131</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
