export const capitalizeString = input => {
  if (typeof input !== "string") {
    return (
      input
        .toString()
        .charAt(0)
        .toUpperCase() + input.toString().substring(1)
    );
  }
  return input.charAt(0).toUpperCase() + input.substring(1);
};
