import { ApolloClient } from "@apollo/client";
import classNames from "classnames";
import { debounce, get, noop } from "lodash";
import { Component, createRef } from "react";
import {
  InventoryItem,
  Product,
  ProductDocument,
  ProductsDocument,
} from "../../Generated/graphql";
import { extractGTIN } from "../../Utils/extractGTIN";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { ItemExistsInInventoryModal } from "../ItemExistsInInventoryModal/ItemExistsInInventoryModal";
import { NotRecognizedProduct } from "../NotRecognizedProduct/NotRecognizedProduct";
import { ScannerComponent } from "../Scanner/Scanner";
import "./ItemSearchBar.css";
import { ItemSearchBarResults } from "./ItemSearchBarResults/ItemSearchBarResults";

interface ItemSearchBarProps {
  client: ApolloClient<any>;
  stockItems: InventoryItem[];
  makingNewItem: boolean;
  addItem: (key: Product) => void;
}

interface ItemSearchBarState {
  searchTerm: string;
  queryResults: Product[];
  lastQueryTime: string | null;
  isQuerying: boolean;
  hasQueryErrors: string | null;
  focused: boolean;
  openCamera: boolean;
  scanningActive: boolean;
  scannerInput: string;
  itemExists: boolean;
  notRecognizedProduct: boolean;
}

export class ItemSearchBar extends Component<
  ItemSearchBarProps,
  ItemSearchBarState
> {
  constructor(props: ItemSearchBarProps) {
    super(props);

    this.queryForSearchResults = debounce(this.queryForSearchResults, 800);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleKeyPress = (event: KeyboardEvent) => {
    let regex = new RegExp("([A-Z0-9])");
    if (
      event &&
      event.key !== "Shift" &&
      event.key !== "Enter" &&
      regex.test(event.key)
    ) {
      this.setState((prevStat) => ({
        scannerInput: prevStat.scannerInput.concat(event.key),
      }));
    } else if (event && event.key === "Enter") {
      // Do whatever you need to with the barcode

      const extractedGTIN = extractGTIN(this.state.scannerInput)

      this.setState({
        scannerInput:  extractedGTIN || this.state.scannerInput,
        searchTerm: extractedGTIN || this.state.scannerInput
        
      })

      this.addItemViaScanInput(extractedGTIN || this.state.scannerInput).then(() => 
        this.setState({
          scannerInput:  '',
          searchTerm: extractedGTIN || this.state.scannerInput,
          scanningActive: false,
        })
      );

    }
  };

  timerFunc: NodeJS.Timeout | null = null;

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
    this.rollingScannerReset();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    this.cancelRollingScannerReset();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  resetScanner = () => {
    this.setState({ scannerInput: "" });
  };

  rollingScannerReset = () => {
    this.timerFunc = setTimeout(() => {
      if (this.state.scannerInput.length > 0) {
        this.resetScanner();
      }
      this.rollingScannerReset();
    }, 2000);
  };

  cancelRollingScannerReset = () => {
    if (this.timerFunc) {
      clearTimeout(this.timerFunc);
    }
  };

  state = {
    searchTerm: "",
    queryResults: [],
    lastQueryTime: null,
    isQuerying: false,
    hasQueryErrors: null,
    focused: false,
    openCamera: false,
    scanningActive: false,
    scannerInput: "",
    itemExists: false,
    notRecognizedProduct: false,
  };

  onChangeHandler = (e: any) => {
    const value = e.target.value;
    this.setState(
      {
        isQuerying: true,
        searchTerm: value,
      },
      () => this.queryForSearchResults()
    );
  };

  openCamera = () => {
    this.setState({ openCamera: !this.state.openCamera });
  };

  activeScannerListener = () => {
    this.setState({ scanningActive: !this.state.scanningActive });
  };

  addItemViaScanInput = async (barcode: string) => {
    if (!barcode) {
      return;
    }
    return this.props.client
      .query({
        query: ProductDocument,
        variables: { barcode: barcode },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data.product) {
          const inInventory = this.props.stockItems
            ? this.props.stockItems.find(
                (x) => x.productNumber === data.product.prodNo
              )
            : null;
          if (inInventory && this.props.makingNewItem) {
            return this.setState({ itemExists: true });
          } else {
            return this.props.addItem(data.product);
          }
        } else {
          this.setState({ notRecognizedProduct: true });
        }
      });
  };

  controller: AbortController | null = null;

  queryForSearchResults = () => {
    // Abortable fetches prototype
    if (this.controller && this.controller.signal) {
      this.controller.abort();
    }
    this.controller = new AbortController();

    this.setState({
      isQuerying: true,
      hasQueryErrors: null,
    });

    this.props.client
      .query({
        query: ProductsDocument,
        variables: {
          searchTerm: this.state.searchTerm,
        },
        fetchPolicy: "network-only",
        context: {
          fetchOptions: {
            signal: this.controller.signal,
          },
        },
      })
      .then((data) => {
        const foundProducts: Product[] = get(data, "data.products", []);
        const withoutStatusTen = foundProducts.filter((x) => {
          const statusCode = get(x, "productData.status.statusCode", null);
          if (this.props.makingNewItem) {
            return statusCode !== "10";
          }
          return statusCode !== "10" && statusCode !== "90";
        });

        this.setState({
          isQuerying: false,
          queryResults: withoutStatusTen,
        });
      })
      .catch((err) => {
        this.setState({ isQuerying: false, hasQueryErrors: err });
      });
  };

  searchRef = createRef<HTMLInputElement>();

  handleClickOutside = (e: MouseEvent) => {
    const el = this.searchRef.current;

    if (!el || el.contains(e.target as Node)) {
      return;
    }

    this.setState({ focused: false });
  };

  render() {
    const { addItem } = this.props;
    const { focused, searchTerm, isQuerying } = this.state;

    return (
      <div className="ItemSearchBar" ref={this.searchRef}>
        <NotRecognizedProduct
          open={this.state.notRecognizedProduct}
          respond={(response: any) =>
            this.setState({ notRecognizedProduct: response })
          }
        />
        {this.state.itemExists ? (
          <ItemExistsInInventoryModal
            respond={() => this.setState({ itemExists: false })}
          />
        ) : null}
        {this.state.openCamera && (
          <div
            style={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              zIndex: "200",
            }}
          >
            <ScannerComponent
              flipMode={false}
              returnItem={this.addItemViaScanInput}
              toggleCamera={this.openCamera}
            />
          </div>
        )}

        <div
          className={classNames("ItemSearchBar__search", {
            "ItemSearchBar__search--active": focused,
          })}
        >
          <input
            type="text"
            className="ItemSearchBar__input"
            placeholder="Søg vare fra katalog..."
            value={searchTerm}
            onChange={this.onChangeHandler}
            onFocus={focused ? noop : () => this.setState({ focused: true })}
          />
          <div className="ItemSearchBar__searchIcon">
            <Icon name="search" width={30} />
          </div>
        </div>
        {searchTerm.length > 0 &&
          focused &&
          (isQuerying ? (
            <div className="ItemSearchBar__isSearching">
              <div className="ItemSearchBar__isSearching-text">Søger...</div>
            </div>
          ) : (
            <ItemSearchBarResults
              items={this.state.queryResults}
              addItem={addItem}
              stockItems={
                this.props.stockItems && this.props.makingNewItem
                  ? this.props.stockItems
                  : []
              }
              errors={this.state.hasQueryErrors}
              exists={this.state.itemExists}
            />
          ))}
        <div className="ItemSearchBar__actions">
          <div
            className={
              this.state.openCamera
                ? "ItemSearchBar__button ItemSearchBar__button-active"
                : "ItemSearchBar__button"
            }
            onClick={() => this.openCamera()}
          >
            <div className="ItemSearchBar__icon">
              <Icon name="camera" width={26} />
            </div>
            <Button
              buttonStyle="trigger"
              labelStyle="Button__trigger-label"
              width="50px"
            >
              Kamera
            </Button>
          </div>

          <div
            className={
              this.state.scanningActive
                ? "ItemSearchBar__button ItemSearchBar__button-active"
                : "ItemSearchBar__button"
            }
            onClick={() => this.activeScannerListener()}
          >
            <div className="ItemSearchBar__icon">
              <Icon name="scanner" width={26} />
            </div>
            <Button
              buttonStyle="trigger"
              labelStyle="Button__trigger-label"
              width="50px"
            >
              Scanner
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
